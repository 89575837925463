<h1 mat-dialog-title>
  <span translate="VEHICLE.VEHICLETYPE"> </span>
</h1>
<form [formGroup]="form">
  <app-vehicle-type-form [vehicleType]="vehicleType" [groups]="groups"></app-vehicle-type-form>
</form>
<div mat-dialog-actions>
  <button mat-raised-button [disabled]="!form.valid"  (click)="onSubmit()">
    {{ 'CREATE' | translate }}
  </button>
  <button mat-raised-button [mat-dialog-close]>
    {{ 'CLOSE' | translate }}
  </button>
</div>

<app-overlay-spinner *ngIf="loading"></app-overlay-spinner>