<h2 mat-dialog-title>{{ 'TRIP.ID' | translate }} {{ tripResponseData.id }}</h2>
<mat-dialog-content class="tables">
  <table mat-table [dataSource]="table2" class="mat-elevation-z8">
    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef>{{ 'TRIP.FIELD' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.field }}</td>
    </ng-container>
    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TRIP.OLDVALUE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.oldValue }}</td>
    </ng-container>
    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TRIP.NEWVALUE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="
          element.oldValue === element.newValue || element.newValue === '' ? 'background_normal' : 'background_change'
        "
      >
        {{ element.newValue }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <table mat-table [dataSource]="table" class="mat-elevation-z8">
    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef>{{ 'TRIP.FIELD' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.field }}</td>
    </ng-container>
    <ng-container matColumnDef="oldValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TRIP.OLDVALUE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.oldValue }}</td>
    </ng-container>
    <ng-container matColumnDef="newValue">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TRIP.NEWVALUE' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="
          element.oldValue === element.newValue || element.newValue === '' ? 'background_normal' : 'background_change'
        "
      >
        {{ element.newValue }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="single-button">
    <button class="mat-raised-button" [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
  </div>
  <div class="single-button">
    <button mat-raised-button class="mat-raised-button" (click)="recalculate(true)" >{{ 'TRIP.PERSIST' | translate }}</button>
  </div>
</mat-dialog-actions>
