import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  private static _condition = null;

  private notAuthenticatedApp = '/app';

  private notAuthenticatedUi = '/ui';

  public rlImprint = '/imprint';

  public rlContact = '/contact';

  public rlPrivacystatement = '/privacystatement';

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private authService: AuthService
  ) {
    iconRegistry.addSvgIcon(
      'privacy',
      sanitizer.bypassSecurityTrustResourceUrl('./assets/privacy.svg')
    );
    this.update();
  }

  private update(): void {
    if (!this.authService.isLoggedInPage()) {
      if (this.authService.isNoLoggedInAppPage()) {
        this.rlImprint = this.notAuthenticatedApp + this.rlImprint;
        this.rlContact = this.notAuthenticatedApp + this.rlContact;
        this.rlPrivacystatement =
          this.notAuthenticatedApp + this.rlPrivacystatement;
      } else {
        this.rlImprint = this.notAuthenticatedUi + this.rlImprint;
        this.rlContact = this.notAuthenticatedUi + this.rlContact;
        this.rlPrivacystatement =
          this.notAuthenticatedUi + this.rlPrivacystatement;
      }
    }
  }

  public static setDisabled(value: boolean): void {
    if (value) {
      FooterComponent._condition = 'footer-disable';
    } else {
      FooterComponent._condition = null;
    }
  }

  public condition(): string {
    return FooterComponent._condition;
  }
}
