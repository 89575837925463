import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import {
  NgxMatDateAdapter,
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NGX_MAT_DATE_FORMATS,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TripSpeedProfileComponent } from './trips-list/trip-analysis/trip-speed-profile/trip-speed-profile.component';
import { TripMultiInfoDistanceChartComponent } from './trips-list/trip-analysis/trip-multi-info-chart/trip-multi-info-distance-chart.component';
import { TripMultiInfoTimeChartComponent } from './trips-list/trip-analysis/trip-multi-info-chart/trip-multi-info-time-chart.component';
import { TripMapComponent } from './trips-list/trip-analysis/trip-map/trip-map.component';
import { TripAltitudeProfileComponent } from './trips-list/trip-analysis/trip-altitude-profile/trip-altitude-profile.component';
import { TripDetailsComponent } from './trips-list/trip-analysis/trip-details/trip-details.component';
import { TripCo2ProfileComponent } from './trips-list/trip-analysis/trip-co2-profile/trip-co2-profile.component';
import { TripWayProfileComponent } from './trips-list/trip-analysis/trip-way-profile/trip-way-profile.component';
import { TripAnalysisComponent } from './trips-list/trip-analysis/trip-analysis.component';
import { TripAnalysisDialogComponent } from './trips-list/trip-analysis-dialog/trip-analysis-dialog.component';
import { TripMultipleDetailsComponent } from './trips-list/trip-multiple-details/trip-multiple-details.component';
import { TripsComponent } from './trips.component';
import { TripsListComponent } from './trips-list/trips-list.component';
import { TripsMapComponent } from './trips-map/trips-map.component';
import { RecalculateDialogComponent } from './trips-list/trip-analysis/trip-details/recalculate-dialog/recalculate-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { TripsEvaluationComponent } from './trips-evaluation/trips-evaluation.component';
import { TripsToolsComponent } from './trips-tools/trips-tools.component';
import { CUSTOM_MOMENT_FORMATS } from '../utils/custom-date-formats';
import { OverlaySpinnerTripsActionComponent } from '../utilComponents/overlay-spinner-trips-action/overlay-spinner-trips-action.component';

@NgModule({
  declarations: [
    TripsComponent,
    TripSpeedProfileComponent,
    TripMultiInfoDistanceChartComponent,
    TripMultiInfoTimeChartComponent,
    TripMapComponent,
    TripAltitudeProfileComponent,
    TripDetailsComponent,
    TripCo2ProfileComponent,
    TripWayProfileComponent,
    TripAnalysisComponent,
    TripAnalysisDialogComponent,
    TripMultipleDetailsComponent,
    TripsListComponent,
    TripsMapComponent,
    RecalculateDialogComponent,
    TripsEvaluationComponent,
    TripsToolsComponent,
    OverlaySpinnerTripsActionComponent,
  ],
  imports: [
    CommonModule,
    ChartsModule,
    SharedModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    MatDatepickerModule,
    MatGridListModule,
    MatSlideToggleModule,
  ],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
  ],
})
export class TripsModule {}
