import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, NavigationEnd } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { Role } from 'lcmm-lib-js';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import { SensorstationRole } from 'sensorstation-lib-js';
import { PrivacyRequestDialogComponent } from '../privacy-request-dialog/privacy-request-dialog.component';
import { EnvConfigurationService } from './env-config.service';

export function authServiceInitializer(
  keycloak: KeycloakService,
  envService: EnvConfigurationService
): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return envService.load().then(() => {
      return keycloak.init({
        initOptions: {
          // onLoad: 'login-required',
          checkLoginIframe: false,
        },
        config: {
          url: envService.config.keycloakAuthUrl,
          clientId: envService.config.keycloakClientId,
          realm: envService.config.keycloakRealm,
        },
        enableBearerInterceptor: true,

        bearerExcludedUrls: [],
      });
    });
  };
}

const COOKIE_EXPIRE_IN_DAYS = 7;
const COOKIE_VALUE = 'lcmm-user';
const GROUP_PATTERN = environment.utilGroupPattern;

const ROLE_NO_DOWNLOAD = 'ROLE_NO_DOWNLOAD';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends KeycloakAuthGuard {
  private initialize: boolean;

  private admin: boolean;

  private dispatcher: boolean;

  private driver: boolean;

  private downloadDisabled: boolean;

  private noLoggedInPageSet: Set<string> = new Set<string>([
    '/app/contact',
    '/app/privacystatement',
    '/app/imprint',
    '/ui/contact',
    '/ui/privacystatement',
    '/ui/imprint',
  ]);

  private noLoggedInPage: boolean = null;

  private appPage: boolean = null;

  // SENSORSTATION BEGIN
  private sensorstationAdmin: boolean;

  private sensorstationViewer: boolean;

  private sensorstationEditor: boolean;

  // SENSORSTATION END
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private matDialog: MatDialog,
    private cookieService: CookieService
  ) {
    super(router, keycloakService);
    this.reset();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const { url } = this.router.routerState.snapshot;
        const callPara: string[] = url.split('/');
        this.noLoggedInPage = this.noLoggedInPageSet.has(url);
        this.appPage = callPara.length > 1 && callPara[1] === 'app';
      });
  }

  private reset(): void {
    this.appPage = null;
    this.noLoggedInPage = null;
    this.admin = false;
    this.dispatcher = false;
    this.driver = false;
    this.downloadDisabled = false;
    // SENSORSTATION BEGIN
    this.sensorstationAdmin = false;
    this.sensorstationEditor = false;
    this.sensorstationViewer = false;
    // SENSORSTATION END
    this.initialize = true;
  }

  private init(): void {
    if (this.initialize) {
      this.initialize = false;
      const myroles = this.keycloakService.getUserRoles();
      this.admin = this.hasRole([Role.Admin], myroles);
      this.dispatcher = this.hasRole([Role.Dispatcher], myroles);
      this.driver = this.hasRole([Role.Driver], myroles);
      this.downloadDisabled =
        this.hasRole([ROLE_NO_DOWNLOAD], myroles) &&
        !this.admin &&
        !this.dispatcher;

      // SENSORSTATION BEGIN
      this.sensorstationAdmin = this.hasRole(
        [SensorstationRole.SensorstationAdmin],
        myroles
      );
      this.sensorstationEditor = this.hasRole(
        [SensorstationRole.SensorstationEditor],
        myroles
      );
      this.sensorstationViewer = this.hasRole(
        [SensorstationRole.SensorstationViewer],
        myroles
      );
      // SENSORSTATION END
    }
  }

  // public functions

  public openGdprRequest(): void {
    this.init();
    this.loadUserProfile().then((profile) => {
      const cookieValue = this.cookieService.get(profile.username);
      if (cookieValue !== COOKIE_VALUE) {
        const dialogRef = this.matDialog.open(PrivacyRequestDialogComponent, {
          maxHeight: '90vh',
          maxWidth: '90vw',
          // Check if vh/vw are ok with iOS Safari. Otherwise: maybe %
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result === true) {
            this.cookieService.set(
              profile.username,
              COOKIE_VALUE,
              COOKIE_EXPIRE_IN_DAYS
            );
          }
        });
      }
    });
  }

  public revokeGdpr(): void {
    this.init();
    this.loadUserProfile().then((profile) => {
      // Delete cookie
      this.cookieService.delete(profile.username);
      // Logout
      this.logout();
      this.reset();
    });
  }

  public isAccessAllowed(
    activatedRouteSnapshot: ActivatedRouteSnapshot
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.openGdprRequest();
      if (!this.authenticated) {
        this.keycloakAngular.login();
        resolve(false);
      }
      const hasrole = this.hasRole(
        activatedRouteSnapshot.data.roles,
        this.roles
      );
      resolve(hasrole);
    });
  }

  public getGroupIdentifier(): string {
    this.init();
    let groupIdentifier: string = null;
    this.keycloakService.getUserRoles().some((element) => {
      if (element.startsWith(GROUP_PATTERN)) {
        groupIdentifier = element.replace(GROUP_PATTERN, '');
        return true;
      }
      return false;
    });
    return groupIdentifier;
  }

  public hasRole(requiredRoles: string[], roles: string[]): boolean {
    this.init();
    if (!requiredRoles || requiredRoles.length === 0) {
      return true;
    }
    if (!roles || roles.length === 0) {
      return false;
    }
    return requiredRoles.some((role) => roles.includes(role));
  }

  public isAdmin(): boolean {
    this.init();
    return this.admin;
  }

  public isDispatcher(): boolean {
    this.init();
    return this.dispatcher;
  }

  public isDriver(): boolean {
    this.init();
    return this.driver;
  }

  public isDownloadEnabled(): boolean {
    this.init();
    return !this.downloadDisabled;
  }

  public isAdminOrDispatcher(): boolean {
    return this.isAdmin() || this.isDispatcher();
  }

  public logout(): Promise<void> {
    this.reset();
    return this.keycloakService.logout();
  }

  public loadUserProfile(): Promise<Keycloak.KeycloakProfile> {
    return this.keycloakService.loadUserProfile();
  }

  public getToken(): Promise<string> {
    return this.keycloakService.getToken();
  }

  public getUsername(): string {
    return this.keycloakService.getUsername();
  }

  public isLoggedInPage(): boolean {
    return !(this.noLoggedInPage !== null && this.noLoggedInPage);
  }

  public isNoLoggedInAppPage(): boolean {
    return this.appPage !== null && this.appPage;
  }

  // SENSORSTATION BEGIN
  public isSensorstationAdmin(): boolean {
    this.init();
    return this.sensorstationAdmin;
  }

  public isSensorstationEditor(): boolean {
    this.init();
    return this.sensorstationEditor;
  }

  public isSensorstationViewer(): boolean {
    this.init();
    return this.sensorstationViewer;
  }

  public isSensorstationMember(): boolean {
    return (
      this.isSensorstationAdmin() ||
      this.isSensorstationEditor() ||
      this.isSensorstationViewer()
    );
  }
  // SENSORSTATION END
}
