import { DatePipe } from '@angular/common';
import { ElementRef } from '@angular/core';
import {
  MarkerClusterer,
  MarkerClustererOptions,
} from '@googlemaps/markerclusterer';
import { TranslateService } from '@ngx-translate/core';
import { FixFlag, Trip } from 'lcmm-lib-js';
import {
  TripSectionParameter,
  TripService,
} from 'src/app/service/trip.service';
import {
  MarkerPopup,
  StandStillMarkerRenderer,
} from 'src/app/utils/markers/stand-still-marker-renderer';
import { secondsToDuration } from '../utils';

export type SectionCallback = (section: TripSectionParameter) => unknown;

export class MapHelper {
  private bounds = StandStillMarkerRenderer.getBoundsEurope();

  private boundsPadding = 0.1;

  private isSectionAllowed: boolean = null;

  private map: google.maps.Map = null;

  private mapListener: google.maps.MapsEventListener = null;

  private section: google.maps.Rectangle = null;

  private sectionCorner1: google.maps.LatLng = null;

  private markers: google.maps.Marker[] = [];

  private sectionMarkers: google.maps.Marker[] = [];

  private startMarker: google.maps.Marker = null;

  private lastMarker: google.maps.Marker = null;

  private mc: MarkerClusterer = null;

  private sectionCallback: SectionCallback = null;

  private sectionClickCallback: SectionCallback = null;

  private cursorUnSelected = 'crosshair';

  private cursorSelected = '';

  constructor(private ts: TranslateService, private datePipe: DatePipe) {}

  private getSectionBounds(): google.maps.LatLngBounds {
    if (this.section === null) {
      return null;
    }
    return this.section.getBounds();
  }

  private updateSection(latlng: google.maps.LatLng, noCallback: boolean): void {
    if (!noCallback && this.getSectionBounds() !== null) {
      this.clearSection(noCallback);
      // return;
    }
    if (this.sectionCorner1 === null) {
      this.sectionCorner1 = latlng;
      this.map.setOptions({ draggableCursor: this.cursorUnSelected });
      this._addMarker(
        latlng,
        '',
        StandStillMarkerRenderer.gooIconSectionStart,
        10000,
        null,
        this.sectionMarkers
      );
    } else {
      this.map.setOptions({ draggableCursor: this.cursorSelected });
      this._removeMarkers(this.sectionMarkers);
      this.section = this.createSection(
        this.sectionCorner1,
        latlng,
        noCallback
      );
      if (!noCallback) {
        this.sectionUpdate();
      }
      this.section.addListener('click', this.sectionClick.bind(this));
      this.section.addListener('bounds_changed', this.sectionUpdate.bind(this));
    }
  }

  private sectionUpdate(): void {
    if (this.sectionCallback) {
      const bounds = this.section.getBounds();
      const tsp = TripService.createTripSectionParameterByCoord(
        'map.updateSection',
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng(),
        bounds.getSouthWest().lat(),
        bounds.getSouthWest().lng()
      );
      this.sectionCallback(tsp);
    }
  }

  private sectionClick(): void {
    if (this.sectionClickCallback) {
      const bounds = this.section.getBounds();
      const tsp = TripService.createTripSectionParameterByCoord(
        'map.clickSection',
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng(),
        bounds.getSouthWest().lat(),
        bounds.getSouthWest().lng()
      );
      this.sectionClickCallback(tsp);
    }
  }

  private createSection(
    corner1: google.maps.LatLng,
    corner2: google.maps.LatLng,
    noCallback: boolean
  ): google.maps.Rectangle {
    this.clearSection(noCallback);
    this.sectionCorner1 = corner1;
    const section: google.maps.Rectangle = new google.maps.Rectangle({
      bounds: StandStillMarkerRenderer.createBounds(corner1, corner2),
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      editable: true,
      draggable: true,
    });
    return section;
  }

  private clearSection(noCallback: boolean): void {
    if (this.section != null) {
      this.section.setMap(null);
      this.section = null;
      this.sectionCorner1 = null;
      this.sectionMarkers = this._removeMarkers(this.sectionMarkers);
      if (!noCallback && this.sectionCallback) {
        this.sectionCallback(null);
      }
    }
    this.map.setOptions({ draggableCursor: this.cursorUnSelected });
  }

  private _removeMarkers(markers: google.maps.Marker[]): google.maps.Marker[] {
    StandStillMarkerRenderer.removeMarkers(markers);
    return [];
  }

  private _addMarker(
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    markers: google.maps.Marker[],
    markerPopup?: MarkerPopup
  ): google.maps.Marker {
    const marker = StandStillMarkerRenderer.createMarker(
      latLng,
      name,
      icon,
      zindex,
      infoContent,
      markerPopup
    );
    StandStillMarkerRenderer.addMarker(this.map, marker);
    markers.push(marker);
    return marker;
  }

  private removeMarker(marker: google.maps.Marker): void {
    StandStillMarkerRenderer.removeMarker(marker);
  }

  private static determineColor(ece: number): string {
    if (ece < 1) {
      return '#00FF00'; // green
    }
    if (ece < 1.5) {
      return '#FFFF00'; // yellow
    }
    return '#FF0000'; // red
  }

  // public

  public isTripOk(trip: Trip): boolean {
    return (
      trip !== null &&
      trip.positions !== undefined &&
      trip.positions !== null &&
      trip.positions.length > 0
    );
  }

  public setSection(
    corner1Lat: number,
    corner1Lng: number,
    corner2Lat: number,
    corner2Lng: number
  ): void {
    this.sectionCorner1 = new google.maps.LatLng(corner1Lat, corner1Lng);
    this.updateSection(new google.maps.LatLng(corner2Lat, corner2Lng), true);
  }

  public isSectionSelected(): boolean {
    return this.section !== null;
  }

  public getSelectedSectionBounds(): google.maps.LatLngBounds {
    if (this.isSectionSelected()) {
      return this.section.getBounds();
    }
    return null;
  }

  public initMap(
    refMap: ElementRef,
    isSectionAllowed: boolean,
    enable?: boolean
  ): google.maps.Map {
    if (refMap) {
      this.isSectionAllowed = isSectionAllowed;

      this.map = new google.maps.Map(refMap.nativeElement);

      if (!isSectionAllowed) {
        this.cursorUnSelected = this.cursorSelected;
      }

      const mco: MarkerClustererOptions = {
        map: this.map,
        renderer: new StandStillMarkerRenderer(this.ts),
      };
      this.mc = new MarkerClusterer(mco);

      const mapOptions: google.maps.MapOptions = {
        draggableCursor: this.cursorUnSelected,
      };

      this.map.setOptions(mapOptions);
      this.map.fitBounds(this.bounds, this.boundsPadding);

      this.setEnabled(!isSectionAllowed || enable);
    }
    return this.map;
  }

  public setEnabled(enable: boolean): void {
    if (enable) {
      if (this.map) {
        this.map.addListener('click', (e) => {
          StandStillMarkerRenderer.hideInfo();
          if (this.isSectionAllowed) {
            // eslint-disable-next-line dot-notation
            this.updateSection(e['latLng'], false);
          }
        });
        this.map.setOptions({
          disableDefaultUI: null,
          clickableIcons: true,
          disableDoubleClickZoom: null,
          draggable: null,
          fullscreenControl: null,
        });
      }
    } else {
      if (this.mapListener) {
        this.mapListener.remove();
        this.mapListener = null;
      }
      if (this.map) {
        this.map.setOptions({
          disableDefaultUI: true,
          clickableIcons: false,
          disableDoubleClickZoom: true,
          draggable: false,
          fullscreenControl: false,
        });
      }
    }
  }

  public removeMarkers(): void {
    this.markers = this._removeMarkers(this.markers);
  }

  public addLine(positions: google.maps.LatLng[], color: string): void {
    // eslint-disable-next-line no-new
    new google.maps.Polyline({
      map: this.map,
      path: positions,
      strokeColor: color,
    });
  }

  public addMarker(
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    markerPopup?: MarkerPopup
  ): google.maps.Marker {
    return this._addMarker(
      latLng,
      name,
      icon,
      zindex,
      infoContent,
      this.markers,
      markerPopup
    );
  }

  public replaceMarker(
    rem: google.maps.Marker,
    latLng: google.maps.LatLng,
    name: string,
    icon: google.maps.Icon,
    zindex: number,
    infoContent: string,
    popup?: MarkerPopup
  ): google.maps.Marker {
    this.removeMarker(rem);
    return this.addMarker(latLng, name, icon, zindex, infoContent, popup);
  }

  public addLines(trip: Trip): void {
    if (this.isTripOk(trip)) {
      let latlngs: google.maps.LatLng[] = [];
      let lastC = MapHelper.determineColor(trip.positions[0].percentageWork);
      for (let index = 0; index < trip.positions.length; index += 1) {
        const p = trip.positions[index];
        const c = MapHelper.determineColor(p.percentageWork);
        if (lastC !== c) {
          this.addLine(latlngs, lastC);
          latlngs = [latlngs[latlngs.length - 1]];
          lastC = c;
        }
        latlngs.push(new google.maps.LatLng(p.latitude, p.longitude));
        if (p.fixFlag === FixFlag.CUT) {
          this.addLine(latlngs, lastC);
          latlngs = [];
        }
      }
      if (latlngs.length > 0) {
        this.addLine(latlngs, lastC);
      }
    }
  }

  public updateStartMarker(trip: Trip): void {
    if (this.isTripOk(trip)) {
      const title = this.ts.instant('TRIP.STARTPOSITION');
      const p = trip.positions[0];
      const infoDate = this.datePipe.transform(
        p.dtime,
        this.ts.instant('DATEFORMAT')
      );
      const infoContent = `<b>${title}</b> <p> ${infoDate} </p>`;
      this.startMarker = this.replaceMarker(
        this.startMarker,
        new google.maps.LatLng(p.latitude, p.longitude),
        title,
        StandStillMarkerRenderer.gooIconStart,
        Number.MAX_VALUE,
        infoContent
      );
    }
  }

  public updateLastMarker(trip: Trip) {
    if (this.isTripOk(trip)) {
      const title = this.ts.instant('TRIP.LASTPOSITION');
      const p = trip.positions[trip.positions.length - 1];
      const infoDate = this.datePipe.transform(
        p.dtime,
        this.ts.instant('DATEFORMAT')
      );
      const infoContent = `<b>${title}</b> <p> ${infoDate} </p>`;
      this.lastMarker = this.replaceMarker(
        this.lastMarker,
        new google.maps.LatLng(p.latitude, p.longitude),
        title,
        StandStillMarkerRenderer.gooIconEnd,
        Number.MAX_VALUE,
        infoContent
      );
    }
  }

  public createStandStillMarkers(trip: Trip): void {
    if (this.mc) {
      this.mc.clearMarkers();
    }
    if (this.isTripOk(trip)) {
      const title = this.ts.instant('TRIP.STANDSTILL');
      const labelTime = this.ts.instant('TRIP.TIME');
      const labelDurance = this.ts.instant('TRIP.DURANCE');
      const tf = this.ts.instant('DATEFORMAT');
      for (let i = 0; i < trip.positions.length; i += 1) {
        const p = trip.positions[i];
        if (p.standStillTime > 0) {
          const standStillTime = secondsToDuration(p.standStillTime);
          const infoDate = this.datePipe.transform(p.dtime, tf);
          const infoContent = `<b>${title}</b><p> ${labelTime}: ${infoDate} </p><p> ${labelDurance}: ${standStillTime} </p>`;
          const gooPos = new google.maps.LatLng(p.latitude, p.longitude);
          const ssm = StandStillMarkerRenderer.createStandStillMarker(
            title,
            p.standStillTime,
            gooPos,
            p.standStillTime,
            infoContent
          );
          ssm.set('standStillTime', p.standStillTime);
          if (this.mc) {
            this.mc.addMarker(ssm);
          }
        }
      }
    }
  }

  public fitMapBounds(bounds: google.maps.LatLngBounds): void {
    if (this.map) {
      this.map.fitBounds(bounds, this.boundsPadding);
    }
  }

  public fitBoundsByTrip(trip: Trip): void {
    if (this.isTripOk(trip)) {
      let latMax = 0;
      let lngMax = 0;
      let latMin = 1000;
      let lngMin = 1000;
      // eslint-disable-next-line array-callback-return
      trip.positions.map((p) => {
        const lat = p.latitude;
        const lng = p.longitude;
        if (latMax < lat) latMax = lat;
        if (lngMax < lng) lngMax = lng;
        if (latMin > lat) latMin = lat;
        if (lngMin > lng) lngMin = lng;
      });
      const bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(latMin, lngMin),
        new google.maps.LatLng(latMax, lngMax)
      );
      this.fitMapBounds(bounds);
    }
  }

  public registerSectionCallback(sectionCallback: SectionCallback): void {
    this.sectionCallback = sectionCallback;
  }

  public registerSectionClickCallback(
    sectionClickCallback: SectionCallback
  ): void {
    this.sectionClickCallback = sectionClickCallback;
  }
}
