import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TripResponse } from 'lcmm-lib-js';

interface TripResult {
  tripId: string;
  groupName: string;
}

@Component({
  selector: 'app-trip-batch-results',
  templateUrl: './trip-batch-results.component.html',
  styleUrls: ['./trip-batch-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripBatchResultsComponent {
  public displayedColumns: string[] = ['tripId', 'groupName'];

  public table: TripResult[];

  public truncateWarning = false;

  public numberOfDisplayedTrips = 100;

  public totalTrips = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { tripResults: TripResponse[] }
  ) {
    let tripResponses: TripResponse[] = data.tripResults;
    if (tripResponses.length > this.numberOfDisplayedTrips) {
      this.totalTrips = tripResponses.length;
      tripResponses = tripResponses.slice(0, this.numberOfDisplayedTrips - 1); // Just show the first XX trips in table
      this.truncateWarning = true;
    }

    this.table = tripResponses.map((tripResponse) => {
      return { tripId: tripResponse.id, groupName: tripResponse.groupName };
    });
  }
}
