import { formatNumber } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

@Directive({
  selector: 'input[matInputPercent]',
  providers: [
    {
      provide: MAT_INPUT_VALUE_ACCESSOR,
      useExisting: MatInputPercentDirective,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatInputPercentDirective),
      multi: true,
    },
  ],
})
export class MatInputPercentDirective {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  private setHtmlInput(value: string | null) {
    if (value !== null && !Number.isNaN(Number(value))) {
      const valueInPercent = Number(value) * 100;
      this.elementRef.nativeElement.value = formatNumber(
        valueInPercent,
        'en-EN',
        '1.0-0'
      );
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('blur')
  public _onBlur(): void {
    const valueAsFactor = Number(this.elementRef.nativeElement.value) / 100;
    const factorAsString = formatNumber(valueAsFactor, 'en-EN', '1.0-3');
    this._onChange(factorAsString);
  }

  // Implement methods from interface ControlValueAccessor
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  public writeValue(value: any): void {
    this.setHtmlInput(value);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  public registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  private _onChange(value: any): void {
    // Dummy method (is overwritten by registerOnChange())
  }

  public registerOnTouched(): void {
    // Dummy method
  }
}
