import { SortDirection } from '@angular/material/sort';
import { Group } from 'lcmm-lib-js';

// If table sort header "groupName" is selected, the sorting shall be performed by groupIdentifier and the case is not relevant.
export const sortingDataAccessor = (
  data: Group,
  sortHeaderId: string
): string | number => {
  let returnName: string = null;
  if (sortHeaderId === 'groupName') {
    returnName = data.groupIdentifier.toLowerCase();
  } else {
    returnName = data[sortHeaderId];
  }
  return returnName;
};

// Function that performs the sorting depending on function "sortingDataAccessor".
// For ascending groups, this function suffice.
// For descending groups, the function "correctDescSorting" must be called in addition.
export const sortGroups = (
  data: Group[],
  active: string,
  direction: SortDirection
): Group[] => {
  // sort implementation derived from https://github.com/angular/components/blob/master/src/material/table/table-data-source.ts
  return data.sort((a, b) => {
    let valueA = sortingDataAccessor(a, active);
    let valueB = sortingDataAccessor(b, active);

    // If there are data in the column that can be converted to a number,
    // it must be ensured that the rest of the data
    // is of the same type so as not to order incorrectly.
    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    if (valueAType !== valueBType) {
      if (valueAType === 'number') {
        valueA += '';
      }
      if (valueBType === 'number') {
        valueB += '';
      }
    }

    // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
    // one value exists while the other doesn't. In this case, existing value should come last.
    // This avoids inconsistent results when comparing values to undefined/null.
    // If neither value exists, return 0 (equal).
    let comparatorResult = 0;
    if (valueA != null && valueB != null) {
      // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
      if (valueA > valueB) {
        comparatorResult = 1;
      } else if (valueA < valueB) {
        comparatorResult = -1;
      }
    } else if (valueA != null) {
      comparatorResult = 1;
    } else if (valueB != null) {
      comparatorResult = -1;
    }

    return comparatorResult * (direction === 'asc' ? 1 : -1);
  });
};

// This function performs the requirement when descending, that the parent group shall not be sorted after the sub groups.
// The idea is, that after normal descending ordering, the parent groups can be found by the level count
// ("T-Systems." -> level count 1, "T-Systems.test.logistics" -> level count 3).
// When iterating through the array, when the level count decreases, that element is a parent group
// and shall be shifted up before all element that start with that value.
export const correctDescSorting = (
  groups: Group[],
  groupIdentifierSeparator: string
): Group[] => {
  let lastLevelCount = 0;
  const changeValueIndices: number[] = [];

  // Iterate through descending sorted array and detect the parent group entry at the end of the sub groups
  // If level count decreases, the parent group is found
  // Name    Level
  // B.c     2
  // B.b     2
  // B.a     2
  // B.      1    <-- Detect and shift up in front of the sub groups
  groups.forEach((value, index) => {
    const levelCount =
      value.groupIdentifier.split(groupIdentifierSeparator).length - 1;

    // Save index of entry that shall be shifted up
    if (levelCount < lastLevelCount) {
      changeValueIndices.push(index);
    }
    lastLevelCount = levelCount;
  });

  // Shift entries infront of the first entry that begins with its name
  // B.
  // B.c
  // B.b
  // B.a
  changeValueIndices.forEach((index) => {
    // "groups[index].groupIdentifier": Entry (parent group) that shall be shifted up (like "T-Systems.")
    // "value.groupIdentifier": First occurance of sub group that begins with parent group (like "T-Systems.test")
    // "toIndex": Index of first occurance
    const toIndex = groups.findIndex((value) =>
      value.groupIdentifier.startsWith(groups[index].groupIdentifier)
    );

    // Cut parent group at index and insert at toIndex
    groups.splice(toIndex, 0, groups.splice(index, 1)[0]);
  });

  return groups;
};
