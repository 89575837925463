<div class="vehicleForm" formGroupName="vehicleTypeForm">
  <mat-form-field>
    <input placeholder="{{ 'VEHICLE.BRAND' | translate }}" required type="text" matInput formControlName="brand" />
  </mat-form-field>
  <mat-form-field>
    <input placeholder="{{ 'VEHICLE.SERIES' | translate }}" required type="text" matInput formControlName="series" />
  </mat-form-field>
  <mat-form-field>
    <input placeholder="{{ 'VEHICLE.MODEL' | translate }}" required type="text" matInput formControlName="model" />
  </mat-form-field>
  <mat-form-field>
    <input placeholder="{{ 'VEHICLE.HSN' | translate }}" type="text" matInput formControlName="hsn" />
  </mat-form-field>
  <mat-form-field>
    <input placeholder="{{ 'VEHICLE.TSN' | translate }}" type="text" matInput formControlName="tsn" />
  </mat-form-field>
  <mat-form-field>
    <input
      placeholder="{{ 'VEHICLE.AIRDRAG_COEFFICIENT' | translate }}"
      required
      type="number"
      matInput
      formControlName="airDragCoefficient"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      placeholder="{{ 'VEHICLE.CROSSSECTIONAREA' | translate }} in m&#0178;"
      required
      type="number"
      matInput
      formControlName="crossSectionArea"
    />
  </mat-form-field>

  <mat-form-field>
    <input
      placeholder="{{ 'VEHICLE.EFFICIENCY' | translate }} in %"
      required
      type="number"
      matInput
      formControlName="efficiency"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      placeholder="{{ 'VEHICLE.FUELEMISSIONFACTOR' | translate }} in kg/l"
      required
      type="number"
      matInput
      formControlName="fuelEmissionFactor"
    />
  </mat-form-field>
  <mat-form-field>  
    <mat-select required 
      placeholder="{{ 'VEHICLE.FUELTYPE' | translate }}"
      formControlName="fuelValue"
    >
      <mat-option *ngFor="let ftype of selectableFuelTypes" [value]="ftype.value">
        {{ 'VEHICLE.' + ftype.key | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input
    placeholder="{{ 'VEHICLE.MASS' | translate }} in kg"
    required
    type="number"
    matInput
    formControlName="mass" />
  </mat-form-field>
  <mat-form-field>
    <input
      placeholder="{{ 'VEHICLE.ROLLFRICTIONCOEFFICIENT' | translate }}"
      required
      type="number"
      matInput
      formControlName="rollFrictionCoefficient"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      placeholder="{{ standStillFuelConsumptionLabel() }}"
      required
      type="number"
      matInput
      formControlName="standStillFuelConsumption"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label mat-label translate="USER.GROUP"></mat-label>
    <mat-select
      formControlName="groupName"
      (selectionChange)="groupChanged($event.value)"
      [disabled]="isGroupFieldDisabled()"
      required
    >
      <mat-option *ngFor="let group of groups" [value]="group.groupIdentifier">
        <span>
          <span *ngFor="
            let dummy of ' '
              .repeat(group.groupIdentifier
              .split(envService.config.groupIdentifierSeparator).length - 2).split('');
          ">
            - </span>{{ group.groupName }}
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field>
    <mat-label mat-label translate="VEHICLE.WLTPCLASS"></mat-label>
    <mat-select 
      formControlName="wltpClass"
      (selectionChange)="wltpClassChanged($event.value)"
      required
    >
      <mat-option [value]="1">{{ 'VEHICLE.WLTPCLASS_1' | translate }}</mat-option>
      <mat-option [value]="2">{{ 'VEHICLE.WLTPCLASS_2' | translate }}</mat-option>
      <mat-option [value]="3">{{ 'VEHICLE.WLTPCLASS_3' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox formControlName="airConditioning">{{ 'VEHICLE.AIRCONDITIONING' | translate }}</mat-checkbox>
  <mat-checkbox formControlName="startStop">{{ 'VEHICLE.STARTSTOP' | translate }}</mat-checkbox>
  <mat-checkbox formControlName="motorHeating">{{ 'VEHICLE.MOTORHEATING' | translate }}</mat-checkbox>
</div>
