<mat-toolbar class="footer" color="accent">
  <div [ngClass]="condition()" class="btn" [routerLink]="rlImprint">{{ 'IMPRINT.TITLE' | translate }}</div>
  <div>|</div>
  <div [ngClass]="condition()" class="btn" [routerLink]="rlPrivacystatement">
    <mat-icon svgIcon="privacy"></mat-icon>
    {{ 'PRIVACY.FOOTER' | translate }}
  </div>
  <div>|</div>
  <div [ngClass]="condition()" class="btn" [routerLink]="rlContact">{{ 'CONTACT.TITLE' | translate }}</div>
</mat-toolbar>
