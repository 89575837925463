import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpParams,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { scan } from 'rxjs/operators';
import { Saver, SAVER } from '../utils/saver.provider';

export enum DownloadState {
  'PENDING' = 0,
  'IN_PROGRESS',
  'DONE',
  'DONE_EMPTY',
}

export enum DownloadType {
  kml = 'kml',
  csv = 'csv',
  zip = 'zip',
}

export interface Download {
  state: DownloadState;
  progress: number;
  content: Blob | null;
}

export interface DownloadStatus {
  type: DownloadType;
  loading: boolean;
  progress: number;
  ok: boolean;
  loaded: boolean;
}

@Injectable({ providedIn: 'root' })
export class DownloadService {
  constructor(private http: HttpClient, @Inject(SAVER) private save: Saver) {}

  public downloadFile(
    url: string,
    getFileName: (event: HttpResponse<Blob>) => string,
    params?:
      | HttpParams
      | {
          [param: string]: string | string[];
        }
  ): Observable<Download> {
    return this.http
      .get(url, {
        reportProgress: true,
        observe: 'response',
        responseType: 'blob',
        params,
      })
      .pipe(
        this.download(
          (blob, fileName) => this.save(blob, fileName),
          getFileName
        )
      );
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  private isHttpProgressEvent(
    event: HttpEvent<unknown>
  ): event is HttpProgressEvent {
    return (
      event.type === HttpEventType.DownloadProgress ||
      event.type === HttpEventType.UploadProgress
    );
  }

  private download(
    saver: (b: Blob, fileName: string) => void,
    getFileName: (event: HttpResponse<Blob>) => string
  ): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan(
          (previous: Download, event: HttpEvent<Blob>): Download => {
            if (this.isHttpProgressEvent(event)) {
              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : previous.progress,
                state: DownloadState.IN_PROGRESS,
                content: null,
              };
            }
            if (this.isHttpResponse(event)) {
              let thestate = DownloadState.DONE_EMPTY;
              if (saver && event.body && event.body.size > 0) {
                thestate = DownloadState.DONE;
                saver(event.body, getFileName(event));
              }
              return {
                progress: 100,
                state: thestate,
                content: event.body,
              };
            }
            return previous;
          },
          { state: DownloadState.PENDING, progress: 0, content: null }
        )
      );
  }
}
