/* eslint-disable max-len */
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TripResponse } from 'lcmm-lib-js';
import {
  getFuelTypeNameByValue,
  isFuelTypeValueElectric,
} from 'src/app/utils/utils';
import { AuthService } from 'src/app/service/auth.service';
import { RecalculateDialogComponent } from './recalculate-dialog/recalculate-dialog.component';

@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss'],
})
export class TripDetailsComponent implements OnInit {
  public detailsListTrip: { label: string; value: string }[];

  public detailsListVehicle: { label: string; value: string }[];

  public detailsListVehicleCheckbox: { label: string; value: boolean }[];

  public detailsListCalculation: { label: string; value: string }[];

  public breakpoint: number;

  public routeStars: number;

  public trafficStars: number;

  public drivingStars: number;

  @Input() public tripResponse: TripResponse;

  @Input()
  public disableStars: boolean;

  public isAdmin: boolean;

  public isDispatcher: boolean;

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private ts: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isAdmin = this.authService.isAdmin();
    this.isDispatcher = this.authService.isDispatcher();
    this.computeDetails();
  }

  private computeDetails(): void {
    this.setDetailsList();
    this.setBreakpoint(window.innerWidth);
    if (this.tripResponse.absoluteCalculation?.percentageWorkCycle) {
      this.routeStars = this.calculateCycle(
        this.tripResponse.absoluteCalculation.percentageWorkCycle,
        200,
        160,
        120,
        80
      );
    }
    this.trafficStars = this.calculateTraffic();
    this.drivingStars = this.calculateDriving(30, 20, 10, 5);
  }

  private isAvailable(attribute: unknown): boolean {
    return attribute !== undefined && attribute !== null;
  }

  public showStars(): boolean {
    return !this.disableStars && this.tripResponse.calculation.distance >= 5.0;
  }

  private appendWltpValue(
    text: string,
    value: number,
    delimiter: string
  ): string {
    if (this.isAvailable(value) && value > 0) {
      return `${text}${this.decimalPipe.transform(
        value * 100,
        '1.0-0'
      )}${delimiter}`;
    }
    return `${text}-${delimiter}`;
  }

  public msToTimeStr(duranceMs: number): string {
    const m = Number(this.datePipe.transform(duranceMs, 'mm', 'UTC'));
    const s = Number(this.datePipe.transform(duranceMs, 'ss', 'UTC'));
    const ms = Number(this.datePipe.transform(duranceMs, 'SSS', 'UTC'));
    const h = Math.round(
      (duranceMs - m * 60 * 1000 - s * 1000 - ms) / (1000 * 60 * 60)
    );
    const str = `${h}${this.datePipe.transform(duranceMs, ':mm:ss', 'UTC')}`;
    return h < 10 ? `0${str}` : str;
  }

  private computePFrom(value: number, total: number): string {
    let strValue = `${this.decimalPipe.transform(value / 1e6, '1.0-1')} MJ`;
    if (total && total > 0 && value && value > 0) {
      strValue = `${strValue} (${this.decimalPipe.transform(
        (value / total) * 100,
        '1.0-0'
      )} %)`;
    }
    return strValue;
  }

  private setDetailsList() {
    this.detailsListTrip = [];
    this.detailsListVehicle = [];
    this.detailsListVehicleCheckbox = [];
    this.detailsListCalculation = [];
    if (this.isAvailable(this.tripResponse)) {
      let fuelValueUnit = ' l';
      if (isFuelTypeValueElectric(this.tripResponse.vehicle.fuelValue)) {
        fuelValueUnit = ' kWh';
      }
      this.detailsListTrip.push({
        label: 'TRIP.VEHICLENAME',
        value: this.tripResponse.vehicle.name,
      });
      this.detailsListTrip.push({
        label: 'TRIP.GROUPNAME',
        value: this.tripResponse.groupName,
      });
      this.detailsListTrip.push({
        label: 'TRIP.STARTTIME',
        value: this.datePipe.transform(
          this.tripResponse.startTime,
          'dd.MM.yyyy, HH:mm'
        ),
      });
      if (this.isAvailable(this.tripResponse.endTime)) {
        this.detailsListTrip.push({
          label: 'TRIP.ENDTIME',
          value: this.datePipe.transform(
            this.tripResponse.endTime,
            'dd.MM.yyyy, HH:mm'
          ),
        });
      }
      this.detailsListVehicle.push({
        label: 'VEHICLE.CROSSSECTIONAREA',
        value: `${this.tripResponse.vehicle.crossSectionArea} m&sup2;`,
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.EFFICIENCY',
        value: `${this.decimalPipe.transform(
          this.tripResponse.vehicle.efficiency * 100,
          '1.0-0'
        )} %`,
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.FUELEMISSIONFACTOR',
        value: `${this.tripResponse.vehicle.fuelEmissionFactor} kg/l`,
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.FUELTYPE',
        value: getFuelTypeNameByValue(
          this.ts,
          this.tripResponse.vehicle.fuelValue
        ),
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.MASS',
        value: `${this.tripResponse.vehicle.mass} kg`,
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.ROLLFRICTIONCOEFFICIENT',
        value: `${this.tripResponse.vehicle.rollFrictionCoefficient}`,
      });
      this.detailsListVehicle.push({
        label: 'VEHICLE.STANDSTILLFUELCONSUMPTION',
        value: `${this.tripResponse.vehicle.standStillFuelConsumption} ${fuelValueUnit}/h`,
      });
      this.detailsListVehicleCheckbox.push({
        label: 'VEHICLE.MOTORHEATING',
        value: this.tripResponse.vehicle.motorHeating,
      });
      this.detailsListVehicleCheckbox.push({
        label: 'VEHICLE.AIRCONDITIONING',
        value: this.tripResponse.vehicle.airConditioning,
      });
      this.detailsListVehicleCheckbox.push({
        label: 'VEHICLE.STARTSTOP',
        value: this.tripResponse.vehicle.startStop,
      });
      if (this.isAvailable(this.tripResponse.calculation)) {
        this.detailsListCalculation.push({
          label: 'TRIP.DURATION',
          value: this.msToTimeStr(
            this.tripResponse.calculation.duration * 1000
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.DISTANCE',
          value: `${this.decimalPipe.transform(
            this.tripResponse.calculation.distance,
            '1.0-1'
          )} km`,
        });
        this.detailsListCalculation.push({
          label: 'TRIP.AVERAGESPEED',
          value: `${this.decimalPipe.transform(
            this.tripResponse.calculation.averageSpeed * 3.6,
            '1.0-1'
          )} km/h`,
        });
      }
      if (this.isAvailable(this.tripResponse.absoluteCalculation)) {
        if (
          this.isAvailable(
            this.tripResponse.absoluteCalculation.fuelConsumption
          )
        ) {
          this.detailsListCalculation.push({
            label: 'TRIP.FUELCONSUMPTION',
            value: `${this.decimalPipe.transform(
              this.tripResponse.absoluteCalculation.fuelConsumption,
              '1.0-1'
            )} ${fuelValueUnit}/100km`,
          });
        }
        this.detailsListCalculation.push({
          label: 'TRIP.CO2EMISSION',
          value: `${this.decimalPipe.transform(
            this.tripResponse.absoluteCalculation.co2Emission,
            '1.0-1'
          )} kg`,
        });
      }
      if (this.isAvailable(this.tripResponse.calculation)) {
        this.detailsListCalculation.push({
          label: 'TRIP.ZEROFUELDISTANCE',
          value: `${this.decimalPipe.transform(
            this.tripResponse.calculation.zeroFuelDistance,
            '1.0-0'
          )} m`,
        });
        this.detailsListCalculation.push({
          label: 'TRIP.STANDSTILLTIME',
          value: this.msToTimeStr(
            this.tripResponse.calculation.standStillTime * 1000
          ),
        });
        if (
          this.isAvailable(this.tripResponse.calculation.percentageAccCycle)
        ) {
          this.detailsListCalculation.push({
            label: 'TRIP.PERCENTAGEACCCYCLE',
            value: `${this.decimalPipe.transform(
              this.tripResponse.calculation.percentageAccCycle * 100,
              '1.0-1'
            )} %`,
          });
        }
        if (
          this.isAvailable(this.tripResponse.calculation.percentageAeroCycle)
        ) {
          this.detailsListCalculation.push({
            label: 'TRIP.PERCENTAGEAEROCYCLE',
            value: `${this.decimalPipe.transform(
              this.tripResponse.calculation.percentageAeroCycle * 100,
              '1.0-1'
            )} %`,
          });
        }
        if (
          this.isAvailable(this.tripResponse.calculation.percentageStsCycle)
        ) {
          this.detailsListCalculation.push({
            label: 'TRIP.PERCENTAGESTSCYCLE',
            value: `${this.decimalPipe.transform(
              this.tripResponse.calculation.percentageStsCycle * 100,
              '1.0-1'
            )} %`,
          });
        }
      }
      if (this.isAvailable(this.tripResponse.absoluteCalculation)) {
        if (
          this.isAvailable(
            this.tripResponse.absoluteCalculation.percentageWorkCycle
          )
        ) {
          this.detailsListCalculation.push({
            label: 'TRIP.PERCENTAGEWORKCYCLE',
            value: `${this.decimalPipe.transform(
              this.tripResponse.absoluteCalculation.percentageWorkCycle * 100,
              '1.0-1'
            )} %`,
          });
        }
        this.detailsListCalculation.push({
          label: 'TRIP.ECOINDEX1',
          value: `${this.decimalPipe.transform(
            this.tripResponse.absoluteCalculation.ecoIndex1,
            '1.0-1'
          )}  ${fuelValueUnit}/100km*t`,
        });
        this.detailsListCalculation.push({
          label: 'TRIP.ECOINDEX4',
          value: `${this.decimalPipe.transform(
            this.tripResponse.absoluteCalculation.ecoIndex4,
            '1.0-1'
          )} kWh/100km*t`,
        });
        let wltpValues = '';
        wltpValues = this.appendWltpValue(
          wltpValues,
          this.tripResponse.calculation.percentageWltpLow,
          ' / '
        );
        wltpValues = this.appendWltpValue(
          wltpValues,
          this.tripResponse.calculation.percentageWltpMedium,
          ' / '
        );
        wltpValues = this.appendWltpValue(
          wltpValues,
          this.tripResponse.calculation.percentageWltpHigh,
          ' / '
        );
        wltpValues = this.appendWltpValue(
          wltpValues,
          this.tripResponse.calculation.percentageWltpExtraHigh,
          ' %'
        );
        this.detailsListCalculation.push({
          label: 'TRIP.PERCENTAGEWLTP',
          value: wltpValues,
        });

        this.detailsListCalculation.push({
          label: 'TRIP.TOTALWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.totalWork,
            null
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.ACCWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.accWork,
            this.tripResponse.absoluteCalculation.totalWork
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.AEROWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.aeroWork,
            this.tripResponse.absoluteCalculation.totalWork
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.STANDSTILLWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.standStillWork,
            this.tripResponse.absoluteCalculation.totalWork
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.ROLLWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.rollWork,
            this.tripResponse.absoluteCalculation.totalWork
          ),
        });
        this.detailsListCalculation.push({
          label: 'TRIP.GRADEWORK',
          value: this.computePFrom(
            this.tripResponse.absoluteCalculation.gradeWork,
            this.tripResponse.absoluteCalculation.totalWork
          ),
        });
      }
    }
  }

  // Use the higher value of percentageAccCycle and percentageStsCycle to calculate the number stars (1-5) for the traffic
  private calculateTraffic(): number {
    let minCycle: number;

    if (
      this.tripResponse.calculation.percentageAccCycle >
      this.tripResponse.calculation.percentageStsCycle
    ) {
      minCycle = this.tripResponse.calculation.percentageAccCycle;
    } else {
      minCycle = this.tripResponse.calculation.percentageStsCycle;
    }
    return this.calculateCycle(minCycle, 200, 160, 120, 80);
  }

  // Calculate the number of stars (1-5) for the route depending on 4 limits
  private calculateCycle(
    cycleValue: number,
    highestLimit: number,
    midhighLimit: number,
    midLowLimit: number,
    lowestLimit: number
  ): number {
    const CycleInPercent = Math.round(cycleValue * 100.0);

    if (CycleInPercent <= lowestLimit) {
      return 5;
    }
    if (CycleInPercent <= midLowLimit) {
      return 4;
    }
    if (CycleInPercent <= midhighLimit) {
      return 3;
    }
    if (CycleInPercent <= highestLimit) {
      return 2;
    }
    return 1;
  }

  // Calculate the number of stars (1-5) for driving
  private calculateDriving(
    highestLimit: number,
    midhighLimit: number,
    midlowLimit: number,
    lowestLimit: number
  ): number {
    const quotientInPercent = Math.round(
      this.tripResponse.calculation.zeroFuelDistance /
        (this.tripResponse.calculation.distance * 10.0)
    );

    if (quotientInPercent >= highestLimit) {
      return 5;
    }
    if (quotientInPercent >= midhighLimit) {
      return 4;
    }
    if (quotientInPercent >= midlowLimit) {
      return 3;
    }
    if (quotientInPercent >= lowestLimit) {
      return 2;
    }
    return 1;
  }

  public recalculate(): void {
    const ref = this.dialog.open(RecalculateDialogComponent, {
      width: '80%',
      data: this.tripResponse,
    });
    ref.afterClosed().subscribe(() => {
      this.tripResponse = (
        ref.componentInstance as RecalculateDialogComponent
      ).tripResponseData;
      this.computeDetails();
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public onResize(event): void {
    this.setBreakpoint(event.target.innerWidth);
  }

  private setBreakpoint(innerWidth: number): void {
    // this.breakpoint = (innerWidth <= 1000) ? 1 : 4;
    if (innerWidth < 600) {
      this.breakpoint = 1;
    } else if (innerWidth < 1000) {
      this.breakpoint = 2;
    } else {
      this.breakpoint = 4;
    }
  }
}
