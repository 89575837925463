<mat-spinner *ngIf="computingTripResponses.isRunning" [diameter]="60"></mat-spinner>
<mat-card *ngIf="showAction()">
    <mat-card-title>{{ title() }}</mat-card-title>
    <mat-card-content style="text-align: center;">
        <mat-icon>list_alt</mat-icon>{{ computingTripResponses.toCompute }}
        <div style="text-align: center; color: green;"><mat-icon>done</mat-icon>{{ computingTripResponses.computedOk }}</div>
        <div  style="text-align: center; color: red;" *ngIf="computingTripResponses.computedError > 0"><mat-icon>clear</mat-icon>{{ computingTripResponses.computedError }}</div>
    </mat-card-content>
    <mat-card-content style="text-align: center;">
        <button style="margin:5px;" mat-raised-button color="primary" (click)="cancel()" [disabled]="!computingTripResponses.isRunning">
            {{ 'CANCEL' | translate }}
        </button>
        <button style="margin:5px;" mat-raised-button color="primary" (click)="confirm()" [disabled]="computingTripResponses.isRunning">
            {{ 'OK' | translate }}
        </button>
    </mat-card-content>
</mat-card>
  