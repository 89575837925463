<mat-card class="card">
  
  <mat-card-header>
    <mat-card-title>{{ 'SENSORSTATION.MAP.OVERVIEW' | translate }}</mat-card-title>
  </mat-card-header>
  
  <div class="grafana-container" style="text-align: center;">
    <iframe class="grafana-iframe"
    [src]="url | sensorstationsUrl"
    width="1200" height="800" frameborder="0"></iframe>
  </div>
</mat-card>


