import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartPoint } from 'chart.js';
import { CalculatedPosition, Trip } from 'lcmm-lib-js';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import {
  MultiChart,
  MultiChartService,
} from 'src/app/service/multi-chart.service';
import { AbstractTripMultiInfoDistanceChartComponent } from './abstract-trip-multi-info-distance-chart.component';

@Component({
  selector: 'app-trip-multi-info-distance-chart',
  // templateUrl: './trip-multi-info-distance-chart.component.html',
  templateUrl: '../abstract-chart/abstract-chart.component.html',
  styleUrls: ['../abstract-chart/abstract-chart.component.scss'],
})
export class TripMultiInfoDistanceChartComponent extends AbstractTripMultiInfoDistanceChartComponent {
  constructor(
    translateService: TranslateService,
    mcs: MultiChartService,
    ces: ChartEventService,
    private configService: EnvConfigurationService
  ) {
    super(translateService, mcs, ces);
    this.configService = configService;
  }

  protected register(multiChart: MultiChart): void {
    const startHided = true;
    const startVisible = false;
    this.registerComputeChartDataFunction(
      multiChart,
      this.computeSpeed.bind(this),
      'TRIPS.SPEED.YLABEL',
      'green',
      'left',
      startVisible
    );
    this.registerComputeChartDataFunction(
      multiChart,
      this.computeAltitude.bind(this),
      'TRIPS.ALTITUDE.YLABEL',
      'red',
      'left',
      startHided
    );
    this.registerComputeChartDataFunction(
      multiChart,
      this.computeFuelconsumption.bind(this),
      'TRIPS.FUELCONSUMPTION.YLABEL',
      'blue',
      'right',
      startHided
    );
    this.registerComputeChartDataFunction(
      multiChart,
      this.computeCo2.bind(this),
      'TRIPS.CO2.YLABEL',
      'brown',
      'right',
      startHided
    );
    this.registerComputeChartDataFunction(
      multiChart,
      this.computeAcceleration.bind(this),
      'TRIPS.ACCELERATION.YLABEL',
      'orange',
      'right',
      startHided
    );
  }

  private computeSpeed(trip: Trip): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    let distanceSum = 0;
    trip.positions.forEach((p) => {
      if (p.distance) {
        distanceSum += p.distance;
      }
      const chartPoint: ChartPoint = {
        x: distanceSum / 1000,
        // y: this.setTicks(ticks, Math.round(p.speed * 36) / 10),
        y: Math.round(p.speed * 36) / 10,
        t: p.dtime,
      };
      chartPoints.push(chartPoint);
    });
    return chartPoints;
  }

  private computeAltitude(trip: Trip): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    let distanceSum = 0;
    trip.positions.forEach((p) => {
      if (p.distance) {
        distanceSum += p.distance;
      }
      const chartPoint: ChartPoint = {
        x: distanceSum / 1000,
        // y: this.setTicks(ticks, Math.round(p.altitude)),
        y: Math.round(p.altitude),
        t: p.dtime,
      };
      chartPoints.push(chartPoint);
    });
    return chartPoints;
  }

  private computeFuelconsumption(trip: Trip): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    let distanceSum = 0;
    trip.positions.forEach((p) => {
      if (p.distance) {
        distanceSum += p.distance;
      }
      const chartPoint: ChartPoint = {
        x: distanceSum / 1000,
        y: (100000 / p.distance) * p.fuel,
        t: p.dtime,
      };
      chartPoints.push(chartPoint);
    });
    return chartPoints;
  }

  // provides position co2 data per km aggregated into chunks of config.co2Distance m
  // eslint-disable-next-line class-methods-use-this
  private computeCo2(trip: Trip): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    // distance for the considered window
    let distance = 0;
    let co2 = 0;
    let counter = 0;
    // total distance passed so far
    let distanceSum = 0;
    const maxCo2 =
      ((trip.absoluteCalculation.co2Emission * 1000) /
        trip.calculation.distance) *
      this.configService.config.co2LimitMultiplier;
    trip.positions.forEach((p) => {
      if (p.speed > 0) {
        if (p.distance) {
          distance += p.distance;
          distanceSum += p.distance;
        }
        if (p.co2 > 0) {
          co2 += p.co2;
          if (distanceSum / this.configService.config.co2Distance > counter) {
            const emissions = Math.round((co2 * 1000 * 1000) / distance);
            chartPoints.push({
              x: distanceSum / 1000,
              y: emissions < maxCo2 ? emissions : maxCo2,
              t: p.dtime,
            });
            counter += 1;
            co2 = 0;
            distance = 0;
          }
        }
      }
    });
    return chartPoints;
  }

  private computeAcceleration(trip: Trip): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    let distanceSum = 0;
    let p1: CalculatedPosition = null;
    let a = 0;
    trip.positions.forEach((p) => {
      a = 0;
      if (p1 !== null) {
        a =
          Math.round(
            ((p.speed - p1.speed) * 100) / (p.dtime / 1000 - p1.dtime / 1000)
          ) / 100;
      }
      p1 = p;
      if (p.distance) {
        distanceSum += p.distance;
      }
      const chartPoint: ChartPoint = {
        x: distanceSum / 1000,
        y: a,
        t: p.dtime,
      };
      chartPoints.push(chartPoint);
    });
    return chartPoints;
  }
}
