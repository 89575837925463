<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let detail of detailsListTrip" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value != null">{{ detail.value }}</span>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div *ngIf="showStars()">
  <mat-grid-list class="addDistance" [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)" id="trip-details-stars">
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.ROUTEEVALUATION"></div>
        <div>
          <mat-icon color="primary" *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star">{{ (i + 1) <= routeStars ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.TRAFFICEVALUATION"></div>
        <div>
          <mat-icon color="primary" *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star">{{ (i + 1) <= trafficStars ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="info_element">
        <div class="label" translate="TRIP.DRIVINGEVALUATION"></div>
        <div>
          <mat-icon color="primary" *ngFor="let dummy of [].constructor(5); let i = index" class="material-icon-outlined" id="star">
            <span class="span-trip-star">{{ (i + 1) <= drivingStars ? 'star' : 'star_outline' }}</span>
          </mat-icon>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)">
  <mat-grid-tile *ngFor="let detail of detailsListCalculation" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value != null">{{ detail.value }}</span>
      </div>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<mat-grid-list [cols]="breakpoint" rowHeight="50px" (window:resize)="onResize($event)" class="addDistance">
  <mat-grid-tile *ngFor="let detail of detailsListVehicle" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <div class="data">
        <span *ngIf="detail.value != null" [innerHTML]="detail.value | safeHtml"></span>
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile *ngFor="let detail of detailsListVehicleCheckbox" class="gridTile">
    <div class="info_element">
      <div class="label" translate="{{ detail.label }}"></div>
      <mat-checkbox [(ngModel)]="detail.value" [disabled]="true" *ngIf="detail.value != undefined"> </mat-checkbox>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div class="single-button" *ngIf="tripResponse.vehicle.originalVehicleId && (isDispatcher || isAdmin)">
  <button mat-raised-button (click)="recalculate()">
    {{ 'TRIP.RECALCULATE' | translate }}
  </button>
</div>