import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private ts: TranslateService) {
    super();
    this.ts.onLangChange.subscribe(() => {
      this.getAndSetTranslations();
    });
    this.getAndSetTranslations();
  }

  private getAndSetTranslations(): void {
    const label = this.ts.instant('ITEMS_PER_PAGE');
    this.itemsPerPageLabel = label;
    this.changes.next();
  }
}
