/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Trip, RecalculatedTrip, TripResponse } from 'lcmm-lib-js';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import { TripService } from 'src/app/service/trip.service';

interface Recalculation {
  field: string;
  oldValue: any;
  newValue: any;
}

@Component({
  selector: 'app-recalculate-dialog',
  templateUrl: './recalculate-dialog.component.html',
  styleUrls: ['./recalculate-dialog.component.scss'],
})
export class RecalculateDialogComponent {
  public persist = true;

  public displayedColumns: string[] = ['field', 'oldValue', 'newValue'];

  public table: Recalculation[];

  public table2: Recalculation[];

  public backgroundColorChange = false;

  private tripResponseToTrip(tripResponse: TripResponse): Trip {
    const trip = tripResponse as unknown as Trip;
    if (tripResponse && tripResponse.vehicle && tripResponse.vehicle.id) {
      trip.vehicleId = tripResponse.vehicle.id;
    }
    return trip;
  }

  constructor(
    private translateService: TranslateService,
    private tripService: TripService,
    @Inject(MAT_DIALOG_DATA) public tripResponseData: TripResponse,
    private ces: ChartEventService,
    private dialogRef: MatDialogRef<RecalculateDialogComponent>
  ) {
    this.recalculate(false);
  }

  public recalculate(persist: boolean): void {
    this.tripService
      .recalculateTrip(
        this.tripResponseToTrip(this.tripResponseData),
        (this.persist = persist)
      )
      .subscribe((recalTrip: RecalculatedTrip) => {
        if (this.persist) {
          this.tripResponseData = recalTrip.newTrip as unknown as TripResponse;
          this.ces.emit(CallbackEventType.tripChanged, this.tripResponseData);
          this.dialogRef.close();
        }
        this.table = Object.keys(recalTrip.oldTrip.calculation).map(
          (key): Recalculation => {
            return {
              field: this.translateService.instant(`TRIP.${key.toUpperCase()}`),
              oldValue: recalTrip.oldTrip.calculation[key],
              // Must be 'newValue: recalTrip['newTrip'].calculation[key],', auto-fix changes it
              newValue: recalTrip.newTrip.calculation[key],
            };
          }
        );
        this.table2 = Object.keys(recalTrip.oldTrip.absoluteCalculation).map(
          (key): Recalculation => {
            return {
              field: this.translateService.instant(`TRIP.${key.toUpperCase()}`),
              oldValue: recalTrip.oldTrip.absoluteCalculation[key],
              // Must be 'newValue: recalTrip['newTrip'].calculation[key],', auto-fix changes it
              newValue: recalTrip.newTrip.absoluteCalculation[key],
            };
          }
        );
      });
  }
}
