<h1 mat-dialog-title>{{ 'GROUP.SHOWCODE' | translate }} {{ gc.groupName }}</h1>
<div class="code-div" *ngIf="gc.link">{{ gc.link }}</div>
<div class="code-div" *ngIf="!gc.link">-</div>
<div class="code-div">
  <mat-label class="code-label">{{ 'GROUP.CODESTATUS' | translate }}: </mat-label>
  {{ gc.status }}
</div>
<div mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]>
    {{ 'CLOSE' | translate }}
  </button>
  <button  mat-raised-button (click)="getGroupCode(true)">
    {{ 'GROUP.RENEWCODE' | translate }}
  </button>
  <button mat-raised-button (click)="copyCode()" [disabled]="!gc.link">
    {{ 'GROUP.CODECOPY' | translate }}
    <mat-icon *ngIf="showDone">done</mat-icon>
  </button>
</div>
