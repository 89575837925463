import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Trip, TripResponse } from 'lcmm-lib-js';
import { Observable, Subscription } from 'rxjs';
import { MultiChartService } from 'src/app/service/multi-chart.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { UntypedFormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import {
  TripSectionParameter,
  TripService,
} from 'src/app/service/trip.service';
import {
  CallbackEventType,
  CallBackFunction,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import { DownloadType } from 'src/app/service/download.service';
import { AuthService } from 'src/app/service/auth.service';
import { tripResponseToTrip } from '../../../utils/utils';
import { XAxisType } from './abstract-multi-chart/abstract-multi-chart.component';
import { TripAnalysisDialogComponent } from '../trip-analysis-dialog/trip-analysis-dialog.component';

@Component({
  selector: 'app-trip-analysis',
  templateUrl: './trip-analysis.component.html',
  styleUrls: ['./trip-analysis.component.scss'],
})
export class TripAnalysisComponent implements OnInit, OnDestroy {
  @Input()
  public tripResponse!: TripResponse;

  @Input()
  public isSectionDialog = false;

  @Input()
  public tripSectionParameter: TripSectionParameter = null;

  @Input()
  public disableStars = false;

  private static connectedIcon = 'link';

  private static unconnectedIcon = 'link_off';

  public tripObservable: Observable<Trip>;

  public trip: Trip;

  private tripSubscription: Subscription = null;

  public kmlLoading = false;

  public csvLoading = false;

  public sectionDetailsLoading = false;

  public selectedTabIndex: number;

  private chartXAxisType: XAxisType;

  private areXAxisConnected: boolean;

  private xAxisConnectionIcon: string;

  public dateStartMin: Date;

  public dateStartMax: Date;

  public dateEndMin: Date;

  public dateEndMax: Date;

  public dateStart: Date;

  public dateEnd: Date;

  public startDateValid = true;

  public endDateValid = true;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public myControlStartDate = new UntypedFormControl(null);

  public myControlEndDate = new UntypedFormControl(null);

  public dialogRefDetailsOpen = false;

  public selectedTime: Date = new Date();

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public tripService: TripService,
    private mcs: MultiChartService,
    private datePipe: DatePipe,
    private ts: TranslateService,
    private ces: ChartEventService
  ) {
    this.resetXAxis();
    this.selectedTabIndex = 0;
    this.deRegister(true);
  }

  private deRegister(register: boolean): void {
    if (!this.isSectionDialog) {
      if (register) {
        const cbfChanged: CallBackFunction = this.sectionChanged.bind(this);
        this.ces.register(
          'TripAnalysisComponent',
          CallbackEventType.changed,
          cbfChanged
        );
        const cbfCleared: CallBackFunction = this.sectionCleared.bind(this);
        this.ces.register(
          'TripAnalysisComponent',
          CallbackEventType.cleared,
          cbfCleared
        );
        const cbfShow: CallBackFunction = this.sectionShow.bind(this);
        this.ces.register(
          'TripAnalysisComponent',
          CallbackEventType.show,
          cbfShow
        );
        const cbfNewData: CallBackFunction = this.newData.bind(this);
        this.ces.register(
          'TripAnalysisComponent',
          CallbackEventType.newData,
          cbfNewData
        );
        const cbfTripChanged: CallBackFunction = this.tripChanged.bind(this);
        this.ces.register(
          'TripAnalysisComponent',
          CallbackEventType.tripChanged,
          cbfTripChanged
        );
      } else {
        this.ces.deregisterAll('TripAnalysisComponent');
      }
    }
  }

  private newData(tripResponse: TripResponse): void {
    if (this.tripResponse.id !== tripResponse.id) {
      this.tripResponse = tripResponse;
      this.ngOnInit();
    }
  }

  private tripChanged(tripResponse: TripResponse): void {
    this.tripResponse = tripResponse;
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.trip = tripResponseToTrip(this.tripResponse);
    this.selectedTabIndex = 0;
    if (this.isSectionDialog !== undefined && this.isSectionDialog) {
      this.tripObservable = null;
    } else {
      this.isSectionDialog = false;
      this.tripObservable = this.tripService.createDetailedTripWebSocket(
        this.tripResponse
      );
    }
    this.initRange();
    this.mcs.reset(this.isSectionDialog);
    this.subscribeTrip();
    this.myControlStartDate.statusChanges.subscribe((status) => {
      this.startDateValid = status === 'VALID';
    });
    this.myControlEndDate.statusChanges.subscribe((status) => {
      this.endDateValid = status === 'VALID';
    });
  }

  ngOnDestroy(): void {
    this.deRegister(false);
    if (!this.isSectionDialog) {
      this.tripService.stopStream();
    }
    this.mcs.reset(this.isSectionDialog);
    this.resetXAxis();
    this.unsubscribeTrip();
  }

  private subscribeTrip(): void {
    if (this.tripObservable) {
      this.tripSubscription = this.tripObservable.subscribe(async (t) => {
        if (t !== null) {
          this.setRange(new Date(t.startTime), new Date(t.endTime));
        }
      });
    }
  }

  private unsubscribeTrip(): void {
    if (this.tripSubscription !== null) {
      this.tripSubscription.unsubscribe();
    }
  }

  private initRange(): void {
    this.dateStartMin = new Date(this.tripResponse.startTime);
    this.dateEndMin = new Date(this.tripResponse.startTime);
    this.dateStart = new Date(this.tripResponse.startTime);
    let et = this.tripResponse.endTime;
    if (et === undefined) {
      et = new Date();
    }
    this.dateEnd = new Date(et);
    this.dateEndMax = new Date(et);
    this.dateStartMax = new Date(et);
    this.tripSectionParameter = null;
  }

  public isTripSectionChanged(): boolean {
    return this.tripSectionParameter != null;
  }

  private setRange(start: Date, end: Date): void {
    if (end === undefined || end === null) {
      // eslint-disable-next-line no-param-reassign
      end = new Date();
    }
    if (this.dateStartMin === undefined) {
      this.dateStartMin = new Date(start);
      this.dateEndMin = new Date(start);
      this.dateStart = new Date(start);
      this.dateEnd = new Date(end);
    }
    if (this.dateStartMin.getTime() === start.getTime()) {
      this.dateEndMax = new Date(end);
      this.dateStartMax = new Date(end);
      if (this.dateEnd.getTime() > this.dateEndMax.getTime()) {
        this.dateEnd = new Date(this.dateEndMax);
      }
    }
  }

  private resetXAxis(): void {
    this.chartXAxisType = 'TimeChart';
    this.areXAxisConnected = false;
    this.xAxisConnectionIcon = TripAnalysisComponent.unconnectedIcon;
  }

  private setLoading(type: DownloadType, value: boolean): void {
    if (type === DownloadType.kml) {
      this.kmlLoading = value;
    }
    if (type === DownloadType.csv) {
      this.csvLoading = value;
    }
  }

  private download(type: DownloadType): void {
    this.setLoading(type, true);
    this.tripService
      .downloadTrip(
        tripResponseToTrip(this.tripResponse),
        type,
        this.isSectionDialog ? this.tripSectionParameter : null
      )
      .subscribe()
      .add(() => {
        this.setLoading(type, false);
      });
  }

  public downloadKml(): void {
    this.download(DownloadType.kml);
  }

  public downloadCsv(): void {
    this.download(DownloadType.csv);
  }

  public switchXAxis(): void {
    if (this.chartXAxisType === 'TimeChart') {
      if (this.areXAxisConnected) {
        this.mcs.syncChartAxis(
          this.chartXAxisType,
          'DistanceChart',
          this.isSectionDialog
        );
      }
      this.chartXAxisType = 'DistanceChart';
    } else {
      if (this.areXAxisConnected) {
        this.mcs.syncChartAxis(
          this.chartXAxisType,
          'TimeChart',
          this.isSectionDialog
        );
      }
      this.chartXAxisType = 'TimeChart';
    }
  }

  public tabChanged(): void {
    if (!this.isSectionDialog) {
      this.initRange();
    }
  }

  public getShowXAxisTime(): boolean {
    return this.chartXAxisType === 'TimeChart';
  }

  public switchXAxisConnection(): void {
    if (this.areXAxisConnected) {
      this.areXAxisConnected = false;
      this.xAxisConnectionIcon = TripAnalysisComponent.unconnectedIcon;
    } else {
      this.areXAxisConnected = true;
      this.xAxisConnectionIcon = TripAnalysisComponent.connectedIcon;
    }
  }

  public getXAxisConnectionIcon(): string {
    return this.xAxisConnectionIcon;
  }

  private getTripSectionParameter(): TripSectionParameter {
    if (this.tripSectionParameter === null) {
      this.tripSectionParameter =
        TripService.createTripSectionParameter('details');
    }
    return this.tripSectionParameter;
  }

  private setStartEndParameter(): void {
    this.getTripSectionParameter().time.startTime = this.dateStart;
    this.getTripSectionParameter().time.endTime = this.dateEnd;
  }

  public startTimeChanged(dateTime: string): void {
    this.dateStart = new Date(dateTime as string);
    this.dateEndMin = new Date(dateTime as string);
    this.setStartEndParameter();
  }

  public endTimeChanged(dateTime: string): void {
    this.dateEnd = new Date(dateTime);
    this.dateStartMax = new Date(dateTime);
    this.setStartEndParameter();
  }

  public sectionReset(): void {
    this.initRange();
  }

  public sectionDetails(): void {
    if (!this.isSectionDetailsOpen()) {
      this.sectionDetailsLoading = true;
      this.tripService
        .getDetailedTrip(
          this.tripResponse.groupName,
          this.tripResponse.id,
          this.tripSectionParameter
        )
        .subscribe(
          (tripSection: TripResponse) => {
            if (tripSection) {
              const tr: TripResponse = { ...this.tripResponse };
              tr.startTime = tripSection.startTime;
              tr.endTime = tripSection.endTime;
              tr.absoluteCalculation = tripSection.absoluteCalculation;
              tr.calculation = tripSection.calculation;
              tr.positions = tripSection.positions;
              tr.ranking = tripSection.ranking;
              this.showSectionDetails(tr);
            }
          },
          () => {
            this.sectionDetailsLoading = false;
          },
          () => {
            this.sectionDetailsLoading = false;
          }
        );
    }
  }

  public showSectionDetails(tripResponse: TripResponse): void {
    this.dialogRefDetailsOpen = true;
    const dialogRefDetails = this.dialog.open(TripAnalysisDialogComponent, {
      width: '80%',
      data: {
        tripResponse,
        isSectionDialog: true,
        tripSectionParameter: this.tripSectionParameter,
      },
      disableClose: true,
    });
    if (dialogRefDetails !== null) {
      dialogRefDetails.afterClosed().subscribe(() => {
        this.dialogRefDetailsOpen = false;
      });
    } else {
      this.dialogRefDetailsOpen = false;
    }
  }

  public isSectionDetailsOpen(): boolean {
    return this.dialogRefDetailsOpen;
  }

  public getTripInfo(): string {
    let txt = this.ts.instant('TRIP.TITLE') as string;
    if (this.tripResponse) {
      const ds = new Date(this.tripResponse.startTime);
      const de = new Date(this.tripResponse.endTime);
      txt += ` (${this.tripResponse.id})`;
      if (this.isSectionDialog) {
        txt += ` ${this.ts.instant('TRIP.SECTION') as string}`;
      }
      txt += ` ${this.datePipe.transform(
        this.tripResponse.startTime,
        'dd.MM.yyyy, HH:mm:ss'
      )} -> `;
      if (ds.getDate() === de.getDate()) {
        txt += `${this.datePipe.transform(
          this.tripResponse.endTime,
          'HH:mm:ss'
        )}`;
      } else {
        txt += `${this.datePipe.transform(
          this.tripResponse.endTime,
          'dd.MM.yyyy, HH:mm:ss'
        )}`;
      }
    }
    return txt;
  }

  public sectionChanged(tsp: TripSectionParameter): void {
    this.tripSectionParameter = tsp;
  }

  public sectionCleared(): void {
    this.tripSectionParameter = null;
  }

  public sectionShow(tsp: TripSectionParameter): void {
    this.tripSectionParameter = tsp;
    this.sectionDetails();
  }

  public isSectionDetailsButtonDisabled(): boolean {
    return (
      this.isSectionDetailsOpen() ||
      !this.isTripSectionChanged() ||
      this.sectionDetailsLoading ||
      !this.startDateValid ||
      !this.endDateValid
    );
  }
}
