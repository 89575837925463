<h1 mat-dialog-title><span translate="SENSORSTATION.SENSORSTATION"> </span> {{ form.value.deviceId }}</h1>
<form [formGroup]="form" cdk-focus-start>
    <app-sensorstation-form [groups]="groups" [sensorstation]="sensorstation" [allowEditing]="allowEditing"> </app-sensorstation-form>
  </form>
  <div mat-dialog-actions>
    <button *ngIf="allowEditing" mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">
      {{ 'UPDATE' | translate }}
    </button>
    <button mat-raised-button (click)="onClose()">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
  <app-overlay-spinner *ngIf="loading"></app-overlay-spinner>