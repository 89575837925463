import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { TripResponse } from 'lcmm-lib-js';
import {
  durationtoSeconds,
  isFuelTypeValueElectric,
  secondsToDuration2,
} from 'src/app/utils/utils';

enum DetailType {
  'string',
  'number',
  'date',
  'boolean',
  'period',
}

enum ViewType {
  'all',
  'compare',
  'average',
  'none',
}

interface ShowOptions {
  attributeName?: string;
  formatString?: string;
}

interface MultipleDetailShowOptions {
  total?: boolean;
  percentFrom?: ShowOptions;
  percentFromTotal?: ShowOptions;
  average?: boolean;
  onElectric?: boolean;
}

interface MultipleDetail {
  ATTRIBUTE: string;
  CURRENCY: string[];
  AVERAGE: string;
  TOTAL: string;
  ELECTRICAVERAGE: string;
  ELECTRICTOTAL: string;
  pvalues: number[];
  values: unknown[];
  averages: unknown[];
  detailType: DetailType;
  viewType: ViewType;
  formatString?: string;
  showOptions?: MultipleDetailShowOptions;
  hiddenDetail?: boolean;
}

@Component({
  selector: 'app-trip-multiple-details',
  templateUrl: './trip-multiple-details.component.html',
  styleUrls: ['./trip-multiple-details.component.scss'],
})
export class TripMultipleDetailsComponent implements OnInit {
  private showCurrencyColumn = false;

  public titleKey: string = null;

  public tableData: MultipleDetail[];

  private tableDataIsElectricColumn: boolean[];

  public displayedColumns: string[] = [];

  private locale: string;

  private viewType: ViewType;

  public tripResponses: TripResponse[] = [];

  public isAdmin: boolean;

  public isDispatcher: boolean;

  public showCompare: boolean;

  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private ts: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tripResponses: TripResponse[];
      isDispatcher: boolean;
      isAdmin: boolean;
      showCompare: boolean;
    }
  ) {
    this.locale = ts.instant('LOCALE');
    this.tripResponses = data.tripResponses;
    this.isAdmin = data.isAdmin;
    this.isDispatcher = data.isDispatcher;
    this.showCompare = data.showCompare;
    this.titleKey = this.showCompare
      ? 'TRIP.COMPARE'
      : 'TRIP.COMPUTEMEANVALUES';
    this.viewType = this.showCompare ? ViewType.compare : ViewType.average;
  }

  ngOnInit(): void {
    this.determineElectricTrips();
    this.createColumns();
    this.createRows();
    this.setRowValues();
    this.computeData(this.tableData);
  }

  private string2Number(str: string): number {
    try {
      const num = Number(str);
      if (Number.isNaN(num)) {
        return Number(str.replace('.', '').replace(',', '.'));
      }
      return num;
    } catch (err) {
      return 0;
    }
  }

  private createColumns(): void {
    this.displayedColumns = ['ATTRIBUTE'];
    if (this.showCurrencyColumn) {
      this.displayedColumns.push('CURRENCY');
    }
    if (this.viewType === ViewType.compare) {
      for (let i = 0; i < this.tripResponses.length; i += 1) {
        this.displayedColumns.push(`DISPLAY${i}`);
      }
    }
    if (
      this.viewType !== ViewType.average ||
      this.getNotElectricColumnCount() > 0
    ) {
      this.displayedColumns.push('AVERAGE');
      this.displayedColumns.push('TOTAL');
    }
    if (
      this.viewType === ViewType.average &&
      this.getElectricColumnCount() > 0
    ) {
      this.displayedColumns.push('ELECTRICAVERAGE');
      this.displayedColumns.push('ELECTRICTOTAL');
    }
  }

  private appendBolt(text: string): string {
    return `${text} <img src="assets/bolt_green.png" width="10" height="10">`;
  }

  private appendBoltOnElectric(index: number, text: string): string {
    if (this.isElectric(index)) {
      return this.appendBolt(text);
    }
    return text;
  }

  private getTripHeader(index: number): string {
    const msg = `${
      this.tripResponses[index].vehicle.name
    } - ${this.datePipe.transform(
      this.tripResponses[index].startTime as Date,
      'dd.MM.yy HH:mm',
      'UTC'
    )}`;
    return this.appendBoltOnElectric(index, msg);
  }

  private attributeColHeader(): string {
    const electricTripCount = this.getElectricColumnCount();
    let msg = `${this.tripResponses.length} ${this.ts.instant('TRIP.TRIPS')}`;
    if (electricTripCount) {
      if (this.getNotElectricColumnCount()) {
        msg = `${msg}${this.ts.instant(
          'TRIP.AREELECTRIC'
        )}${electricTripCount}`;
      }
      msg = this.appendBolt(msg);
    }
    return msg;
  }

  private averageColHeader(): string {
    const electricTripCount = this.getElectricColumnCount();
    let msg = `${this.ts.instant('TRIP.AVERAGE')}`;
    if (electricTripCount && this.viewType === ViewType.compare) {
      if (this.getNotElectricColumnCount()) {
        msg = `${msg} | ${msg}`;
      }
      msg = this.appendBolt(msg);
    }
    return msg;
  }

  private totalColHeader(): string {
    const electricTripCount = this.getElectricColumnCount();
    let msg = `${this.ts.instant('TRIP.TOTAL')}`;
    if (electricTripCount && this.viewType === ViewType.compare) {
      if (this.getNotElectricColumnCount()) {
        msg = `${msg} | ${msg}`;
      }
      msg = this.appendBolt(msg);
    }
    return msg;
  }

  private electricaverageColHeader(): string {
    return this.appendBolt(`${this.ts.instant('TRIP.AVERAGE')}`);
  }

  private electrictotalColHeader(): string {
    return this.appendBolt(`${this.ts.instant('TRIP.TOTAL')}`);
  }

  public getHeader(column: string): string {
    if (column.startsWith('DISPLAY')) {
      return this.getTripHeader(Number(column.replace('DISPLAY', '')));
    }
    if (column === 'CURRENCY') {
      return null;
    }
    if (column === 'ATTRIBUTE') {
      return this.attributeColHeader();
    }
    if (column === 'TOTAL') {
      return this.totalColHeader();
    }
    if (column === 'AVERAGE') {
      return this.averageColHeader();
    }
    if (column === 'ELECTRICTOTAL') {
      return this.electrictotalColHeader();
    }
    if (column === 'ELECTRICAVERAGE') {
      return this.electricaverageColHeader();
    }
    return null;
  }

  private formatValue(md: MultipleDetail, value: unknown): string {
    switch (md.detailType) {
      case DetailType.number: {
        return this.decimalPipe.transform(
          value as number,
          md.formatString,
          this.locale
        );
      }
      case DetailType.boolean: {
        return this.ts.instant(`TRIP.${value}`);
      }
      case DetailType.date: {
        if (md.formatString)
          return this.datePipe.transform(
            value as Date,
            md.formatString,
            'UTC',
            this.locale
          );
        return value as string;
      }
      case DetailType.period: {
        return secondsToDuration2(value as number, this.locale);
      }
      case DetailType.string:
      default: {
        return value as string;
      }
    }
  }

  private determineElectricTrips(): void {
    this.tableDataIsElectricColumn = [];
    if (this.tripResponses && this.tripResponses.length > 0) {
      for (let i = 0; i < this.tripResponses.length; i += 1) {
        this.setIsElectric(
          i,
          isFuelTypeValueElectric(this.tripResponses[i].vehicle.fuelValue)
        );
      }
    }
  }

  private addValue(tableDataIndex: number, value: unknown): void {
    const md = this.tableData[tableDataIndex];
    md.values.push(value);
  }

  private value2str(md: MultipleDetail, index: number): string {
    if (!md.values || md.values.length <= 0) {
      return null;
    }
    return md.values[index] as string;
  }

  private setPValue(
    md: MultipleDetail,
    index: number,
    value: number,
    total: number
  ): void {
    if (value) {
      const _md = md;
      _md.pvalues[index] = (value / total) * 100;
    }
  }

  private computePTotal(
    md: MultipleDetail,
    mdNum: number,
    total: unknown
  ): void {
    const _md = md;
    if (
      total === null ||
      mdNum <= 0 ||
      _md === null ||
      _md.showOptions?.percentFrom
    ) {
      return;
    }
    if (_md.detailType === DetailType.number) {
      const numtot = this.string2Number(total as string);
      if (numtot !== 0) {
        for (let i = 0; i < mdNum; i += 1) {
          this.setPValue(
            _md,
            i,
            this.string2Number(this.value2str(_md, i)),
            numtot
          );
        }
      }
    } else if (_md.detailType === DetailType.period) {
      const numtot = durationtoSeconds(total as string);
      if (numtot !== 0) {
        for (let i = 0; i < mdNum; i += 1) {
          this.setPValue(
            _md,
            i,
            this.string2Number(this.value2str(_md, i)),
            numtot
          );
        }
      }
    }
  }

  private computePFrom(
    md: MultipleDetail,
    mdNum: number,
    data: MultipleDetail[]
  ): void {
    const _md = md;
    if (
      data === null ||
      data.length <= 0 ||
      _md === null ||
      _md.showOptions?.percentFromTotal
    ) {
      return;
    }
    const attr = this.ts.instant(
      `TRIP.${_md.showOptions.percentFrom.attributeName}`
    );
    let mdf = null;
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].ATTRIBUTE === attr) {
        mdf = data[i];
        break;
      }
    }
    if (mdf !== null) {
      for (let i = 0; i < mdNum; i += 1) {
        const numtot = mdf.values[i] as number;
        if (numtot !== 0) {
          this.setPValue(_md, i, _md.values[i] as number, numtot);
        }
      }
    }
  }

  private _getCURRENCY(md: MultipleDetail, index: number): string {
    if (!(md && md.CURRENCY && md.CURRENCY.length > 0)) {
      return null;
    }
    if (md.CURRENCY.length === 1) {
      return md.CURRENCY[0];
    }
    if (md.CURRENCY.length > index) {
      return md.CURRENCY[index];
    }
    return null;
  }

  private getCURRENCY(md: MultipleDetail, index: number): string {
    if (this.isElectric(index) && this.getShowOption(md, 'onElectric')) {
      return this._getCURRENCY(md, 1);
    }
    return this._getCURRENCY(md, 0);
  }

  private setIsElectric(index: number, value: boolean): void {
    this.tableDataIsElectricColumn[index] = value;
  }

  private getElectricColumnCount(): number {
    if (
      !this.tableDataIsElectricColumn ||
      this.tableDataIsElectricColumn.length <= 0
    ) {
      return 0;
    }
    let c = 0;
    for (let i = 0; i < this.tableDataIsElectricColumn.length; i += 1) {
      if (this.tableDataIsElectricColumn[i]) {
        c += 1;
      }
    }
    return c;
  }

  private getNotElectricColumnCount(): number {
    if (
      !this.tableDataIsElectricColumn ||
      this.tableDataIsElectricColumn.length <= 0
    ) {
      return 0;
    }
    return (
      this.tableDataIsElectricColumn.length - this.getElectricColumnCount()
    );
  }

  private computeData(data: MultipleDetail[]): void {
    for (let index = 0; index < data.length; index += 1) {
      const md: MultipleDetail = data[index];
      const mvs = md.values;
      const mvsLen = mvs.length;
      let total = null;
      let average = null;
      let currency: string = null;
      let electricTotal = null;
      let electricAverage = null;
      let electricCurrency: string = null;
      let doFormat = true;
      switch (md.detailType) {
        case DetailType.string: {
          const strSet = new Set<string>();
          for (let i = 0; i < mvsLen; i += 1) {
            strSet.add(mvs[i] as string);
          }
          average = Array.from(strSet);
          break;
        }
        case DetailType.period:
        case DetailType.number: {
          let _count = 0;
          let _total = 0;
          let _electricCount = 0;
          let _electricTotal = 0;
          for (let i = 0; i < mvsLen; i += 1) {
            if (md.showOptions?.onElectric && this.isElectric(i)) {
              _electricCount += 1;
              _electricTotal += mvs[i] as number;
              electricCurrency = this.getCURRENCY(md, i);
            } else {
              _count += 1;
              _total += mvs[i] as number;
              currency = this.getCURRENCY(md, i);
            }
          }
          if (_electricCount > 0) {
            electricTotal = _electricTotal;
            electricAverage = _electricTotal / _electricCount;
          }
          if (_count > 0) {
            total = _total;
            average = _total / _count;
          }
          break;
        }
        case DetailType.boolean: {
          doFormat = false;
          let trueCounter = 0;
          for (let i = 0; i < mvsLen; i += 1) {
            if (mvs[i]) {
              trueCounter += 1;
            }
          }
          average = trueCounter >= mvsLen / 2;
          average = this.formatValue(md, average);
          const tr = this.formatValue(md, true);
          const fa = this.formatValue(md, false);
          average += ` ( ${trueCounter} ${tr}, ${mvsLen - trueCounter} ${fa} )`;
          break;
        }
        case DetailType.date: {
          let first: Date = null;
          let last: Date = null;
          for (let i = 0; i < mvsLen; i += 1) {
            if (first === null || first > mvs[i]) {
              first = mvs[i] as Date;
            }
            if (last === null || last < mvs[i]) {
              last = mvs[i] as Date;
            }
          }
          doFormat = false;
          average = `${this.formatValue(md, first)} -> ${this.formatValue(
            md,
            last
          )}`;
          break;
        }
        default: {
          break;
        }
      }

      if (doFormat) {
        if (electricAverage) {
          electricAverage = this.formatValue(md, electricAverage);
        }
        if (electricTotal != null) {
          electricTotal = this.formatValue(md, electricTotal);
        }
        if (average) {
          average = this.formatValue(md, average);
        }
        if (total != null) {
          total = this.formatValue(md, total);
        }
      }

      if (md.viewType === this.viewType || md.viewType === ViewType.all) {
        if (md.showOptions?.total) {
          md.TOTAL = total;
          if (md.TOTAL && currency) {
            md.TOTAL = `${md.TOTAL} ${currency}`;
          }
          md.ELECTRICTOTAL = electricTotal;
          if (md.ELECTRICTOTAL && electricCurrency) {
            md.ELECTRICTOTAL = `${md.ELECTRICTOTAL} ${electricCurrency}`;
          }
          if (md.ELECTRICTOTAL && this.viewType === ViewType.compare) {
            if (md.TOTAL) {
              md.TOTAL = `${md.TOTAL} | ${md.ELECTRICTOTAL}`;
            } else {
              md.TOTAL = `${md.ELECTRICTOTAL}`;
            }
          }
        }
        if (md.showOptions?.average) {
          md.AVERAGE = average;
          if (md.AVERAGE && currency) {
            md.AVERAGE = `${md.AVERAGE} ${currency}`;
          }
          md.ELECTRICAVERAGE = electricAverage;
          if (md.ELECTRICAVERAGE && electricCurrency) {
            md.ELECTRICAVERAGE = `${md.ELECTRICAVERAGE} ${electricCurrency}`;
          }
          if (md.ELECTRICAVERAGE && this.viewType === ViewType.compare) {
            if (md.AVERAGE) {
              md.AVERAGE = `${md.AVERAGE} | ${md.ELECTRICAVERAGE}`;
            } else {
              md.AVERAGE = `${md.ELECTRICAVERAGE}`;
            }
          }
        }
        if (md.showOptions?.percentFromTotal) {
          this.computePTotal(md, mvsLen, total);
        }
        if (md.showOptions?.percentFrom) {
          this.computePFrom(md, mvsLen, data);
        }
        this.setDISPLAY(md, mvsLen);
      } else {
        md.hiddenDetail = true;
      }
    }
  }

  private setDISPLAY(md: MultipleDetail, mvsLen: number): void {
    const _md = md;
    for (let index = 0; index < mvsLen; index += 1) {
      const value = _md.values[index];
      let strValue = null;
      switch (_md.detailType) {
        case DetailType.number:
          if ((value as number) > 0) {
            if (_md.formatString) {
              strValue = this.decimalPipe.transform(
                value as number,
                _md.formatString,
                this.locale
              );
            } else {
              strValue = Number(value).toString();
            }
          }
          break;
        case DetailType.period:
          if ((value as number) > 0) {
            strValue = secondsToDuration2(value as number, this.locale);
          }
          break;
        case DetailType.date:
          strValue = this.datePipe.transform(
            value as Date,
            _md.formatString,
            'UTC'
          );
          break;
        default:
          strValue = value as string;
          break;
      }
      if (strValue) {
        const currency = this.getCURRENCY(_md, index);
        strValue = currency ? `${strValue} ${currency}` : strValue;
        if (_md.pvalues && _md.pvalues.length > index) {
          const pvalue = _md.pvalues[index];
          if (pvalue) {
            const strPValue = this.decimalPipe.transform(
              _md.pvalues[index],
              '1.0-0',
              this.locale
            );
            strValue = `${strValue} (${strPValue} %)`;
          }
        }
      }
      _md[`DISPLAY${index}`] = strValue;
    }
  }

  private setRowMeta(
    attribute: string,
    type: DetailType,
    viewType: ViewType,
    currency?: string[],
    formatString?: string,
    showOptions?: MultipleDetailShowOptions,
    hidden?: boolean
  ): void {
    const attr = this.ts.instant(attribute);
    const curr = currency === undefined ? [] : currency;
    this.tableData.push({
      ATTRIBUTE: attr,
      CURRENCY: curr,
      AVERAGE: null,
      TOTAL: null,
      ELECTRICAVERAGE: null,
      ELECTRICTOTAL: null,
      averages: [],
      values: [],
      pvalues: [],
      detailType: type,
      viewType,
      showOptions,
      formatString,
      hiddenDetail: hidden,
    });
  }

  private setValue(attribute: string, value: unknown): void {
    const attr = this.ts.instant(attribute);
    for (let i = 0; i < this.tableData.length; i += 1) {
      if (this.tableData[i].ATTRIBUTE === attr) {
        this.addValue(i, value);
        return;
      }
    }
  }

  private isAvailable(attribute: unknown): boolean {
    return attribute !== undefined && attribute !== null;
  }

  private setRowValues() {
    for (let i = 0; i < this.tripResponses.length; i += 1) {
      const t = this.tripResponses[i];
      if (this.isAvailable(t)) {
        this.setValue('TRIP.TITLE', t.id);
        this.setValue('TRIP.STARTTIME', t.startTime);
        if (this.isAvailable(t.vehicle)) {
          const v = t.vehicle;
          this.setValue('VEHICLE.FUELVALUE', v.fuelValue);
          this.setValue('TRIP.VEHICLENAME', v.name);
          this.setValue('TRIP.GROUPNAME', v.groupName);
          this.setValue('VEHICLE.EFFICIENCY', v.efficiency * 100);
          this.setValue('VEHICLE.FUELEMISSIONFACTOR', v.fuelEmissionFactor);
          this.setValue('VEHICLE.MASS', v.mass);
          this.setValue(
            'VEHICLE.ROLLFRICTIONCOEFFICIENT',
            v.rollFrictionCoefficient
          );
          this.setValue(
            'VEHICLE.STANDSTILLFUELCONSUMPTION',
            v.standStillFuelConsumption
          );
          this.setValue('VEHICLE.MOTORHEATING', v.motorHeating);
          this.setValue('VEHICLE.AIRCONDITIONING', v.airConditioning);
          this.setValue('VEHICLE.STARTSTOP', v.startStop);
          this.setValue('VEHICLE.CROSSSECTIONAREA', v.crossSectionArea);
        }
        if (this.isAvailable(t.endTime)) {
          this.setValue('TRIP.ENDTIME', t.endTime);
        }
        if (this.isAvailable(t.calculation)) {
          const c = t.calculation;
          this.setValue('TRIP.DURATION', c.duration);
          this.setValue('TRIP.DISTANCE', c.distance);
          this.setValue('TRIP.POSITIONCOUNT', c.positionCount);
          this.setValue('TRIP.AVERAGESPEED', c.averageSpeed * 3.6);
          this.setValue('TRIP.ZEROFUELDISTANCE', c.zeroFuelDistance / 1000);
          this.setValue('TRIP.STANDSTILLTIME', c.standStillTime);
          this.setValue('TRIP.PERCENTAGEACCCYCLE', c.percentageAccCycle * 100);
          this.setValue(
            'TRIP.PERCENTAGEAEROCYCLE',
            c.percentageAeroCycle * 100
          );
          this.setValue('TRIP.PERCENTAGESTSCYCLE', c.percentageStsCycle * 100);
          this.setValue('TRIP.PERCENTAGEWLTPLOW', c.percentageWltpLow * 100);
          this.setValue(
            'TRIP.PERCENTAGEWLTPMEDIUM',
            c.percentageWltpMedium * 100
          );
          this.setValue('TRIP.PERCENTAGEWLTPHIGH', c.percentageWltpHigh * 100);
          this.setValue(
            'TRIP.PERCENTAGEWLTPEXTRAHIGH',
            c.percentageWltpExtraHigh * 100
          );
        }
        if (this.isAvailable(t.absoluteCalculation)) {
          const a = t.absoluteCalculation;
          this.setValue('TRIP.FUELCONSUMPTION', a.fuelConsumption);
          this.setValue('TRIP.ENERGYCONSUMPTION', a.totalWork / 1e6);
          this.setValue('TRIP.CO2EMISSION', a.co2Emission);
          this.setValue(
            'TRIP.PERCENTAGEWORKCYCLE',
            a.percentageWorkCycle * 100
          );
          this.setValue('TRIP.ECOINDEX1', a.ecoIndex1);
          this.setValue('TRIP.ECOINDEX4', a.ecoIndex4);
          this.setValue('TRIP.ACCWORK', a.accWork / 1e6);
          this.setValue('TRIP.AEROWORK', a.aeroWork / 1e6);
          this.setValue('TRIP.STANDSTILLWORK', a.standStillWork / 1e6);
          this.setValue('TRIP.ROLLWORK', a.rollWork / 1e6);
          this.setValue('TRIP.GRADEWORK', a.gradeWork / 1e6);
        }
      }
    }
  }

  private isElectric(index: number): boolean {
    const iselectric =
      index >= 0 &&
      this.tableDataIsElectricColumn &&
      this.tableDataIsElectricColumn.length > index &&
      this.tableDataIsElectricColumn[index];
    return iselectric;
  }

  private getShowOption(md: MultipleDetail, option: string): boolean {
    let value: boolean = null;
    if (md && md.showOptions && md.showOptions[option]) {
      value = md.showOptions[option];
    }
    return value || false;
  }

  /*
  private isElectricCell(col: string, md?: MultipleDetail): boolean {
    if (this.getElectricColumnCount() <= 0 || !col.startsWith('DISPLAY') || !this.isElectric(Number(col.substring(7)).valueOf())) {
      return false;
    }
    return !md ? true : md.ATTRIBUTE === this.ts.instant('TRIP.VEHICLENAME');
  }
  */

  public getColClass(i: number): string {
    let cl = '';
    if (i % 2 !== 0) {
      cl = 'unevenCol';
    }
    return cl;
  }

  private createRows(): void {
    this.tableData = [];
    this.setRowMeta(
      'TRIP.VEHICLENAME',
      DetailType.string,
      ViewType.compare,
      null,
      null,
      {
        onElectric: true,
      }
    );
    this.setRowMeta('TRIP.GROUPNAME', DetailType.string, ViewType.compare);
    this.setRowMeta(
      'TRIP.STARTTIME',
      DetailType.date,
      ViewType.compare,
      null,
      'dd.MM.yyyy, HH:mm'
    );
    this.setRowMeta(
      'TRIP.DURATION',
      DetailType.period,
      ViewType.compare,
      null, // 'hh:mm:ss',
      null,
      {
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.DISTANCE',
      DetailType.number,
      ViewType.all,
      ['km'],
      '1.0-1',
      {
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.CO2EMISSION',
      DetailType.number,
      ViewType.all,
      ['kg'],
      '1.0-1',
      {
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.ENERGYCONSUMPTION',
      DetailType.number,
      ViewType.all,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.AVERAGESPEED',
      DetailType.number,
      ViewType.all,
      ['km/h'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.FUELCONSUMPTION',
      DetailType.number,
      ViewType.all,
      ['l/100km', 'kWh/100km'],
      '1.0-1',
      {
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'VEHICLE.FUELVALUE',
      DetailType.number,
      ViewType.compare,
      ['MJ/l'],
      '1.0-1'
    );
    this.setRowMeta(
      'TRIP.ECOINDEX1',
      DetailType.number,
      ViewType.all,
      ['l/100km', 'kWh/100km'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.ECOINDEX4',
      DetailType.number,
      ViewType.all,
      ['kWh/100km*t'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.ZEROFUELDISTANCE',
      DetailType.number,
      ViewType.compare,
      ['km'],
      '1.0-1',
      {
        percentFrom: { attributeName: 'DISTANCE' },
        average: true,
        total: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.STANDSTILLTIME',
      DetailType.period,
      ViewType.compare,
      null, // 'hh:mm:ss',
      null,
      {
        average: true,
        total: true,
        percentFrom: { attributeName: 'DURATION', formatString: '1.0-1' },
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEACCCYCLE',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEAEROCYCLE',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGESTSCYCLE',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEWORKCYCLE',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-1',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEWLTPLOW',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-0',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEWLTPMEDIUM',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-0',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEWLTPHIGH',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-0',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.PERCENTAGEWLTPEXTRAHIGH',
      DetailType.number,
      ViewType.all,
      ['%'],
      '1.0-0',
      {
        average: true,
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.ACCWORK',
      DetailType.number,
      ViewType.compare,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        percentFrom: {
          attributeName: 'ENERGYCONSUMPTION',
          formatString: '1.0-1',
        },
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.AEROWORK',
      DetailType.number,
      ViewType.compare,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        percentFrom: {
          attributeName: 'ENERGYCONSUMPTION',
          formatString: '1.0-1',
        },
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.STANDSTILLWORK',
      DetailType.number,
      ViewType.compare,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        percentFrom: {
          attributeName: 'ENERGYCONSUMPTION',
          formatString: '1.0-1',
        },
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.ROLLWORK',
      DetailType.number,
      ViewType.compare,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        percentFrom: {
          attributeName: 'ENERGYCONSUMPTION',
          formatString: '1.0-1',
        },
        onElectric: true,
      }
    );
    this.setRowMeta(
      'TRIP.GRADEWORK',
      DetailType.number,
      ViewType.compare,
      ['MJ'],
      '1.0-1',
      {
        average: true,
        total: true,
        percentFrom: {
          attributeName: 'ENERGYCONSUMPTION',
          formatString: '1.0-1',
        },
        onElectric: true,
      }
    );
  }
}
