<h1 mat-dialog-title>
    <span translate="SENSORSTATION.SENSORSTATION"> </span>
  </h1>
  <form [formGroup]="form">
         <app-sensorstation-form [groups]="groups" [sensorstation]="sensorstation" [allowEditing]="true"> </app-sensorstation-form>  
  </form>
  <div mat-dialog-actions>
    <button mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">
      {{ 'CREATE' | translate }}
    </button>
    <button mat-raised-button [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
  </div>
