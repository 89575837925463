<app-overlay-spinner-trips-action [computingTripResponses]="ctr" *ngIf="(tripsData.loading$ | async) || isNotConfirmed()"></app-overlay-spinner-trips-action>
<div class="filter">
  <div class="dropdown">

    <mat-form-field hideRequiredMarker="false" *ngIf="users !== undefined && users.length > 0">
      <mat-chip-list #chipList1>
        <mat-chip class="trip-list-mat-chip"
          *ngIf="selectedUser"
          (removed)="unselectUser()">
          {{selectedUser.userName}}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input *ngIf="!selectedUser"
          type="text"
          placeholder="{{ 'VEHICLE.USER' | translate }}"
          required
          matInput
          [formControl]="myControlUser"
          [matAutocomplete]="auto1"
          [matChipInputFor]="chipList1"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto1="matAutocomplete"
        (optionSelected)="selectUser($event.option.value)"
        [displayWith]="displayUser"
      >
        <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
          {{ user.userName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field hideRequiredMarker="false">
      <mat-chip-list #chipList2>
        <mat-chip class="trip-list-mat-chip"
          *ngIf="selectedVehicle"
          (removed)="unselectVehicle()">
          {{ selectedVehicle.name }}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input *ngIf="!selectedVehicle"
          type="text"
          placeholder="{{ 'VEHICLE.VEHICLE' | translate }}"
          required
          matInput
          [formControl]="myControlVehicle"
          [matAutocomplete]="auto2"
          [matChipInputFor]="chipList2"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto2="matAutocomplete"
        (optionSelected)="selectVehicle($event.option.value)"
        [displayWith]="displayVehicle"
      >
        <mat-option *ngFor="let vehicle of filteredVehicles | async" [value]="vehicle">
          {{ vehicle.name }}
          <span *ngIf="vehicle.originalVehicleId && vehicle.lastModified">
            {{ (vehicle.lastModified | date: 'dd.MM.yyyy, HH:mm') || 'n/a' }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field hideRequiredMarker="false" *ngIf="groups !== undefined && groups.length > 0">
      <mat-chip-list #chipList3>
        <mat-chip class="trip-list-mat-chip"
          *ngIf="selectedGroup"
          (removed)="unselectGroup()">
          {{ selectedGroup.groupName }}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input *ngIf="!selectedGroup"
          type="text"
          placeholder="{{ 'TRIP.GROUPNAME' | translate }}"
          required
          matInput
          [formControl]="myControlGroup"
          [matAutocomplete]="auto3"
          [matChipInputFor]="chipList3"
        />
      </mat-chip-list>
      <mat-autocomplete
        #auto3="matAutocomplete"
        (optionSelected)="selectGroup($event.option.value)"
        [displayWith]="displayGroup"
      >
        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
          <span>
            <span
              *ngFor="
                let dummy of ' '.repeat(group.groupIdentifier.split(GROUP_IDENTIFIER_SEPARATOR).length - 2).split('');
                let x = index
              "
            >
              - </span
            >{{ group.groupName }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'TRIP.FROM_TITLE' | translate }}</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="startPicker"
        (dateChange)="queryTrips()"
        [max]="maxDate"
        autocomplete="off"
        [formControl]="myControlStartDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!startDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #startPicker [showSeconds]="false"> </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'TRIP.TO_TITLE' | translate }}</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="endPicker"
        (dateChange)="queryTrips()"
        [max]="maxDate"
        autocomplete="off"
        [formControl]="myControlEndDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!endDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #endPicker [showSeconds]="false"></ngx-mat-datetime-picker>
    </mat-form-field>

  </div>
</div>
<div class="sort">
  <button
    *ngFor="let sb of sortButtons; let i = index"
    mat-raised-button
    class="trips-list-button"
    (click)="onChangeSort(i)"
  >
    <mat-icon *ngIf="sb.sortDirection">{{ sb.sortDirection }}</mat-icon>
    {{ sb.buttonText | translate }}
  </button>
</div>

<table
  mat-table
  matSort
  [dataSource]="tripsData"
  class="mat-elevation-z8"
  multiTemplateDataRows="true"
  (matSortChange)="sortChanged($event.active, $event.direction)"
  matSortActive="startTime"
  [matSortDirection]="tableSortDirection"
  matSortStart="desc"
>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef class="trips-select-check-box-row">
      <mat-checkbox #headercheckbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    *ngIf="authService.isDownloadEnabled()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" class="trips-select-check-box-row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? rowToggle(row) : null"
                    [checked]="selection.isSelected(row)"
                    *ngIf="authService.isDownloadEnabled()">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="groupName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'TRIP.GROUPNAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span *ngIf="row.groupName != null">{{ row.groupName }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'TRIP.DISTANCE' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span *ngIf="row.calculation != null && row.calculation.distance != null">{{
        row.calculation.distance | number: '1.1-3'
      }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'TRIP.STARTTIME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span *ngIf="row.startTime != null">{{ row.startTime | date: 'dd.MM.yyyy, HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="endTime">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'TRIP.ENDTIME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span *ngIf="row.endTime">{{ row.endTime | date: 'dd.MM.yyyy, HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="rank">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'TRIP.RANK' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span *ngIf="row.ranking != null && row.ranking.position != null && row.ranking.totalTrips"
        >{{ row.ranking.position }} / {{ row.ranking.totalTrips }}</span
      >
    </td>
  </ng-container>

  <ng-container matColumnDef="vehicleName">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'TRIP.VEHICLENAME' | translate }}
    </th>
    <td mat-cell *matCellDef="let row" (click)="showAnalysis(row)">
      <span [class]="getCellClass(row.vehicle)">
        {{ row.vehicle.name }}
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>

<mat-paginator
  *ngIf="tripsData.page$ | async as page"
  [length]="page.totalElements"
  [pageSizeOptions]="pageSizeOptions"
  [pageSize]="page.size"
  [pageIndex]="page.number"
  (page)="tripsData.fetch($event.pageIndex, $event.pageSize);resetPage($event.pageSize)"
>
</mat-paginator>

<mat-action-list>
  <button *ngIf="authService.isAdminOrDispatcher() && showCompare()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.compare)" [disabled]="this.disableCompare()">
    {{ 'TRIP.COMPARE' | translate }}
  </button>
  <button *ngIf="authService.isAdminOrDispatcher() && showComputeAverageValues()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.compare)" [disabled]="this.disableCompare()">
    {{ 'TRIP.AVERAGEVALUES' | translate }}
  </button>
  <button *ngIf="authService.isAdminOrDispatcher()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.delete)" [disabled]="this.disableActionButtons()">
    {{ 'DELETE' | translate }}
  </button>
  <button *ngIf="authService.isAdmin()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.recalculate)" [disabled]="this.disableActionButtons()">
    {{ 'TOOLS.NEW_CALC' | translate }}
  </button>
  <button *ngIf="authService.isDownloadEnabled()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.downloadKml)" [disabled]="this.disableActionButtons()">
    {{ 'TRIP.KMLDOWNLOAD' | translate }}
  </button>
  <button *ngIf="authService.isDownloadEnabled()" mat-raised-button color="primary" class="trips-action-button" (click)="executeAction(actionTypes.downloadCsv)" [disabled]="this.disableActionButtons()">
    {{ 'TRIP.CSVDOWNLOAD' | translate }}
  </button>
</mat-action-list>
