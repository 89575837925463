<nav mat-tab-nav-bar>
  <a mat-tab-link routerLink="groups" [active]="activeLink === '/admin/groups'">
    {{ 'GROUP.TITLE' | translate }}
  </a>
  <a mat-tab-link routerLink="users" [active]="activeLink === '/admin/users'">
    {{ 'USER.TITLE' | translate }}
  </a>
  <a mat-tab-link routerLink="models" [active]="activeLink === '/admin/models'">
    {{ 'VEHICLETYPE.TITLE' | translate }}
  </a>
  <a mat-tab-link routerLink="vehicles" [active]="activeLink === '/admin/vehicles'">
    {{ 'VEHICLE.TITLE' | translate }}
  </a>
</nav>
<router-outlet></router-outlet>
