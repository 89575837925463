<h1 mat-dialog-title><span translate="VEHICLE.VEHICLE"> </span> {{ form.value.id }}</h1>
<form [formGroup]="form" cdk-focus-start>
  <app-vehicle-form [groups]="groups" [users]="users" [vehicle]="vehicle" [allowEditing]="allowEditing"></app-vehicle-form>
</form>
<div mat-dialog-actions>
  <button *ngIf="allowEditing" mat-raised-button [disabled]="!isFuelValueOk() || !form.valid" (click)="onSubmit()">
    {{ 'UPDATE' | translate }}
  </button>
  <button mat-raised-button (click)="onClose()">
    {{ 'CLOSE' | translate }}
  </button>
</div>
<app-overlay-spinner *ngIf="loading"></app-overlay-spinner>
