import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChartPoint } from 'chart.js';
import { CalculatedPosition } from 'lcmm-lib-js';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { AbstractChartComponent } from '../abstract-chart/abstract-chart.component';

@Component({
  selector: 'app-trip-way-profile',
  templateUrl: '../abstract-chart/abstract-chart.component.html',
  styleUrls: ['../abstract-chart/abstract-chart.component.scss'],
})
export class TripWayProfileComponent extends AbstractChartComponent {
  constructor(
    translateService: TranslateService,
    dialog: MatDialog,
    ces: ChartEventService
  ) {
    super(translateService, dialog, ces);
    this.yLabel = 'TRIPS.WAY.YLABEL';
    this.chartType = 'linear';
  }

  // provides position way data per km aggregated into chunks of config.Distance m
  // eslint-disable-next-line class-methods-use-this
  public getData(positions: CalculatedPosition[]): ChartPoint[] {
    const chartPoints: ChartPoint[] = [];
    // total distance passed so far
    let distanceSum = 0;
    positions.forEach((p) => {
      if (p.distance) {
        distanceSum += p.distance;
      }
      chartPoints.push({
        x: distanceSum / 1000,
        y: p.standStillTime,
        t: new Date(p.dtime),
      });
    });
    return chartPoints;
  }
}
