import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

export type ErrorComponentCloseCallback = () => void;

export interface ErrorComponentData {
  title?: string;
  text?: string;
  buttonTitle?: string;
  doNotTranslateTitle?: boolean;
  doNotTranslateText?: boolean;
  doNotTranslateButtonTitle?: boolean;
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  public title = 'ERROR';

  public doNotTranslateTitle = false;

  public text = null;

  public doNotTranslateText = false;

  public buttonTitle = 'CONTINUE';

  public doNotTranslateButtonTitle = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data?: ErrorComponentData) {
    if (data) {
      if (data.title) this.title = data.title;
      if (data.doNotTranslateTitle)
        this.doNotTranslateTitle = data.doNotTranslateTitle;
      if (data.text) this.text = data.text;
      if (data.doNotTranslateText)
        this.doNotTranslateText = data.doNotTranslateText;
      if (data.buttonTitle) this.buttonTitle = data.buttonTitle;
      if (data.doNotTranslateButtonTitle)
        this.doNotTranslateButtonTitle = data.doNotTranslateButtonTitle;
    }
  }

  public static open(
    dialog: MatDialog,
    onClose?: ErrorComponentCloseCallback,
    errorData?: ErrorComponentData,
    modal?: boolean
  ): MatDialogRef<ErrorComponent> {
    const dialogRef = dialog.open(ErrorComponent, {
      data: errorData,
      disableClose: modal === undefined || modal === null || modal,
    });
    if (onClose) {
      dialogRef.afterClosed().subscribe(() => {
        onClose();
      });
    }
    return dialogRef;
  }
}
