import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Group, Trip, TripResponse } from 'lcmm-lib-js';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/service/auth.service';
import { Download } from 'src/app/service/download.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { TripService } from 'src/app/service/trip.service';
import { UserService } from 'src/app/service/user.service';
import { DateInputErrorStateMatcher } from 'src/app/utils/error-state-matcher';
import { sortString } from 'src/app/utils/utils';
import { TranslateService } from '@ngx-translate/core';
import { TripBatchResultsComponent } from './trip-batch-results/trip-batch-results.component';
import { TripAnalysisDialogComponent } from '../trips-list/trip-analysis-dialog/trip-analysis-dialog.component';

interface SearchCriteria {
  startTimeFrom?: Date;
  startTimeTo?: Date;
  groupIdentifier?: string;
}

@Component({
  selector: 'app-trips-tools',
  templateUrl: './trips-tools.component.html',
  styleUrls: ['./trips-tools.component.scss'],
})
export class TripsToolsComponent implements OnInit {
  public GROUP_IDENTIFIER_SEPARATOR: string;

  private searchCriteria: SearchCriteria = {
    startTimeFrom: null,
    startTimeTo: null,
    groupIdentifier: null,
  };

  public withPositions = false;

  public maxDate: Date = new Date();

  public groups: Group[];

  public formControlGroup = new UntypedFormControl();

  public myControlStartDate = new UntypedFormControl(null);

  public myControlEndDate = new UntypedFormControl(null);

  public myControlCsvStartDate = new UntypedFormControl(null);

  public myControlCsvEndDate = new UntypedFormControl(null);

  public filteredGroups: Observable<Group[]>;

  public selectedGroup?: Group;

  public downloadFile$: Observable<Download>;

  @Input() public userRole: string;

  public loading = false;

  public startDateValid = true;

  public endDateValid = true;

  public startCsvDateValid = true;

  public endCsvDateValid = true;

  public errorStateMatcher = new DateInputErrorStateMatcher();

  public errorStateMatcherCsv = new DateInputErrorStateMatcher();

  public selectedGroupShowTrip?: Group;

  public tripIdShowTrip: string;

  public tripIdShowTripErrorMsg: string;

  constructor(
    private tripService: TripService,
    public dialog: MatDialog,
    private userService: UserService,
    public envService: EnvConfigurationService,
    public authService: AuthService,
    private translate: TranslateService
  ) {
    this.tripIdShowTrip = null;
    this.tripIdShowTripErrorMsg = null;
    this.GROUP_IDENTIFIER_SEPARATOR =
      envService.config.groupIdentifierSeparator;
  }

  ngOnInit(): void {
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups.sort(sortString('groupIdentifier'));
        this.filteredGroups = this.formControlGroup.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });

    this.myControlStartDate.statusChanges.subscribe((status) => {
      this.startDateValid = status === 'VALID';
    });

    this.myControlEndDate.statusChanges.subscribe((status) => {
      this.endDateValid = status === 'VALID';
    });

    this.myControlCsvStartDate.statusChanges.subscribe((status) => {
      this.startCsvDateValid = status === 'VALID';
    });

    this.myControlCsvEndDate.statusChanges.subscribe((status) => {
      this.endCsvDateValid = status === 'VALID';
    });
  }

  public recalcSubmit(): void {
    const criteria = { ...this.searchCriteria };
    this.tripService
      .recalculateTrips(
        criteria.startTimeFrom,
        criteria.startTimeTo,
        criteria.groupIdentifier
      )
      .subscribe((recalTrips: TripResponse[]) => {
        // Open new window with results
        this.dialog.open(TripBatchResultsComponent, {
          width: '80%',
          data: { tripResults: recalTrips },
        });
      });
  }

  public isRecalcSubmitDisabled(): boolean {
    return (
      this.searchCriteria.startTimeFrom === null ||
      this.searchCriteria.startTimeTo === null
    );
  }

  public clearEmptySubmit(): void {
    const dialogRefConfirmation = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '80%',
        data: 'DELETE',
      }
    );

    dialogRefConfirmation.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.tripService
          .deleteEmptyTrips(this.withPositions)
          .subscribe((deletedTrips: TripResponse[]) => {
            // Open new window with results
            this.dialog.open(TripBatchResultsComponent, {
              width: '80%',
              data: { tripResults: deletedTrips },
            });
          });
      }
    });
  }

  public clearTripIdShowTrip(): void {
    this.tripIdShowTrip = null;
    this.tripIdShowTripErrorMsg = null;
  }

  public clearTripIdShowTripErrorMsg(): void {
    this.tripIdShowTripErrorMsg = null;
  }

  private tripResponseByTrip(trip: Trip): TripResponse {
    const tr: TripResponse = trip as unknown as TripResponse;
    tr.startTime = new Date(tr.startTime);
    tr.endTime = new Date(tr.endTime);
    return tr;
  }

  public disableShowTripButton(): boolean {
    return (
      this.tripIdShowTripErrorMsg !== null ||
      !this.selectedGroupShowTrip ||
      !this.tripIdShowTrip ||
      this.tripIdShowTrip.length < 20
    );
  }

  public showTripDetailsByTripId(): void {
    this.tripService
      .getDetailedTrip(
        this.selectedGroupShowTrip.groupIdentifier,
        this.tripIdShowTrip,
        null
      )
      .subscribe(
        (trip) => {
          const tripResponse: TripResponse = trip;
          this.dialog.open(TripAnalysisDialogComponent, {
            width: '80%',
            data: {
              tripResponse,
              isSectionDialog: false,
              tripSectionParameter: null,
            },
            disableClose: false,
          });
        },
        () => {
          this.tripIdShowTripErrorMsg = 'TOOLS.TRIP_ID_UNKNOWN';
        }
      );
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    if (group !== null) {
      this.searchCriteria.groupIdentifier = group.groupIdentifier;
    } else {
      this.searchCriteria.groupIdentifier = null;
    }
  }

  public selectGroupShowTrip(group: Group): void {
    this.selectedGroupShowTrip = group;
    if (group !== null) {
      this.searchCriteria.groupIdentifier = group.groupIdentifier;
    } else {
      this.searchCriteria.groupIdentifier = null;
    }
  }

  public isGroupSelected(): boolean {
    if (this.selectedGroup) {
      return true;
    }
    return false;
  }

  public isGroupShowTripSelected(): boolean {
    if (this.selectedGroupShowTrip) {
      return true;
    }
    return false;
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public intervalChange(interval: SearchCriteria): void {
    if (interval.startTimeFrom) {
      this.searchCriteria.startTimeFrom = interval.startTimeFrom;
    }
    if (interval.startTimeTo) {
      this.searchCriteria.startTimeTo = interval.startTimeTo;
    }
  }

  private filterGroups(groupName: string): Group[] {
    if (typeof groupName === 'string') {
      const filterValue = groupName.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }
}
