import { Component, ViewChild } from '@angular/core';
import { TripResponse, TripEvaluation } from 'lcmm-lib-js';
import { map, switchMap, take } from 'rxjs/operators';
import { TripService } from 'src/app/service/trip.service';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import { TripAnalysisComponent } from '../trips-list/trip-analysis/trip-analysis.component';

enum StarType {
  ROUTE,
  TRAFFIC,
  DRIVE,
}

@Component({
  selector: 'app-trips-evaluation',
  templateUrl: './trips-evaluation.component.html',
  styleUrls: ['./trips-evaluation.component.scss'],
})
export class TripsEvaluationComponent {
  public StarType = StarType;

  public tripResponse: TripResponse;

  public routeStars: number;

  public trafficStars: number;

  public drivingStars: number;

  public validTrip = true;

  @ViewChild(TripAnalysisComponent)
  private tripAnalysisComponent: TripAnalysisComponent;

  constructor(
    private tripService: TripService,
    private ces: ChartEventService
  ) {
    this.nextRandomTrip();
  }

  public nextRandomTrip() {
    this.resetEvaluation();
    this.tripService.getRandomTrip().subscribe((tripResponse) => {
      this.tripResponse = tripResponse;
      this.ces.emit(CallbackEventType.newData, tripResponse);
    });
  }

  public changeStars(type: StarType, stars: number) {
    // no need to change stars for an invalid trip
    if (this.validTrip) {
      switch (type) {
        case StarType.ROUTE:
          this.routeStars = stars;
          break;
        case StarType.TRAFFIC:
          this.trafficStars = stars;
          break;
        case StarType.DRIVE:
          this.drivingStars = stars;
          break;
        default:
          break;
      }
    }
  }

  public submitEvaluation() {
    this.tripAnalysisComponent.tripObservable
      .pipe(
        take(1),
        map((t) => {
          let tripEvaluation: TripEvaluation;
          if (this.validTrip) {
            tripEvaluation = {
              ...t,
              id: this.tripResponse.id,
              startTime: this.tripResponse.startTime,
              endTime: this.tripResponse.endTime,
              vehicle: this.tripResponse.vehicle,
              routeStars: this.routeStars,
              traficStars: this.trafficStars,
              drivingStars: this.drivingStars,
              validTrip: this.validTrip,
            };
          } else {
            tripEvaluation = {
              ...t,
              id: this.tripResponse.id,
              startTime: this.tripResponse.startTime,
              endTime: this.tripResponse.endTime,
              vehicle: this.tripResponse.vehicle,
              validTrip: this.validTrip,
            };
          }
          return tripEvaluation;
        }),
        switchMap((tripEval) => this.tripService.submitEvaluation(tripEval))
      )
      .subscribe(() => {
        this.nextRandomTrip();
      });
  }

  public evaluationInvalid(): boolean {
    return !(
      !this.validTrip ||
      (this.routeStars > 0 && this.trafficStars > 0 && this.drivingStars > 0)
    );
  }

  private resetEvaluation() {
    this.tripResponse = null;
    this.routeStars = 0;
    this.trafficStars = 0;
    this.drivingStars = 0;
    this.validTrip = true;
    this.ces.emit(CallbackEventType.cleared);
  }
}
