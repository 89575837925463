import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, Group, Vehicle, VehicleType, RoleConstants } from 'lcmm-lib-js';

import { VehicleService } from '../../../service/vehicle.service';
import { VehicleFormComponent } from '../vehicle-form/vehicle-form.component';

@Component({
  selector: 'app-vehicle-create-dialog',
  templateUrl: './vehicle-create-dialog.component.html',
  styleUrls: ['./vehicle-create-dialog.component.scss'],
})
export class VehicleCreateDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  public selectedVehicleType: VehicleType;

  public groups: Group[];

  public vehicleType: VehicleType = {
    brand: '',
    series: '',
    model: '',
    hsn: '',
    tsn: '',
    airDragCoefficient: 0,
    crossSectionArea: 0,
    efficiency: 0,
    fuelEmissionFactor: 0,
    fuelValue: null,
    mass: 0,
    rollFrictionCoefficient: 0,
    standStillFuelConsumption: 0,
    startStop: false,
    airConditioning: false,
    motorHeating: false,
    wltpClass: 3,
    groupName: '',
  };

  public vehicle: Vehicle;

  public users: User[];

  public vehicleTypeList: VehicleType[];

  public loading = false;

  @ViewChild(VehicleFormComponent)
  public vehicleFormComponent: VehicleFormComponent;

  constructor(
    public dialogRef: MatDialogRef<VehicleCreateDialogComponent>,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      vehicleTypeList: VehicleType[];
      users: User[];
      groups: Group[];
    }
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;
    if (data.vehicleTypeList) {
      this.vehicleTypeList = data.vehicleTypeList;
    }

    if (data.users) {
      this.users = data.users.filter(
        (user) => user.userRole === RoleConstants.DRIVER
      );
    }

    if (data.groups) {
      this.groups = data.groups;

      this.vehicle = {
        name: '',
        _name: '',
        user: [],
        disabled: false,
        groupName:
          this.groups.length === 1 ? this.groups[0].groupIdentifier : '',
        ...this.vehicleType,
      };
    }
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
  }

  private _getVehicle(): Vehicle {
    const vehicle: Vehicle = {
      ...this.form.value.vehicleForm,
      id: null,
      groupName: this.form.value.vehicleForm.groupName.groupIdentifier,
    };
    vehicle.user = [];
    if (
      this.form.value.vehicleForm.user !== undefined &&
      this.form.value.vehicleForm.user !== null
    ) {
      this.form.value.vehicleForm.user.forEach((u: User) => {
        if (u) {
          vehicle.user.push(u.id);
        }
      });
    }
    return vehicle;
  }

  public onSubmit(): void {
    const vehicle: Vehicle = this._getVehicle();
    this.loading = true;
    this.vehicleService.createVehicle(vehicle).subscribe(
      (createdVehicle) => {
        this.loading = false;
        this.dialogRef.close(createdVehicle);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err) => {
        this.loading = false;
      }
    );
  }

  public loadVehicleType(): void {
    this.vehicleFormComponent.updateType(this.selectedVehicleType);
    Object.assign(this.vehicle, this.selectedVehicleType);
  }
}
