<mat-dialog-content>
  <div>
    <h2 mat-dialog-title>{{ titleKey | translate }}</h2>
    <div>
      <table class="mat-elevation-z8" mat-table [dataSource]="tableData">
        <ng-container sticky [matColumnDef]="col" *ngFor="let col of displayedColumns; let i = index">
          <th mat-header-cell *matHeaderCellDef [class]="getColClass(i)" [innerHTML]="getHeader(col)"></th>
          <td mat-cell *matCellDef="let row" [innerHTML]="row[col]" [class]="getColClass(i)"></td> <!--row)"></td>-->
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let triprow; columns: displayedColumns" [class.hidden]="triprow.hiddenDetail"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
