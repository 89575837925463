<app-header></app-header>

<mat-card class="mat-card">
  <mat-card-title translate="IMPRINT.TITLE"></mat-card-title>
</mat-card>

<div class="wrapper">
  <ng-template ngFor let-ITEM [ngForOf]="'IMPRINT.ADDRESSES' | translate">
    <a class="adress">
      <mat-card class="mat-card">
        <mat-card-title translate>{{ ITEM.TITLE }} </mat-card-title>
        <mat-card-content innerHTML="{{ ITEM.CONTENT }}"></mat-card-content>
      </mat-card>
    </a>
  </ng-template>
</div>

<app-footer></app-footer>
