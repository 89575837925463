<mat-selection-list #test [multiple]="false">
    <mat-list-option [selected]="false">
        <div class="list">
            <mat-icon class="icon">person</mat-icon>
            <span class="text">{{ 'USER.USERNAME' | translate }}: {{ authService.getUsername() }}</span>
        </div>
    </mat-list-option>
    <mat-list-option>
        <div class="list">
            <mat-icon class="icon">edit</mat-icon>
            <a href="{{ envService.config.keycloakAccountUrl }}" target="_blank" class="links">{{ 'EDIT_ACCOUNT' | translate }}</a>
        </div>
    </mat-list-option>
    <mat-list-option (click)="authService.logout()">
        <div class="list">
            <mat-icon class="icon">power_settings_new</mat-icon>
            <span class="links">{{ 'LOGOUT' | translate }}</span>
        </div>
    </mat-list-option>
</mat-selection-list>
