<app-overlay-spinner *ngIf="loading"></app-overlay-spinner>

<mat-card class="card" *ngIf="authService.isAdminOrDispatcher()">
  
  <mat-card-header>
    <mat-card-title>{{ 'TOOLS.OVERVIEW_CSV' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-card-actions>

    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        [ngxMatDatetimePicker]="startPicker"
        [formControl]="fcStartDate"
        placeholder="{{ 'TRIP.FROM_TITLE' | translate }}"
        (dateChange)="updateMapByParameterChange()"
        [max]="maxDate"
        autocomplete="off"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!startDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #startPicker [showSeconds]="false"> </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        [ngxMatDatetimePicker]="endPicker"
        [formControl]="fcEndDate"
        placeholder="{{ 'TRIP.TO_TITLE' | translate }}"
        (dateChange)="updateMapByParameterChange()"
        [min]="minDate"
        [max]="maxDate"
        autocomplete="off"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!endDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #endPicker [showSeconds]="false"></ngx-mat-datetime-picker>
    </mat-form-field>
    
    <mat-form-field hideRequiredMarker="false">
      <mat-chip-list #chipListMap>
        <mat-chip class="trips-tools-mat-chip"
          *ngIf="selectedGroup"
          [disabled]="isInputDisabled"
          (removed)="selectGroup(null)">
          {{selectedGroup.groupName}}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input *ngIf="!selectedGroup"
          matInput
          [disabled]="isInputDisabled"
          type="text"
          placeholder="{{ 'TRIP.GROUPNAME' | translate }} *"
          required
          [formControl]="fcGroup"
          [matAutocomplete]="autoGroupMap"
          [matChipInputFor]="chipListMap"
        />
      </mat-chip-list>
      <mat-autocomplete
        #autoGroupMap="matAutocomplete"
        (optionSelected)="selectGroup($event.option.value)"
        [displayWith]="displayGroup"
      >
        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
          <span>
            <span
              *ngFor="
                let dummy of ' '.repeat(group.groupIdentifier.split(GROUP_IDENTIFIER_SEPARATOR).length - 2).split('');
                let x = index
              "
            >
              - </span
            >{{ group.groupName }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="download()" [disabled]="disableDownloadButton()">
      {{ 'DOWNLOAD' | translate }}
    </button> 

    <button mat-raised-button color="primary" (click)="compare()" [disabled]="disableCompareButton()">
      {{ 'TRIP.COMPARE' | translate }}
    </button> 

    <br>

    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        type="number"
        [(ngModel)]="sectionNELat"
        placeholder="{{ 'TRIPS.SECTION_NE_LAT' | translate }}"
        (change)="updateMapSection()"
        autocomplete="off"
      />
    </mat-form-field>
    
    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        type="number"
        [(ngModel)]="sectionNELng"
        placeholder="{{ 'TRIPS.SECTION_NE_LNG' | translate }}"
        (change)="updateMapSection()"
        autocomplete="off"
      />
    </mat-form-field>
    
    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        type="number"
        [(ngModel)]="sectionSWLat"
        placeholder="{{ 'TRIPS.SECTION_SW_LAT' | translate }}"
        (change)="updateMapSection()"
        autocomplete="off"
      />
    </mat-form-field>
    
    <mat-form-field>
      <input
        matInput
        [disabled]="isInputDisabled"
        type="number"
        [(ngModel)]="sectionSWLng"
        placeholder="{{ 'TRIPS.SECTION_SW_LNG' | translate }}"
        (change)="updateMapSection()"
        autocomplete="off"
      />
    </mat-form-field>

    <mat-checkbox *ngIf="areAllCoordinatesSetCorrectly()" [(ngModel)]="cutPositions" [disabled]="isInputDisabled">{{ 'TRIP.REDUCEPOSITIONS' | translate }}</mat-checkbox>
    <output class="errorMsg" *ngIf="areCoordinatesSetWrong()">{{ 'TRIP.COORDINATESWRONG' | translate }}</output>
  
  </mat-card-actions>  

</mat-card>

<div class="mapAndHideDiv">
  <div #googleGlobalMap class="myglobalmap"></div>
  <div id="disableMapDiv" class="myglobalmap disableMap"></div>
</div>

<br>

<div id="map-legend">
  <img alt="" src="assets/marker/marker_running.png">running
  <img alt="" src="assets/marker/marker_sts_5.png">STS&lt;5min
  <img alt="" src="assets/marker/marker_sts_30.png">STS&lt;30min 
  <img alt="" src="assets/marker/marker_sts_60.png">STS&lt;1h
  <img alt="" src="assets/marker/marker_sts.png">STS&gt;1h
  <img alt="" src="assets/marker/marker_finished.png">finished&lt;8h
  <img alt="" src="assets/marker/marker_finished_8h.png">finished&gt;8h
</div>

