import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { timeoutWorker } from 'timeout-worker';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent {
  // countdown Timer

  public display: string;

  constructor(
    public dialogRef: MatDialogRef<TimeoutComponent>,
    public dialog: MatDialog,
    private authService: AuthService
  ) {
    this.timer(1);

    // 60 seconde timeout in dialog
    timeoutWorker.start();
    timeoutWorker.setTimeout(() => {
      this.logout();
    }, 60000);
  }
  // countdown Timer

  public timer(minute: number): void {
    let seconds: number = minute * 60;
    let textSec = '0';
    let statSec = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds -= 1;
      if (statSec !== 0) statSec -= 1;
      else statSec = 59;

      if (statSec < 10) {
        textSec = `0${statSec}`;
      } else {
        textSec = statSec.toString();
      }

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  public continue(): void {
    this.dialogRef.close();
    timeoutWorker.stop();
  }

  public logout(): void {
    this.authService.logout();
  }
}
