<h1 mat-dialog-title>
  <span translate="GROUP.GROUP"> </span>
</h1>
<form [formGroup]="form">
  <div class="form">
    <mat-form-field>
      <mat-label mat-label translate="GROUP.PARENTGROUP"></mat-label>
      <mat-select [(value)]="selectedGroup">
        <mat-option [value]="null">{{ 'GROUP.NONE' | translate }} </mat-option>
        <mat-option *ngFor="let selectedGroup of groups" [value]="selectedGroup">
          <span>
          <span
            *ngFor="
              let dummy of ' '.repeat(selectedGroup.groupIdentifier.split(envService.config.groupIdentifierSeparator).length - 2).split('');
              let x = index
            "
          >
            - </span>{{ selectedGroup.groupName }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-divider></mat-divider>
  <mat-form-field>
    <input placeholder="{{ 'GROUP.NAME' | translate }}" required type="text" matInput formControlName="groupName" />
  </mat-form-field>
  <div *ngIf="form.controls.groupName.invalid && (form.controls.groupName.dirty || form.controls.groupName.touched)"
  class="alert">
  {{ 'GROUP.INVALID_INPUT' | translate }}

</div>
</form>
<div mat-dialog-actions>
  <button mat-raised-button [disabled]="form.invalid" (click)="onSubmit()">
    {{ 'CREATE' | translate }}
  </button>
  <button mat-raised-button [mat-dialog-close]>
    {{ 'CLOSE' | translate }}
  </button>
</div>
