import { DataSource } from '@angular/cdk/collections';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap, startWith, share, map, tap } from 'rxjs/operators';
import { Page, Sort, PaginatedEndpoint } from './page';
import { indicate } from './operators';

interface SimpleDataSource<T> extends DataSource<T> {
  connect(): Observable<T[]>;
  disconnect(): void;
}

export class LcmmDataSource<T, Q> implements SimpleDataSource<T> {
  private pageNumber = new Subject<number>();

  private sort: BehaviorSubject<Sort>;

  private query: BehaviorSubject<Q>;

  private loading = new Subject<boolean>();

  private requestContinuation: { [key: number]: string } = {};

  public loading$ = this.loading.asObservable();

  public page$: Observable<Page<T>>;

  private lastFetchPage: number;

  private lastFetchPageSize: number;

  constructor(
    private endpoint: PaginatedEndpoint<T, Q>,
    initialSort: Sort,
    initialQuery: Q,
    public pageSize = 10
  ) {
    this.lastFetchPageSize = 0;
    this.lastFetchPageSize = pageSize;
    this.query = new BehaviorSubject<Q>(initialQuery);
    this.sort = new BehaviorSubject<Sort>(initialSort);
    const param$ = combineLatest([this.query, this.sort]);
    this.page$ = param$.pipe(
      // Reset hash map with continuation token when query or sort has changed
      tap(() => {
        this.requestContinuation = {};
      }),
      switchMap(([query, sort]) =>
        this.pageNumber.pipe(
          startWith(0),
          switchMap((page) =>
            this.endpoint(
              {
                page,
                size: this.pageSize,
                sort,
                requestContinuation: this.requestContinuation[page],
              },
              query
            ).pipe(
              indicate(this.loading),
              tap((data) => {
                this.requestContinuation[data.number + 1] =
                  data.continuationNextPage;
              })
            )
          )
        )
      ),
      share()
    );
  }

  public sortBy(sort: Partial<Sort>): void {
    const lastSort = this.sort.getValue();
    const nextSort = { ...lastSort, ...sort };
    this.sort.next(nextSort);
  }

  public queryBy(query: Partial<Q>): void {
    const lastQuery = this.query.getValue();
    const nextQuery = { ...lastQuery, ...query };
    this.query.next(nextQuery);
  }

  public fetch(page?: number, pageSize?: number): void {
    if (page !== undefined) {
      this.lastFetchPage = page;
    }
    if (pageSize !== undefined) {
      this.lastFetchPageSize = pageSize;
    }
    this.pageSize = this.lastFetchPageSize;
    this.pageNumber.next(this.lastFetchPage);
  }

  public connect(): Observable<T[]> {
    return this.page$.pipe(map((page) => page.content));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public disconnect(): void {}

  public setLoading(loading: boolean): void {
    this.loading.next(loading);
  }
}
