import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Vehicle, User, Group, VehicleType } from 'lcmm-lib-js';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { VehicleService } from 'src/app/service/vehicle.service';
import { enumSelector, FuelType, getControlValue } from '../../../utils/utils';

@Component({
  selector: 'app-vehicle-form',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
  viewProviders: [
    // See https://stackoverflow.com/questions/53588169/how-to-use-formgroupname-inside-child-components
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class VehicleFormComponent implements OnInit, AfterViewInit {
  public form: UntypedFormGroup;

  public group: Group;

  public user: User[];

  @Input() public groups: Group[];

  @Input() public users: User[];

  @Input() public vehicle: Vehicle;

  @Input() public allowEditing: boolean;

  @ViewChild('userSelect') private userSelectRef: MatSelect;

  private myVehicle: Vehicle;

  public selectableFuelTypes = enumSelector(FuelType);

  constructor(
    private ctrlContainer: FormGroupDirective, // Parentform
    private fb: UntypedFormBuilder,
    public envService: EnvConfigurationService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.myVehicle = VehicleService.checkOptionalVehicleAttributes({
      ...this.vehicle,
    });
    const vehicleForm = this._createVehicleFormGroup();
    this.form = this.ctrlContainer.form;
    this.form.addControl('vehicleForm', vehicleForm);
    this._fillUser();
    this.group = this.groups.find(
      (element) => element.groupIdentifier === this.myVehicle.groupName
    );
    if (
      this.group === undefined &&
      this.groups !== undefined &&
      this.groups.length === 1
    ) {
      // eslint-disable-next-line prefer-destructuring
      this.group = this.groups[0];
    }
    this.form.controls.vehicleForm.patchValue({
      groupName: this.group,
    });

    if (!this.allowEditing) {
      this.form.disable();
    }
  }

  private _checkForAllUsersSelection(): void {
    if (
      this.myVehicle &&
      this.myVehicle.user &&
      this.myVehicle.user.length >= this.getUsersForGroup().length
    ) {
      this._setAllUsers(true);
    }
  }

  ngAfterViewInit(): void {
    this._checkForAllUsersSelection();
  }

  private _fillUser(): void {
    this.user = [];
    if (this.myVehicle.user) {
      this.users.forEach((u) => {
        if (this.myVehicle.user.includes(u.id)) {
          this.user.push(u);
        }
      });
    }
    this.form.controls.vehicleForm.patchValue({
      user: this.user,
    });
  }

  private _createVehicleFormGroup(): UntypedFormGroup {
    const vehicleUsers: string[] = [];
    if (this.myVehicle && this.myVehicle.user) {
      this.myVehicle.user.forEach((u) => {
        vehicleUsers.push(u);
      });
    }
    // eslint-disable-next-line no-param-reassign
    this.myVehicle.user = [];
    const vehicleForm = this.fb.group(this.myVehicle);
    vehicleUsers.forEach((element) => {
      this.myVehicle.user.push(element);
    });
    vehicleForm.patchValue({ user: this.myVehicle.user });
    if (this.myVehicle.id) {
      vehicleForm.get('groupName').disable();
    }
    return vehicleForm;
  }

  private _setUser(user: MatOption, value: boolean): void {
    if (user) {
      if (value) {
        user.select();
      } else {
        user.deselect();
      }
    }
  }

  private _setItemAll(value: boolean): void {
    this._setUser(this.userSelectRef.options.first, value);
  }

  private _setAllUsers(value: boolean): void {
    this.userSelectRef.options.forEach((user: MatOption) => {
      this._setUser(user, value);
    });
  }

  private _getSeletedUsersCount(): number {
    let usersSelected = 0;
    this.userSelectRef.options.forEach((user: MatOption) => {
      if (user.value && user.selected) {
        usersSelected += 1;
      }
    });
    return usersSelected;
  }

  public toggleAllUsersStatus(value: boolean): void {
    this._setAllUsers(value);
  }

  public checkItemAllStatus(): void {
    this._setItemAll(
      this._getSeletedUsersCount() >= this.getUsersForGroup().length
    );
  }

  public updateType(vehicleType: VehicleType): void {
    const vt = { ...vehicleType };
    delete vt.groupName;
    this.form.patchValue({
      vehicleForm: vt,
    });
  }

  public groupChanged(groupIdentifier: string): void {
    this.myVehicle.groupName = groupIdentifier;
    this.group = this.groups.find(
      (element) => element.groupIdentifier === this.myVehicle.groupName
    );
    this._setItemAll(false);
    this.myVehicle.user = [];
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public getUsersForGroup(): User[] {
    const groupUsers: User[] = [];
    if (this.group !== undefined && this.group !== null) {
      this.users.forEach((user) => {
        if (user.userGroupId === this.group.id) {
          groupUsers.push(user);
        }
      });
    }
    return groupUsers;
  }

  public standStillFuelConsumptionLabel(): string {
    const pre = this.ts.instant('VEHICLE.STANDSTILLFUELCONSUMPTION');
    const value = getControlValue(
      this.form.value,
      'vehicleForm.fuelValue'
    ) as number;
    if (value === FuelType.ELECTRIC) {
      return `${pre} kW/h`;
    }
    return `${pre} l/h`;
  }
}
