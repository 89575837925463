<h1 mat-dialog-title>{{ 'DOWNLOAD_MSG' | translate }}</h1>
<div mat-dialog-actions class="buttons">
  <button mat-raised-button [disabled]="isLoadingCsv()" (click)="downloadOverviewCsv()" color="primary" class="continue single-button">
    {{ 'TOOLS.OVERVIEW_CSV_DOWNLOAD' | translate }} 
    <mat-icon *ngIf="isLoadingCsv()"><mat-spinner color="'primary'" diameter=20 [value]="getLoadingProgressCsv()"></mat-spinner></mat-icon>
    <mat-icon *ngIf="!isLoadingCsv()">{{ this.getLoadingIconCsv() }}</mat-icon>
</button>
<button mat-raised-button [disabled]="isLoadingZip()" (click)="downloadTripsZip()" color="primary" class="continue" class="single-button">
    {{ 'TOOLS.ALL_ZIP_DOWNLOAD' | translate }} 
    <mat-icon *ngIf="isLoadingZip()"><mat-spinner color="'primary'" diameter=20 [value]="getLoadingProgressZip()"></mat-spinner></mat-icon>
    <mat-icon *ngIf="!isLoadingZip()">{{ this.getLoadingIconZip() }}</mat-icon>
</button>
<button mat-raised-button (click)="abort()" color="primary" class="continue" class="single-button">
    {{ 'CLOSE' | translate }}
</button>
</div>