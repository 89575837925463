import { Component } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sensorstations',
  templateUrl: './sensorstations.component.html',
  styleUrls: ['./sensorstations.component.scss'],
})
export class SensorstationsComponent {
  constructor(public authService: AuthService) {}
}
