import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, empty, Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { Sensorstation } from 'sensorstation-lib-js';
import { Page, PageRequest } from 'src/app/datasource/page';
import { CustomHttpParamEncoder } from 'src/app/utils/custom-http-param-encoder';
import { catchError, map } from 'rxjs/operators';

export interface SensorstationQuery {
  deviceId: string;
  aFESerialNumber: string;
  name: string;
  group: string;
  street: string;
  city: string;
  state: string;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  params: {
    size: '999999',
    // size: '100',
  },
};

@Injectable({
  providedIn: 'root',
})
export class SensorstationService {
  private URL: string;

  private _sensorstations = new BehaviorSubject<Sensorstation[]>(null);

  constructor(
    public authService: AuthService,
    public envService: EnvConfigurationService,
    private http: HttpClient
  ) {
    this.URL = envService.config.sensorstationManagementUrl;
    this.getAllSensorstations().subscribe((sensorstations) => {
      this._sensorstations.next(sensorstations);
    });
  }

  public readonly sensorstations = this._sensorstations.asObservable();

  public getAllSensorstations(): Observable<Sensorstation[]> {
    return this.http.get<Sensorstation[]>(
      `${this.URL}/sensorstations`,
      httpOptions
    );
  }

  public createSensorstation(
    sensorstation: Sensorstation
  ): Observable<Sensorstation> {
    return new Observable((observer) => {
      this.http
        .post<Sensorstation>(
          `${this.URL}/sensorstations`,
          sensorstation,
          httpOptions
        )
        .subscribe(
          (createdSensorstation: Sensorstation) => {
            this._sensorstations.next([
              ...this._sensorstations.getValue(),
              createdSensorstation,
            ]);
            observer.next(createdSensorstation);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public updateSensorstation(
    sensorstation: Sensorstation
  ): Observable<Sensorstation> {
    return new Observable((observer) => {
      this.http
        .put<Sensorstation>(
          `${this.URL}/sensorstations/${sensorstation.group}/${sensorstation.deviceId}`,
          sensorstation,
          httpOptions
        )
        .subscribe(
          (editedSensorstation) => {
            const sensorstations = this._sensorstations
              .getValue()
              .map((element) =>
                element.deviceId === editedSensorstation.deviceId
                  ? editedSensorstation
                  : element
              );
            this._sensorstations.next(sensorstations);
            observer.next(editedSensorstation);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public deleteSensorstation(
    sensorstation: Sensorstation
  ): Observable<Sensorstation> {
    return new Observable((observer) => {
      this.http
        .delete<Sensorstation>(
          `${this.URL}/sensorstations/${sensorstation.group}/${sensorstation.deviceId}`,
          httpOptions
        )
        .subscribe(
          () => {
            this._sensorstations.next(
              this._sensorstations
                .getValue()
                .filter(
                  (element) => element.deviceId !== sensorstation.deviceId
                )
            );
            observer.next();
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public page(
    request: PageRequest,
    query: SensorstationQuery
  ): Observable<Page<Sensorstation>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (request.size) params = params.append('size', request.size.toString());
    if (request.requestContinuation)
      params = params.append(
        'requestContinuation',
        request.requestContinuation
      );

    let sortText: string;
    if (request.sort.property && request.sort.order) {
      sortText = request.sort.property;
      if (sortText === 'group') sortText = 'groupName';
      // Add order, if available
      if (request.sort.order) sortText = `${sortText},${request.sort.order}`;
      params = params.append('sort', sortText);
    }

    if (query.deviceId) params = params.append('deviceId', query.deviceId);
    if (query.group) params = params.append('group', query.group);
    if (query.aFESerialNumber)
      params = params.append('aFESerialNumber', query.aFESerialNumber);
    if (query.name) params = params.append('name', query.name);
    if (query.city) params = params.append('city', query.city);
    if (query.street) params = params.append('street', query.street);
    if (query.state) params = params.append('state', query.state);

    const options = {
      headers,
      params,
      observe: 'response' as 'body',
    };

    return this.http
      .get<HttpResponse<Sensorstation[]>>(`${this.URL}/sensorstations`, options)
      .pipe(
        map((data: HttpResponse<Sensorstation[]>) => {
          return <Page<Sensorstation>>{
            content: data.body,
            number: request.page, // Aktuelle Seitenzahl
            size: request.size, // Anzahl angezeigter Einträge
            totalElements: Number(data.headers.get('X-Total-Count')),
            continuationNextPage: data.headers.get('x-continuation-token'),
          };
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
    } else {
      // The backend returned an unsuccessful response code.
    }
    // Return an observable with a user-facing error message.
    // return throwError('Something bad happened; please try again later.');
    return empty();
  }
}
