import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';
import { LcmmDataSource } from 'src/app/datasource/lcmm-data-source';
import { Sort } from 'src/app/datasource/page';
import { Sensorstation } from 'sensorstation-lib-js';
import { Group } from 'lcmm-lib-js';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import {
  ConfirmationDialogAction,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import {
  SensorstationQuery,
  SensorstationService,
} from '../service/sensorstation.service';
import { SensorstationCreateDialogComponent } from '../sensorstation-create-dialog/sensorstation-create-dialog.component';
import { SensorstationDialogComponent } from '../sensorstation-dialog/sensorstation-dialog.component';

@Component({
  selector: 'app-sensorstations-list',
  templateUrl: './sensorstations-list.component.html',
  styleUrls: ['./sensorstations-list.component.scss'],
})
export class SensorstationsListComponent {
  public displayedColumns: string[] = [
    'sensorstationName',
    'sensorstationId',
    'aFESerialNumber',
    'sensorstationGroup',
    'streetName',
    'cityName',
    'stateName',
    'details',
  ];

  public groups: Group[] = [];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = '';

  public selectedGroup: Group;

  public initialSort: Sort = { property: 'deviceId', order: 'asc' };

  public sensorstationData = new LcmmDataSource<
    Sensorstation,
    SensorstationQuery
  >(
    (request, query) => this.sensorstationService.page(request, query),
    this.initialSort,
    {
      deviceId: undefined,
      aFESerialNumber: undefined,
      name: undefined,
      group: undefined,
      street: undefined,
      city: undefined,
      state: undefined,
    }
  );

  public serialNumberFilter = '';

  public deviceIdFilter = '';

  public deviceNameFilter = '';

  @ViewChild(MatSort) public sort: MatSort;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public sensorstationService: SensorstationService
  ) {}

  public openEdit(sensorstation: Sensorstation, allowEditing: boolean): void {
    const dialogRef = this.dialog.open(SensorstationDialogComponent, {
      width: '80%',
      data: { sensorstation, groups: this.groups, allowEditing },
    });

    dialogRef.afterClosed().subscribe((isChanged) => {
      if (isChanged != null && isChanged === true) {
        this.sensorstationData.fetch();
      }
    });
  }

  public create(): void {
    const dialogRef = this.dialog.open(SensorstationCreateDialogComponent, {
      width: '80%',
      height: '80%',
      data: {
        groups: this.groups,
      },
    });

    dialogRef.afterClosed().subscribe((returnedSensorstation) => {
      if (returnedSensorstation != null) {
        this.sensorstationData.fetch();
      }
    });
  }

  private alterSensorstation(
    theSensorstation: Sensorstation,
    theAction: ConfirmationDialogAction,
    func: (s: Sensorstation) => Observable<Sensorstation>
  ) {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.sensorstation,
      action: theAction,
      data: theSensorstation.name,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((shoudlAlter) => {
      if (shoudlAlter) {
        func(theSensorstation).subscribe(() => {
          this.sensorstationData.fetch();
        });
      }
    });
  }

  public deleteSensorstation(sensorstation: Sensorstation): void {
    const alterFunction = this.sensorstationService.deleteSensorstation.bind(
      this.sensorstationService
    );
    this.alterSensorstation(
      sensorstation,
      ConfirmationDialogAction.delete,
      alterFunction
    );
  }

  public queryByFilter(): void {
    this.sensorstationData.queryBy({
      name: this.deviceNameFilter,
      aFESerialNumber: this.serialNumberFilter,
      deviceId: this.deviceIdFilter,
      group: this.groupFilter,
    });
  }
}
