<div><app-trip-analysis *ngIf="tripResponse" [tripResponse]="tripResponse" [disableStars]="true"></app-trip-analysis></div>

<div *ngIf="tripResponse" class="evaluation-form">
  <div>
    <div class="label" translate="TRIP.ROUTEEVALUATION"></div>
    <div>
      <mat-icon [color]="validTrip ? 'primary': 'accent'" *ngFor="let dummy of [].constructor(5); let i = index" [id]="'star_route_'+i" (click)="changeStars(StarType.ROUTE ,i+1)" class="material-icon-outlined">
        <span class="span-trip-star">{{ (i + 1) <= routeStars ? 'star' : 'star_outline' }}</span>
      </mat-icon>
    </div>
  </div>

  <div>
    <div class="label" translate="TRIP.TRAFFICEVALUATION"></div>
    <div>
      <mat-icon [color]="validTrip ? 'primary': 'accent'" *ngFor="let dummy of [].constructor(5); let i = index" [id]="'star_traffic_'+i" (click)="changeStars(StarType.TRAFFIC ,i+1)" class="material-icon-outlined">
        <span class="span-trip-star">{{ (i + 1) <= trafficStars ? 'star' : 'star_outline' }}</span>
      </mat-icon>
    </div>
  </div>

  <div>
    <div class="label" translate="TRIP.DRIVINGEVALUATION"></div>
    <div>
      <mat-icon [color]="validTrip ? 'primary': 'accent'" *ngFor="let dummy of [].constructor(5); let i = index" [id]="'star_drive_'+i" (click)="changeStars(StarType.DRIVE ,i+1)" class="material-icon-outlined">
        <span class="span-trip-star">{{ (i + 1) <= drivingStars ? 'star' : 'star_outline' }}</span>
      </mat-icon>
    </div>
  </div>
  <div>
    <div class="label" translate="EVALUATION.TRIP.VALID"></div>
    <mat-slide-toggle color="primary" [(ngModel)]="validTrip"></mat-slide-toggle>
  </div>
  <button mat-raised-button (click)="submitEvaluation()" [disabled]="evaluationInvalid()">
    {{ 'EVALUATION.SUBMIT' | translate }}
  </button>
  <button mat-raised-button (click)="nextRandomTrip()">
    {{ 'EVALUATION.NEXT' | translate }}
  </button>
</div>
