import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group, VehicleType } from 'lcmm-lib-js';
import { VehicleService } from '../../../service/vehicle.service';

@Component({
  selector: 'app-vehicle-type-create-dialog',
  templateUrl: './vehicle-type-create-dialog.component.html',
  styleUrls: ['./vehicle-type-create-dialog.component.scss'],
})
export class VehicleTypeCreateDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  public vehicleType: VehicleType = {
    brand: '',
    series: '',
    model: '',
    hsn: '',
    tsn: '',
    airDragCoefficient: null,
    crossSectionArea: null,
    efficiency: null,
    fuelEmissionFactor: null,
    fuelValue: null,
    mass: null,
    rollFrictionCoefficient: null,
    standStillFuelConsumption: null,
    startStop: false,
    airConditioning: false,
    motorHeating: false,
    wltpClass: 3,
    groupName: null,
  };

  public groups: Group[];

  public loading = false;

  constructor(
    public dialogRef: MatDialogRef<VehicleTypeCreateDialogComponent>,
    private vehicleService: VehicleService,
    @Inject(MAT_DIALOG_DATA) public data: { groups: Group[] }
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;

    if (data.groups) {
      this.groups = data.groups;
    }
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
  }

  public onSubmit(): void {
    const vehicleType: VehicleType = {
      ...this.form.value.vehicleTypeForm,
    };
    this.loading = true;
    this.vehicleService.createVehicleType(vehicleType).subscribe(
      (createdVehicleType) => {
        this.loading = false;
        this.dialogRef.close(createdVehicleType);
      },
      () => {
        this.loading = false;
      }
    );
  }
}
