import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartEventService } from 'src/app/service/chart-event.service';
import { MultiChartService } from 'src/app/service/multi-chart.service';
import { AbstractMultiChartComponent } from '../abstract-multi-chart/abstract-multi-chart.component';

@Component({ template: '' })
export abstract class AbstractTripMultiInfoTimeChartComponent extends AbstractMultiChartComponent {
  constructor(
    translateService: TranslateService,
    mcs: MultiChartService,
    ces: ChartEventService
  ) {
    super(translateService, mcs, ces);
    this.xScaleType = 'time';
    this.chartId = 'TimeChart';
    this.xAxisType = 'TimeChart';
  }
}
