import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Group, User } from 'lcmm-lib-js';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { flattenGroups, sortString } from '../utils/utils';
import { EnvConfigurationService } from './env-config.service';
import { AuthService } from './auth.service';
import { CustomHttpParamEncoder } from '../utils/custom-http-param-encoder';

export interface UserPageRequest {
  page?: number;
  size?: number;
  group?: string;
  username?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
}

export interface BulkUser {
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  userRole: string;
  importMessageCode?: string;
  listId?: number;
}

export interface GroupCode extends Group {
  groupId?: string;
  code?: string;
  creationTimestamp?: Date;
  status?: string;
  link?: string;
}

const defaultHttpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
  params: {
    size: '999999',
  },
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private URL: string;

  private _flattenedGroups = new BehaviorSubject<GroupCode[]>(null);

  private _users = new BehaviorSubject<User[]>(null);

  private groupName: string;

  private groupIdentifier: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public envService: EnvConfigurationService,
    private ts: TranslateService,
    private dp: DatePipe
  ) {
    this.URL = envService.config.userManagementUrl;
    this.groupName = null;
    this.groupIdentifier = this.authService.getGroupIdentifier();
    if (this.groupIdentifier) {
      const groupNames = this.groupIdentifier.split(
        envService.config.groupIdentifierSeparator
      );
      this.groupName = groupNames[groupNames.length - 2];
    }

    if (authService.isAdminOrDispatcher()) {
      this._getGroups().subscribe((groups) => {
        const flGroups = flattenGroups(groups).sort(
          sortString('groupIdentifier')
        );
        this._flattenedGroups.next(flGroups);
      });

      this._getUsers().subscribe((users) => {
        const filteredUsers = users;
        this.updateUserGroup(filteredUsers).finally(() => {
          this._users.next(filteredUsers.sort(sortString('userName')));
        });
      });
    }
  }

  private _getUsers(userPageRequest?: UserPageRequest): Observable<User[]> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    let size = 999999;
    let page = 0;
    if (userPageRequest?.size) size = userPageRequest.size;
    if (userPageRequest?.page) page = userPageRequest.page;
    params = params.append('size', size);
    params = params.append('page', page);
    if (userPageRequest?.page)
      params = params.append('page', userPageRequest.page);
    if (userPageRequest?.username)
      params = params.append('username', userPageRequest.username);
    if (userPageRequest?.firstname)
      params = params.append('firstName', userPageRequest.firstname);
    if (userPageRequest?.lastname)
      params = params.append('lastName', userPageRequest.lastname);
    if (userPageRequest?.email)
      params = params.append('email', userPageRequest.email);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params,
    };
    return this.http.get<User[]>(`${this.URL}/users`, options);
  }

  private _getGroups(): Observable<GroupCode[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      params: {
        size: '999999',
      },
    };
    return this.http.get<GroupCode[]>(`${this.URL}/groups`, httpOptions);
  }

  private setUserGroup(user: User, groups: GroupCode[]): void {
    for (let i = 0; i < groups.length; i += 1) {
      const g = groups[i];
      if (g.id === user.userGroupId) {
        // eslint-disable-next-line no-param-reassign
        user.group = g.groupName;
        return;
      }
    }
  }

  public static createEmptyBulkUser(): BulkUser {
    const bulkUser: BulkUser = {
      listId: null,
      userName: null,
      email: null,
      userRole: null,
      firstName: null,
      lastName: null,
    };
    return bulkUser;
  }

  public static createEmptyUser(): User {
    const user: User = {
      id: null,
      userName: null,
      email: null,
      userRole: null,
      firstName: null,
      lastName: null,
      userGroupId: null,
      group: null,
    };
    return user;
  }

  private updateUserGroup(users: User[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.flattenedGroups.subscribe(
        (groups) => {
          if (groups) {
            for (let i = 0; i < users.length; i += 1) {
              this.setUserGroup(users[i], groups);
            }
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  // public access

  public readonly flattenedGroups = this._flattenedGroups.asObservable();

  public readonly users = this._users.asObservable();

  public getCurrentUserGroupName(): string {
    return this.groupName;
  }

  public getGroupIdentifier(): string {
    return this.groupIdentifier;
  }

  public getEmptyGroupIdentifier(): string {
    return 'DetectEmptyGrId.';
  }

  public updateUser(user: User): Observable<User> {
    return this.http.put<User>(
      `${this.URL}/users/${user.id}`,
      user,
      defaultHttpOptions
    );
  }

  public createUser(user: User): Observable<User> {
    return new Observable((observer) => {
      this.http
        .post<User>(`${this.URL}/users`, user, defaultHttpOptions)
        .subscribe(
          (createdUser: User) => {
            const cu = { ...createdUser };
            cu.group = user.group;
            const groups = this._flattenedGroups.getValue();
            groups
              .find((element) => element.id === cu.userGroupId)
              .users.push(cu);
            this._flattenedGroups.next(groups);
            this._users.next([...this._users.getValue(), cu]);
            observer.next(cu);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public createBulkUsers(
    group: GroupCode,
    bulkUsers: BulkUser[]
  ): Observable<BulkUser[]> {
    const url = `${this.URL}/groups/${group.groupIdentifier}/users/import`;
    return this.http.post<BulkUser[]>(url, bulkUsers, defaultHttpOptions);
  }

  public deleteUser(user: User): Promise<void> {
    return this.http
      .delete<User>(`${this.URL}/users/${user.id}`, defaultHttpOptions)
      .toPromise()
      .then(() => {
        this._users.next(
          this._users.getValue().filter((element) => element.id !== user.id)
        );
      });
  }

  public getGroupByIdentifier(identifier: string): Observable<GroupCode> {
    return this.http.get<GroupCode>(
      `${this.URL}/groups/by-identifier/${identifier}`,
      defaultHttpOptions
    );
  }

  public updateGroup(group: GroupCode): Observable<GroupCode> {
    return this.http.put<GroupCode>(
      `${this.URL}/groups/${group.id}`,
      group,
      defaultHttpOptions
    );
  }

  public createGroup(group: GroupCode): Observable<GroupCode> {
    return new Observable((observer) => {
      this.http
        .post<GroupCode>(`${this.URL}/groups`, group, defaultHttpOptions)
        .subscribe(
          (createdGroup) => {
            const groups = this._flattenedGroups.getValue();
            this._flattenedGroups.next(groups);
            observer.next(createdGroup);
            observer.complete();
          },
          () => {
            observer.error();
          }
        );
    });
  }

  public deleteGroup(group: GroupCode): Observable<GroupCode> {
    return new Observable((observer) => {
      this.http
        .delete<GroupCode>(`${this.URL}/groups/${group.id}`, defaultHttpOptions)
        .subscribe(
          () => {
            const groups = this._flattenedGroups.getValue();
            this._flattenedGroups.next(groups);
            observer.next(group);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  public getUsers(userPageRequest?: UserPageRequest): Observable<User[]> {
    return new Observable<User[]>((observer) => {
      this._getUsers(userPageRequest).subscribe(
        (users) => {
          const filteredUsers = users;
          this.updateUserGroup(filteredUsers).finally(() => {
            const sortedUsers = filteredUsers.sort(sortString('userName'));
            observer.next(sortedUsers);
            observer.complete();
          });
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  public emptyUserPageRequest(): UserPageRequest {
    const upr: UserPageRequest = {
      size: undefined,
      page: undefined,
      username: undefined,
      firstname: undefined,
      lastname: undefined,
      email: undefined,
    };
    return upr;
  }

  private updateGroupCode(
    gc: GroupCode,
    status?: string,
    code?: string,
    creationTimestamp?: Date
  ): void {
    if (status) {
      // eslint-disable-next-line no-param-reassign
      gc.status = status;
    } else {
      // eslint-disable-next-line no-param-reassign
      gc.status = null;
    }
    if (code) {
      // eslint-disable-next-line no-param-reassign
      gc.code = code;
      // eslint-disable-next-line no-param-reassign
      gc.link = `${this.envService.config.lcmmUrl}/register?lcmm_code=${code}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      gc.code = null;
      // eslint-disable-next-line no-param-reassign
      gc.link = null;
    }
    if (creationTimestamp) {
      // eslint-disable-next-line no-param-reassign
      gc.creationTimestamp = creationTimestamp;
      // eslint-disable-next-line no-param-reassign
      gc.status = `${this.ts.instant('GROUP.CODECREATION')} ${this.dp.transform(
        creationTimestamp,
        'dd/MM/yyyy, HH:mm'
      )}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      gc.creationTimestamp = null;
    }
  }

  private createGroupCode(groupCode: GroupCode): Promise<GroupCode> {
    return new Promise<GroupCode>((resolve, reject) => {
      this.updateGroupCode(groupCode, 'creating...');
      const body = `{ "groupId": "${groupCode.id}" }`;
      this.http
        .post<GroupCode>(`${this.URL}/groupcode`, body, defaultHttpOptions)
        .subscribe(
          (gc: GroupCode) => {
            this.updateGroupCode(
              groupCode,
              null,
              gc.code,
              gc.creationTimestamp
            );
            resolve(groupCode);
          },
          (err) => {
            this.updateGroupCode(groupCode, JSON.stringify(err));
            reject(err);
          }
        );
    });
  }

  private getGroupCodeByGroupId(groupCode: GroupCode): Promise<GroupCode> {
    return new Promise<GroupCode>((resolve, reject) => {
      this.updateGroupCode(groupCode, 'searching...');
      this.http
        .get<GroupCode>(
          `${this.URL}/groupcode/${groupCode.id}`,
          defaultHttpOptions
        )
        .subscribe(
          (gc: GroupCode) => {
            this.updateGroupCode(
              groupCode,
              null,
              gc.code,
              gc.creationTimestamp
            );
            resolve(groupCode);
          },
          (err) => {
            this.updateGroupCode(groupCode, JSON.stringify(err));
            reject(err);
          }
        );
    });
  }

  public getGroupCode(
    groupCode: GroupCode,
    renew?: boolean
  ): Promise<GroupCode> {
    return new Promise<GroupCode>((resolve, reject) => {
      if (renew) {
        this.createGroupCode(groupCode)
          .then((gc) => {
            resolve(gc);
          })
          .catch((err) => {
            reject(err);
          });
      } else if (groupCode.code === undefined || groupCode.code === null) {
        this.getGroupCodeByGroupId(groupCode)
          .then((gc) => {
            resolve(gc);
          })
          .catch((getErr) => {
            if (getErr.status === 404) {
              this.createGroupCode(groupCode)
                .then((gc) => {
                  resolve(gc);
                })
                .catch((createErr) => {
                  reject(createErr);
                });
            } else {
              reject(getErr);
            }
          });
      } else {
        resolve(groupCode);
      }
    });
  }
}
