<h2 mat-dialog-title>{{ 'TOOLS.TRIP_BATCH_TITLE' | translate }}</h2>
<span class="truncateWarning" *ngIf="truncateWarning"
  >{{ 'TOOLS.TRUNCATEDWARNING' | translate:{totalTrips: totalTrips, numberOfDisplayedTrips: numberOfDisplayedTrips } }}</span
>
<mat-dialog-content class="tables">
  <table mat-table [dataSource]="table" class="mat-elevation-z8">
    <ng-container matColumnDef="tripId">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TOOLS.TRIPID' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.tripId }}</td>
    </ng-container>
    <ng-container matColumnDef="groupName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'TOOLS.GROUPNAME' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.groupName }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
<mat-dialog-actions class="config">
  <button class="mat-raised-button" [mat-dialog-close]>
    {{ 'CLOSE' | translate }}
  </button>
</mat-dialog-actions>
