<div>
  <canvas 
    height="{{lineChartHeightPx}}"
    baseChart
    [datasets]="lineChartData"
    [options]="lineChartOptions"
    [chartType]="lineChartType"
    [plugins]="lineChartPlugins"
    [labels]="lineChartLabels"
    [legend]="lineChartLegend">
  </canvas>
</div>
