import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { EnvConfigurationService } from '../service/env-config.service';

interface EmailResponse {
  success: boolean;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  public content = '???';

  public formData: FormGroup;

  public msgCharLength = 500;

  public remainingCharLength = this.msgCharLength;

  public comment = '';

  public emailSent = false;

  public emailError = false;

  constructor(
    private ts: TranslateService,
    private builder: FormBuilder,
    private http: HttpClient,
    public envService: EnvConfigurationService
  ) {}

  ngOnInit(): void {
    this.content = this.ts.instant('CONTACT.TEXT');
    this.formData = this.builder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.msgCharLength),
      ]),
      subject: new FormControl('', [Validators.required]),
    });
    this.updateCharCount();
  }

  public updateCharCount() {
    const messageControl = this.formData.get('message');
    if (messageControl) {
      this.remainingCharLength =
        this.msgCharLength - (messageControl.value?.length || 0);
    }
  }

  public submit(formDirective) {
    if (this.formData.valid) {
      this.http
        .post<EmailResponse>(
          `${this.envService.config.lcmmUrl}/mailer.php`,
          this.formData.value
        )
        .subscribe(
          (response) => {
            this.emailSent = response.success;
            this.emailError = !response.success;
            // Reset form after successful submission
            if (response.success) {
              this.resetForm(formDirective);
              setTimeout(() => {
                this.emailSent = false;
              }, 5000);
            }
          },
          () => {
            this.emailSent = false;
            this.emailError = true;
            setTimeout(() => {
              this.emailError = false;
            }, 5000);
          }
        );
    } else {
      // Mark form controls as touched to display validation errors
      Object.values(this.formData.controls).forEach((control) => {
        control.markAsTouched();
      });
    }
  }

  private resetForm(formDirective: FormGroupDirective) {
    formDirective.resetForm();
    this.formData.reset(); // Reset form values
    this.updateCharCount();
  }
}
