import { Component, Inject, OnInit } from '@angular/core';
import { Sensorstation } from 'sensorstation-lib-js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Group } from 'lcmm-lib-js';
import { UntypedFormGroup } from '@angular/forms';
import { SensorstationService } from '../service/sensorstation.service';

@Component({
  selector: 'app-sensorstation-dialog',
  templateUrl: './sensorstation-dialog.component.html',
  styleUrls: ['./sensorstation-dialog.component.scss'],
})
export class SensorstationDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  public sensorstation: Sensorstation;

  public isSensorstationUpdated: boolean;

  public groups: Group[];

  public loading = false;

  public allowEditing: boolean;

  constructor(
    public dialogRef: MatDialogRef<SensorstationDialogComponent>,
    private sensorstationService: SensorstationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sensorstation: Sensorstation;
      groups: Group[];
      allowEditing: boolean;
    }
  ) {
    // eslint-disable-next-line no-param-reassign
    dialogRef.disableClose = true;

    this.sensorstation = null;
    if (data.sensorstation) {
      this.sensorstation = data.sensorstation;
    }
    this.groups = null;
    if (data.groups) {
      this.groups = data.groups;
    }

    this.allowEditing = data.allowEditing;
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
    this.isSensorstationUpdated = false;
  }

  private _getSensorstation(): Sensorstation {
    const sensorstation: Sensorstation = this.form.value.sensorstationForm;
    sensorstation.deviceId = this.sensorstation.deviceId;
    sensorstation.aFESerialNumber = this.sensorstation.aFESerialNumber;
    sensorstation.group = this.sensorstation.group;
    return sensorstation;
  }

  public onSubmit(): void {
    const sensorstation: Sensorstation = this._getSensorstation();
    this.loading = true;
    this.isSensorstationUpdated = false;
    this.sensorstationService.updateSensorstation(sensorstation).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (updatedSensorstation) => {
        this.isSensorstationUpdated = true;
        this.loading = false;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err) => {
        this.loading = false;
      }
    );
  }

  public onClose(): void {
    this.dialogRef.close(this.isSensorstationUpdated);
  }
}
