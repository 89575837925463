import { Injectable } from '@angular/core';
import * as Chart from 'chart.js';
import * as chartZoomPlugin from 'chartjs-plugin-zoom';
import { Subscription } from 'rxjs';

type RegisterChartFunction = (any) => void;

type ComputeChartFunction = (any) => void;

type UpdateChartFunction = () => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComputeChartDataFunction = (any) => any;

export interface ChartYAxisProperties {
  index: number;
  color: string;
  hiddenColor: string;
  min: number;
  max: number;
  visibility: boolean;
}

export interface MultiChart {
  computed: boolean;
  chartDataSets: Chart.ChartDataSets[];
  chartOptions: Chart.ChartOptions;
  chartType: Chart.ChartType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartPlugins: any[];
  chartLabels: string[];
  chartLegend: boolean;
  chartId: string;
  hiddenColor: string;
  chartXAxe: Chart.ChartXAxe;
  yAxisConfig: Map<number, ChartYAxisProperties>;
  chart: Chart;
  computeChartDataFunctionList: ComputeChartDataFunction[];
  computeChartData: boolean;
  subscription: Subscription;
  isViewInitialized: boolean;
  isXScaleChanged: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MultiChartService {
  private multiCharts: Map<string, MultiChart> = new Map<string, MultiChart>();

  private sectionMultiCharts: Map<string, MultiChart> = new Map<
    string,
    MultiChart
  >();

  private getChartsMap(isSection: boolean): Map<string, MultiChart> {
    if (isSection) {
      return this.sectionMultiCharts;
    }
    return this.multiCharts;
  }

  private create(theChartId: string): MultiChart {
    const mc: MultiChart = {
      computed: false,
      chartDataSets: [],
      chartOptions: {},
      chartType: 'line',
      chartPlugins: [],
      chartLabels: [],
      chartLegend: true,
      chartId: theChartId,
      hiddenColor: 'lightgrey',
      chartXAxe: null,
      yAxisConfig: new Map<number, ChartYAxisProperties>(),
      chart: null,
      computeChartDataFunctionList: [],
      computeChartData: true,
      subscription: null,
      isViewInitialized: false,
      isXScaleChanged: false,
    };
    return mc;
  }

  private set(
    chartId: string,
    multiChart: MultiChart,
    isSection: boolean
  ): MultiChart {
    this.getChartsMap(isSection).set(chartId, multiChart);
    return multiChart;
  }

  private createChartOptions(): Chart.ChartOptions {
    const co: Chart.ChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        display: true,
      },
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      responsiveAnimationDuration: 0,
      scales: {
        xAxes: [],
        yAxes: [],
      },
      plugins: {
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
          },
          zoom: {
            enabled: true,
            mode: 'x',
          },
        },
      },
    };
    return co;
  }

  private resetChartPlugins(
    multiChart: MultiChart,
    updateChartFunction?: UpdateChartFunction
  ): void {
    // eslint-disable-next-line no-param-reassign
    multiChart.chartPlugins = [];
    multiChart.chartPlugins.push(chartZoomPlugin);
    multiChart.chartPlugins.push({
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      afterUpdate: (updatedChart) => {
        // eslint-disable-next-line no-param-reassign
        multiChart.chart = updatedChart as Chart;
        // eslint-disable-next-line no-param-reassign
        multiChart.isXScaleChanged = true;
        if (updateChartFunction !== undefined) {
          updateChartFunction();
        }
      },
    });
  }

  public get(
    chartId: string,
    isSection: boolean,
    registerChartFunction?: RegisterChartFunction,
    computeChartFunction?: ComputeChartFunction,
    updateChartFunction?: UpdateChartFunction
  ): MultiChart {
    const chartsMap = this.getChartsMap(isSection);
    let multiChart = chartsMap.get(chartId);
    if (multiChart === undefined) {
      multiChart = this.create(chartId);
      this.set(chartId, multiChart, isSection);
    }
    if (multiChart.chartDataSets.length <= 0) {
      if (registerChartFunction !== undefined) {
        multiChart.chartOptions = this.createChartOptions();
        registerChartFunction(multiChart);
      }
      if (computeChartFunction !== undefined) {
        computeChartFunction(multiChart);
        // multiChart.computed = true;
      }
      this.resetChartPlugins(multiChart, updateChartFunction);
    }
    return multiChart;
  }

  public reset(isSection: boolean): void {
    const charts = this.getChartsMap(isSection);
    charts.forEach((mc) => {
      // eslint-disable-next-line no-param-reassign
      mc.chartDataSets = null;
      // eslint-disable-next-line no-param-reassign
      mc.computeChartDataFunctionList = null;
      // eslint-disable-next-line no-param-reassign
      mc.isXScaleChanged = false;
    });
    charts.clear();
  }

  public syncChartAxis(
    masterChartId: string,
    slaveChartId: string,
    isSection: boolean
  ): void {
    const slave = this.get(slaveChartId, isSection);
    if (slave.chartDataSets.length > 0) {
      const master = this.get(masterChartId, isSection);
      let beginIndex: number = null;
      let endIndex: number = null;
      for (let i = 0; i < master.chartDataSets.length; i += 1) {
        slave.yAxisConfig.set(i, { ...master.yAxisConfig.get(i) });
      }
      if (
        master.chartXAxe.ticks.min !== undefined ||
        master.chartXAxe.ticks.max !== undefined
      ) {
        for (let i = 0; i < master.chartDataSets[0].data.length; i += 1) {
          const data = master.chartDataSets[0].data[i] as Chart.ChartPoint;
          if (
            master.chartXAxe.ticks.min !== undefined &&
            beginIndex === null &&
            data.x >= master.chartXAxe.ticks.min
          ) {
            beginIndex = i;
          }
          if (master.chartXAxe.ticks.max !== undefined) {
            if (data.x > master.chartXAxe.ticks.max) {
              break;
            }
            endIndex = i;
          }
        }
      }
      if (beginIndex !== null) {
        slave.chartXAxe.ticks.min = (
          slave.chartDataSets[0].data[beginIndex] as Chart.ChartPoint
        ).x;
      }
      if (endIndex !== null) {
        slave.chartXAxe.ticks.max = (
          slave.chartDataSets[0].data[endIndex] as Chart.ChartPoint
        ).x;
      }
    }
  }

  public createXAxis(xAxeType: string): Chart.ChartXAxe {
    if (xAxeType === 'linear') {
      const xa: Chart.ChartXAxe = {
        type: 'linear',
      };
      return xa;
    }
    if (xAxeType === 'time') {
      const xa: Chart.ChartXAxe = {
        type: 'time',
        time: {
          unit: 'minute',
          tooltipFormat: 'YYYY-MM-DD HH:mm:ss',
          displayFormats: {
            minute: 'H:mm',
          },
        },
      };
      return xa;
    }
    return null;
  }

  public createChartDataSets(): Chart.ChartDataSets {
    const chartData: Chart.ChartDataSets = {
      borderColor: 'black',
      label: 'label',
      yAxisID: 'yAxisID',
      data: [],
      fill: false,
      cubicInterpolationMode: 'monotone',
      lineTension: 0,
      pointRadius: 0,
      borderWidth: 1,
      pointHitRadius: 5,
      hidden: false,
    };
    return chartData;
  }

  public createYAxisProperties(
    theIndex: number,
    theColor: string,
    theHiddenColor: string,
    theVisibility: boolean
  ): ChartYAxisProperties {
    const props: ChartYAxisProperties = {
      index: theIndex,
      color: theColor,
      hiddenColor: theHiddenColor,
      min: undefined,
      max: undefined,
      visibility: theVisibility,
    };
    return props;
  }

  public createYAxis(
    axisId: string,
    label: string,
    color: string,
    yPosition: string
  ): Chart.ChartYAxe {
    const ya: Chart.ChartYAxe = {
      scaleLabel: {
        display: true,
        labelString: label,
        fontColor: color,
      },
      id: axisId,
      type: 'linear',
      position: yPosition,
      ticks: {
        fontColor: color,
      },
    };
    return ya;
  }
}
