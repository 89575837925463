<div class="container">
  <h2 *ngIf="!isSectionDialog" mat-dialog-title>{{ getTripInfo() }}</h2>
  <h2 *ngIf="isSectionDialog" class="mat-dialog-title-red" mat-dialog-title>{{ getTripInfo() }}</h2>
  <mat-dialog-content>
    <mat-tab-group (selectedTabChange)="tabChanged()" [(selectedIndex)]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIP.DETAILS"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-details [tripResponse]="tripResponse" [disableStars]="disableStars"></app-trip-details>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.MAP.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-map [tripObservable]="tripObservable" [trip]="trip" [isSectionDialog]="isSectionDialog"></app-trip-map>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.SPEED.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-speed-profile [tripObservable]="tripObservable" [trip]="trip" [isSectionDialog]="isSectionDialog"></app-trip-speed-profile>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.ALTITUDE.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-altitude-profile [tripObservable]="tripObservable" [trip]="trip" [isSectionDialog]="isSectionDialog"></app-trip-altitude-profile>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.CO2.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-co2-profile [tripObservable]="tripObservable" [trip]="trip" [isSectionDialog]="isSectionDialog"></app-trip-co2-profile>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.WAY.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-way-profile [tripObservable]="tripObservable" [trip]="trip" [isSectionDialog]="isSectionDialog"></app-trip-way-profile>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span translate="TRIPS.INFO.TITLE"></span>
        </ng-template>
        <ng-template matTabContent>
          <app-trip-multi-info-distance-chart [tripObservable]="tripObservable" [trip]="trip" *ngIf="!getShowXAxisTime()" [isSectionDialog]="isSectionDialog"></app-trip-multi-info-distance-chart>
          <app-trip-multi-info-time-chart [tripObservable]="tripObservable" [trip]="trip" *ngIf="getShowXAxisTime()" [isSectionDialog]="isSectionDialog"></app-trip-multi-info-time-chart>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions class="buttons" *ngIf="selectedTabIndex === 0">
    <button *ngIf="isSectionDialog" class="single-button" mat-raised-button color="primary"  [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
    <button *ngIf="!isSectionDialog" class="single-button" mat-raised-button color="primary" (click)="sectionReset()" [disabled]="!isTripSectionChanged()">
      {{ 'TRIP.SECTIONRESET' | translate }}
    </button>
    <mat-form-field *ngIf="!isSectionDialog">
      <mat-label>{{ 'TRIP.STARTTIME' | translate }}</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="startPicker"
        [max]="dateStartMax"
        [min]="dateStartMin"
        autocomplete="off"
        [ngModel]="dateStart"
        (dateChange)="startTimeChanged($event.value)"
        [formControl]="myControlStartDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!startDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #startPicker [showSeconds]="true"> </ngx-mat-datetime-picker>
    </mat-form-field>
    <mat-form-field *ngIf="!isSectionDialog">
      <mat-label>{{ 'TRIP.ENDTIME' | translate }}</mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="endPicker"
        [max]="dateEndMax"
        [min]="dateEndMin"
        autocomplete="off"
        [ngModel]="dateEnd"
        (dateChange)="endTimeChanged($event.value)"
        [formControl]="myControlEndDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!endDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #endPicker [showSeconds]="true"></ngx-mat-datetime-picker>
    </mat-form-field>
    <button *ngIf="!isSectionDialog" class="single-button" mat-raised-button color="primary" (click)="sectionDetails()" [disabled]="isSectionDetailsButtonDisabled()" [class.spinner]="sectionDetailsLoading">
      {{ 'TRIP.SECTIONDETAILS' | translate }}
    </button>
  </mat-dialog-actions>
  
  <mat-dialog-actions class="buttons" *ngIf="selectedTabIndex === 1">
    <button *ngIf="isSectionDialog" class="single-button" mat-raised-button color="primary"  [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
    <button *ngIf="authService.isDownloadEnabled()" class="single-button" mat-raised-button color="primary" (click)="downloadKml()" [disabled]="kmlLoading" [class.spinner]="kmlLoading" [disabled]="isSectionDetailsOpen()||sectionDetailsLoading">
      {{ 'TRIP.KMLDOWNLOAD' | translate }}
    </button>
    <button *ngIf="authService.isDownloadEnabled()" class="single-button" mat-raised-button color="primary" (click)="downloadCsv()" [class.spinner]="csvLoading" [disabled]="csvLoading" [disabled]="isSectionDetailsOpen()||sectionDetailsLoading">
      {{ 'TRIP.CSVDOWNLOAD' | translate }}
    </button>
    <button *ngIf="!isSectionDialog" class="single-button" mat-raised-button color="primary" (click)="sectionDetails()" [disabled]="isSectionDetailsButtonDisabled()" [class.spinner]="sectionDetailsLoading">
      {{ 'TRIP.SECTIONDETAILS' | translate }}
    </button>
  </mat-dialog-actions>

  <mat-dialog-actions class="buttons" *ngIf="selectedTabIndex > 1 && selectedTabIndex <= 5">
    <button *ngIf="isSectionDialog" class="single-button" mat-raised-button color="primary" [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
    <button *ngIf="!isSectionDialog" class="single-button" mat-raised-button color="primary" (click)="sectionDetails()" [disabled]="isSectionDetailsButtonDisabled()" [class.spinner]="sectionDetailsLoading">
      {{ 'TRIP.SECTIONDETAILS' | translate }}
    </button>
  </mat-dialog-actions>
  
  <mat-dialog-actions class="xaxisbuttons" *ngIf="selectedTabIndex > 5">
    <button *ngIf="isSectionDialog" class="single-button" mat-raised-button color="primary"  [mat-dialog-close]>
      {{ 'CLOSE' | translate }}
    </button>
    <mat-dialog-actions (click)="switchXAxis()" class="xaxisbuttons">
      <button mat-flat-button class="xaxisbutton" color="basic" [class.spinner]="csvLoading" [disabled]="csvLoading || !getShowXAxisTime()" >
        {{ 'TRIPS.XLABEL.TIME' | translate }}
      </button>
      <div>&nbsp;/&nbsp; </div>
      <button mat-flat-button class="xaxisbutton" color="basic" [class.spinner]="csvLoading" [disabled]="csvLoading || getShowXAxisTime()" >
        {{ 'TRIPS.XLABEL.DISTANCE' | translate }}
      </button>
    </mat-dialog-actions>
    <div>
      &nbsp; 
      &nbsp; 
      <mat-icon title="{{ 'TRIPS.XLABEL.TOOLTIP' | translate }}" (click)="switchXAxisConnection()">{{ getXAxisConnectionIcon()}}</mat-icon>
    </div>
    <button *ngIf="!isSectionDialog" class="single-button" mat-raised-button color="primary" (click)="sectionDetails()" [disabled]="isSectionDetailsButtonDisabled()" [class.spinner]="sectionDetailsLoading">
      {{ 'TRIP.SECTIONDETAILS' | translate }}
    </button>
  </mat-dialog-actions>

</div>