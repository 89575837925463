<h1>{{ 'HOME.WELCOMEMESSAGE' | translate }}</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>{{ 'HOME.DOWNLOADTITLE' | translate }}</mat-card-title>
  </mat-card-header>
  <img class="apk-img" mat-card-image src="assets/APK-Icon.jpg" alt="APK DOWNLOAD" />
  <mat-card-content>
    <p>{{ 'HOME.APKDOWNLOADEXPLANATION' | translate }}</p>
  </mat-card-content>
  <mat-card-actions>
    <button class="single-button" mat-raised-button color="primary" (click)="appDownload()" [disabled]="loading" [class.spinner]="loading">
      {{ 'DOWNLOAD' | translate }}
    </button>
  </mat-card-actions>
</mat-card>
