import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComputingTripResponses } from 'src/app/trips/trips-list/trips-list.component';

@Component({
  selector: 'app-overlay-spinner-trips-action',
  templateUrl: './overlay-spinner-trips-action.component.html',
  styleUrls: ['./overlay-spinner-trips-action.component.scss'],
})
export class OverlaySpinnerTripsActionComponent {
  @Input() public computingTripResponses: ComputingTripResponses;

  constructor(private tr: TranslateService) {}

  public cancel(): void {
    this.computingTripResponses.cancel = true;
  }

  public confirm(): void {
    this.computingTripResponses.isConfirmed = true;
  }

  public title(): string {
    if (this.computingTripResponses.isRunning) {
      return this.tr.instant(
        `${this.computingTripResponses.actionType as string}_RUNNING`
      );
    }
    if (this.computingTripResponses.isCanceld) {
      return this.tr.instant(
        `${this.computingTripResponses.actionType as string}_CANCELED`
      );
    }
    return this.tr.instant(
      `${this.computingTripResponses.actionType as string}_READY`
    );
  }

  public showAction(): boolean {
    return (
      this.computingTripResponses.toCompute > 0 &&
      !this.computingTripResponses.isConfirmed &&
      !this.computingTripResponses.noResult
    );
  }
}
