import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Trip } from 'lcmm-lib-js';
import { Observable, Subscription } from 'rxjs';
import {
  CallbackEventType,
  ChartEventService,
} from 'src/app/service/chart-event.service';
import {
  MultiChartService,
  MultiChart,
  ChartYAxisProperties,
} from 'src/app/service/multi-chart.service';
import { TripService } from 'src/app/service/trip.service';

import { ChartDataSets, ChartOptions, ChartType, ScaleType } from 'chart.js';

export type XAxisType = 'TimeChart' | 'DistanceChart';

@Component({ template: '' })
export abstract class AbstractMultiChartComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  // protected

  protected chartId: string;

  protected xScaleType: ScaleType;

  protected xAxisType: XAxisType;

  // public

  public lineChartHeightPx = 400;

  public lineChartData: ChartDataSets[];

  public lineChartOptions: ChartOptions;

  public lineChartPlugins;

  public lineChartType: ChartType;

  public lineChartLabels: string[];

  public lineChartLegend: boolean;

  // private

  private multiChart: MultiChart = null;

  private setXScaleType = true;

  private tripSubscription: Subscription = null;

  private _t: Trip = null;

  @Input() public trip: Trip;

  @Input() public tripObservable: Observable<Trip>;

  @Input() public isSectionDialog: boolean;

  constructor(
    private translateService: TranslateService,
    private mcs: MultiChartService,
    private ces: ChartEventService
  ) {
    this.lineChartHeightPx = ces.getAppHeightPx(40);
  }

  protected abstract register(multiChart: MultiChart): void;

  private compute(multiChart: MultiChart): void {
    if (
      !multiChart.computed &&
      this._t &&
      this._t.positions
      // && (!this.trip || this.trip.id === this._t.id)
    ) {
      // eslint-disable-next-line no-param-reassign
      multiChart.computeChartData = false;
      for (let index = 0; index < multiChart.chartDataSets.length; index += 1) {
        // eslint-disable-next-line no-param-reassign
        multiChart.chartDataSets[index].data =
          multiChart.computeChartDataFunctionList[index](
            this._t
          ) as Chart.ChartPoint[];
      }
      // eslint-disable-next-line no-param-reassign
      multiChart.computed = true;
    }
  }

  private subscribeTrip(): void {
    if (this.isSectionDialog) {
      this._t = this.trip;
    } else if (this.tripObservable) {
      this.tripSubscription = this.tripObservable.subscribe(async (t) => {
        this._t = t;
      });
    }
  }

  private unsubscribeTrip(): void {
    if (!this.isSectionDialog && this.tripSubscription !== null) {
      this.tripSubscription.unsubscribe();
      this.tripSubscription = null;
    }
  }

  private setMultiChart(mc: MultiChart): void {
    this.multiChart = mc;
    this.lineChartData = this.multiChart.chartDataSets;
    this.lineChartOptions = this.multiChart.chartOptions;
    this.lineChartPlugins = this.multiChart.chartPlugins;
    this.lineChartType = this.multiChart.chartType;
    this.lineChartLabels = this.multiChart.chartLabels;
    this.lineChartLegend = this.multiChart.chartLegend;
  }

  public reset(): void {
    this.subscribeTrip();
    this.setMultiChart(this.mcs.get(this.chartId, this.isSectionDialog));
    if (!this.multiChart.computed) {
      this.setMultiChart(
        this.mcs.get(
          this.chartId,
          this.isSectionDialog,
          this.register.bind(this),
          this.compute.bind(this),
          this.saveChart.bind(this)
        )
      );
    }
  }

  ngOnInit(): void {
    this.reset();
  }

  ngOnChanges(): void {
    this.reset();
  }

  ngOnDestroy(): void {
    this.ces.emit(CallbackEventType.cleared);
    this.multiChart.isViewInitialized = false;
    this.unsubscribeTrip();
    this.trip = undefined;
  }

  ngAfterViewInit(): void {
    this.multiChart.isViewInitialized = true;
    this.restoreChart();
  }

  // private functions

  private restoreChart(): void {
    try {
      const c: Chart = this.multiChart.chart;
      if (this.multiChart.chartXAxe !== null) {
        c.config.options.scales.xAxes[0] = this.multiChart.chartXAxe;
      }
      for (let i = 0; i < c.data.datasets.length; i += 1) {
        const yP: ChartYAxisProperties = this.multiChart.yAxisConfig.get(i);
        c.data.datasets[i].hidden = !yP.visibility;
        const chartYAxe: Chart.ChartYAxe = c.config.options.scales.yAxes[i];
        // chartYAxe.display = yP.visibility;
        chartYAxe.ticks.min = yP.min;
        chartYAxe.ticks.max = yP.max;
        if (yP.visibility) {
          chartYAxe.scaleLabel.fontColor = yP.color;
          chartYAxe.ticks.fontColor = yP.color;
        } else {
          chartYAxe.scaleLabel.fontColor = yP.hiddenColor;
          chartYAxe.ticks.fontColor = yP.hiddenColor;
        }
      }
      c.update();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  private createChangeEvent(chartId: string): void {
    const tsp = TripService.createTripSectionParameter(
      `MultiChart.${chartId}.changed`
    );
    const m: MultiChart = this.mcs.get('TimeChart', this.isSectionDialog);
    if (chartId === m.chartId) {
      if (m.chartXAxe.ticks.min !== undefined) {
        tsp.time.startTime = new Date(m.chartXAxe.ticks.min);
      }
      if (m.chartXAxe.ticks.max !== undefined) {
        tsp.time.endTime = new Date(m.chartXAxe.ticks.max);
      }
    } else {
      const c: MultiChart = this.mcs.get(chartId, this.isSectionDialog);
      let p: Chart.ChartPoint;
      let i: number;

      const { min } = c.chartXAxe.ticks;
      if (min !== undefined) {
        i = 0;
        while (i < c.chartDataSets[0].data.length) {
          p = c.chartDataSets[0].data[i] as Chart.ChartPoint;
          i += 1;
          if (p.x >= min) {
            tsp.time.startTime = new Date(p.t as number);
            i = c.chartDataSets[0].data.length;
          }
        }
      }

      const { max } = c.chartXAxe.ticks;
      if (max !== undefined) {
        i = c.chartDataSets[0].data.length;
        while (i > 0) {
          i -= 1;
          p = c.chartDataSets[0].data[i] as Chart.ChartPoint;
          if (p.x <= max) {
            tsp.time.endTime = new Date(p.t as number);
            i = 0;
          }
        }
      }
    }
    if (tsp.time.startTime !== undefined || tsp.time.endTime !== undefined) {
      this.ces.emit(CallbackEventType.changed, tsp);
    }
  }

  private saveChart(): void {
    try {
      if (this.multiChart.isViewInitialized) {
        const c: Chart = this.multiChart.chart;
        // eslint-disable-next-line prefer-destructuring
        this.multiChart.chartXAxe = c.config.options.scales.xAxes[0];
        for (let i = 0; i < c.data.datasets.length; i += 1) {
          const yP: ChartYAxisProperties = this.multiChart.yAxisConfig.get(i);
          yP.visibility = c.isDatasetVisible(i);
          if (yP.visibility) {
            // eslint-disable-next-line dot-notation
            const { min, max } = c['scales'][i];
            yP.min = min;
            yP.max = max;
          }
          const chartYAxe: Chart.ChartYAxe = c.config.options.scales.yAxes[i];
          // chartYAxe.display = yP.visibility;
          chartYAxe.ticks.min = yP.min;
          chartYAxe.ticks.max = yP.max;
          if (yP.visibility) {
            chartYAxe.scaleLabel.fontColor = yP.color;
            chartYAxe.ticks.fontColor = yP.color;
          } else {
            chartYAxe.scaleLabel.fontColor = yP.hiddenColor;
            chartYAxe.ticks.fontColor = yP.hiddenColor;
          }
        }
      }
      if (!this.isSectionDialog) {
        this.createChangeEvent(this.chartId);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  // protected functions

  protected registerComputeChartDataFunction(
    multiChart: MultiChart,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computeChartDataFunction: any,
    yLabel?: string,
    borderColor?: string,
    yLabelPosition?: string,
    hideData?: boolean
  ): void {
    if (yLabel === undefined || yLabel === null) {
      // eslint-disable-next-line no-param-reassign
      yLabel = 'Y Axis';
    }
    // eslint-disable-next-line no-param-reassign
    yLabel = this.translateService.instant(yLabel);
    if (borderColor === undefined || borderColor === null) {
      // eslint-disable-next-line no-param-reassign
      borderColor = 'black';
    }
    if (yLabelPosition === undefined || yLabelPosition === null) {
      // eslint-disable-next-line no-param-reassign
      yLabelPosition = 'left';
    }
    if (hideData === undefined || hideData === null) {
      // eslint-disable-next-line no-param-reassign
      hideData = true;
    }
    const dsIndex = multiChart.chartDataSets.length;
    const ds: Chart.ChartDataSets = this.mcs.createChartDataSets();
    ds.borderColor = borderColor;
    ds.label = yLabel;
    ds.yAxisID = `${dsIndex}`;
    ds.hidden = hideData;
    if (multiChart.yAxisConfig.get(dsIndex) === undefined) {
      const yProps = this.mcs.createYAxisProperties(
        dsIndex,
        borderColor,
        multiChart.hiddenColor,
        !hideData
      );
      multiChart.yAxisConfig.set(dsIndex, yProps);
    }
    multiChart.chartDataSets.push(ds);
    multiChart.computeChartDataFunctionList.push(computeChartDataFunction);
    multiChart.chartOptions.scales.yAxes.push(
      this.mcs.createYAxis(ds.yAxisID, yLabel, borderColor, yLabelPosition)
    );
    if (this.setXScaleType) {
      this.setXScaleType = false;
      const { chartXAxe } = multiChart;
      if (chartXAxe !== null) {
        multiChart.chartOptions.scales.xAxes.push(chartXAxe);
      } else {
        multiChart.chartOptions.scales.xAxes.push(
          this.mcs.createXAxis(this.xScaleType)
        );
      }
    }
  }
}
