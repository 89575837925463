import { Component, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';
import { EnvConfigurationService } from 'src/app/service/env-config.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  constructor(
    public envService: EnvConfigurationService,
    private matDialogRef: MatDialogRef<AccountComponent>,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();
    matDialogConfig.position = { right: '10px', top: '60px' };
    this.matDialogRef.updatePosition(matDialogConfig.position);
  }
}
