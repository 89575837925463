<app-overlay-spinner *ngIf="!groups"></app-overlay-spinner>

<div class="filter">

  <mat-form-field>
    <mat-label>{{ 'GROUP.LEVEL' | translate }}</mat-label>
    <mat-chip-list #levelChipList>
      <mat-chip class="level-mat-chip" 
        *ngFor="let level of selectedLevels"
        (removed)="removeLevel(level)"
      >
        {{ level }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        type="text"
        maxlength="0"
        placeholder
        matInput
        [matAutocomplete]="auto2"
        [matChipInputFor]="levelChipList"
      />
    </mat-chip-list>
    <mat-autocomplete
      #auto2="matAutocomplete"
      (optionSelected)="addLevel($event.option.value)"
      [displayWith]="displayLevel"
    >
      <mat-option *ngFor="let level of levels" [value]="level">
        <span>
          {{ level }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  

  <mat-form-field>
    <mat-label>{{ 'GROUP.NAME' | translate }}</mat-label>
    <input matInput (keyup.enter)="filterGroups()" (blur)="filterGroups()" [(ngModel)]="groupNameFilter"/>
    <button mat-button *ngIf="groupNameFilter" matSuffix mat-icon-button aria-label="Clear" (click)="groupNameFilter=''; filterGroups()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

</div>  

<button mat-raised-button (click)="create()">
  {{ 'GROUP.TITLECREATE' | translate }}
</button>

<table mat-table matSort [dataSource]="groupsListTable" class="mat-elevation-z8">
  <!-- (window:resize)="onResize($event)"> -->

  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef  mat-sort-header>
      {{ "GROUP.LEVEL" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.level }}
    </td>
  </ng-container>

  <ng-container matColumnDef="groupName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ "GROUP.NAME" | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      {{ leveledGroupName(element) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-raised-button class="create-code-button" (click)="showGroupCode(element)"> 
        {{ 'GROUP.CODE' | translate }}
      </button>
      <button mat-raised-button (click)="delete(element)" [disabled]=" element.level == 1 &&  !this.authService.isAdmin()" > 
        {{ 'DELETE' | translate }}
      </button>
    </td>
  </ng-container>

  <!-- <ng-container matColumnDef="mass">
      <th mat-header-cell *matHeaderCellDef translate="VEHICLE.MASS"></th>
      <td mat-cell *matCellDef="let element">{{ element.mass }}</td>
    </ng-container> -->

  <!-- <ng-container matColumnDef="details">
      <th mat-header-cell *matHeaderCellDef translate="DETAILS"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button (click)="openEdit(element)">Edit</button>
      </td>
    </ng-container> -->

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<mat-paginator class="mat-elevation-z8" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
  (page)="resetPage($event.pageSize)">
</mat-paginator>