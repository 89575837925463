import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Group } from 'lcmm-lib-js';
import { Sensorstation } from 'sensorstation-lib-js';
import { SensorstationService } from '../service/sensorstation.service';
import { SensorstationFormComponent } from '../sensorstation-form/sensorstation-form.component';

@Component({
  selector: 'app-sensorstation-create-dialog',
  templateUrl: './sensorstation-create-dialog.component.html',
  styleUrls: ['./sensorstation-create-dialog.component.scss'],
})
export class SensorstationCreateDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  public groups: Group[];

  public sensorstation: Sensorstation;

  public loading = false;

  @ViewChild(SensorstationFormComponent)
  public sensorstationFormComponent: SensorstationFormComponent;

  constructor(
    public dialogRef: MatDialogRef<SensorstationCreateDialogComponent>,
    private sensorstationService: SensorstationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      groups: Group[];
    }
  ) {
    this.sensorstation = {
      deviceId: '',
      aFESerialNumber: '',
      name: '',
      group: '',
      street: '',
      city: '',
      state: '',
      houseNumber: '',
      postalCode: '',
      latitude: null,
      longitude: null,
    };
    if (data.groups) {
      this.groups = data.groups;
    }
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({});
  }

  private _getSensorstation(): Sensorstation {
    const sensorstation: Sensorstation = {
      ...this.form.value.sensorstationForm,
      id: null,
      groupName: this.form.value.sensorstationForm.groupName,
    };
    return sensorstation;
  }

  public onSubmit(): void {
    const sensorstation: Sensorstation = this._getSensorstation();
    this.loading = true;
    this.sensorstationService.createSensorstation(sensorstation).subscribe(
      (createdSensorstation) => {
        this.loading = false;
        this.dialogRef.close(createdSensorstation);
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err) => {
        this.loading = false;
      }
    );
  }
}
