import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Role } from 'lcmm-lib-js';
import { AdministrationComponent } from './administration/administration.component';
import { UserComponent } from './administration/user/user.component';
import { VehiclesComponent } from './administration/vehicles/vehicles.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { TripsComponent } from './trips/trips.component';
import { AuthService } from './service/auth.service';
import { HomeComponent } from './home/home.component';
import { VehicleTypeComponent } from './administration/vehicle-type/vehicle-type.component';
import { LcmmGroupsComponent } from './administration/lcmm-groups/lcmm-groups.component';
import { ContactComponent } from './contact/contact.component';
import { SensorstationsComponent } from './sensorstations/sensorstations.component';

const routes: Routes = [
  // without authorization

  { path: 'app/privacystatement', component: PrivacyStatementComponent },
  { path: 'app/contact', component: ContactComponent },
  { path: 'app/imprint', component: ImprintComponent },

  { path: 'ui/privacystatement', component: PrivacyStatementComponent },
  { path: 'ui/imprint', component: ImprintComponent },
  { path: 'ui/contact', component: ContactComponent },

  // with authorization

  { path: '', component: HomeComponent, canActivate: [AuthService] },
  { path: 'contact', component: ContactComponent, canActivate: [AuthService] },
  { path: 'imprint', component: ImprintComponent, canActivate: [AuthService] },
  {
    path: 'privacystatement',
    component: PrivacyStatementComponent,
    canActivate: [AuthService],
  },
  { path: 'trips', component: TripsComponent, canActivate: [AuthService] },
  {
    path: 'sensorstation',
    component: SensorstationsComponent,
    canActivate: [AuthService],
  },
  // { path: 'reports', component: ReportsComponent, canActivate: [AuthService] },
  {
    path: 'admin',
    component: AdministrationComponent,
    canActivate: [AuthService],
    data: { roles: [Role.Dispatcher, Role.Admin] },
    children: [
      { path: 'models', component: VehicleTypeComponent },
      { path: 'vehicles', component: VehiclesComponent },
      { path: 'groups', component: LcmmGroupsComponent },
      { path: 'users', component: UserComponent },
    ],
  },
  // { path: 'email-list', component: EmailListComponent, canActivate: [AuthService] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
