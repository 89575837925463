import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Group, VehicleType } from 'lcmm-lib-js';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { VehicleService } from 'src/app/service/vehicle.service';
import { enumSelector, FuelType, getControlValue } from 'src/app/utils/utils';

@Component({
  selector: 'app-vehicle-type-form',
  templateUrl: './vehicle-type-form.component.html',
  styleUrls: ['./vehicle-type-form.component.scss'],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class VehicleTypeFormComponent implements OnInit {
  public form: UntypedFormGroup;

  public selectableFuelTypes = enumSelector(FuelType);

  @Input() public vehicleType: VehicleType;

  @Input() public groups: Group[];

  constructor(
    private ctrlContainer: FormGroupDirective,
    private fb: UntypedFormBuilder,
    public envService: EnvConfigurationService,
    private ts: TranslateService
  ) {}

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    this.form.addControl(
      'vehicleTypeForm',
      this.fb.group(
        VehicleService.checkOptionalVehicleTypeAttributes(this.vehicleType)
      )
    );
  }

  public isGroupFieldDisabled(): boolean {
    return this.vehicleType.id !== undefined && this.vehicleType.id !== null;
  }

  public groupChanged(groupIdentifier: string): void {
    this.vehicleType.groupName = groupIdentifier;
  }

  public wltpClassChanged(wltpClassIndex: number): void {
    this.vehicleType.wltpClass = wltpClassIndex;
  }

  public standStillFuelConsumptionLabel(): string {
    const pre = this.ts.instant('VEHICLE.STANDSTILLFUELCONSUMPTION');
    const value = getControlValue(
      this.form.value,
      'vehicleTypeForm.fuelValue'
    ) as number;
    if (value === FuelType.ELECTRIC) {
      return `${pre} kW/h`;
    }
    return `${pre} l/h`;
  }
}
