import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Group, VehicleType } from 'lcmm-lib-js';
import { MatSort } from '@angular/material/sort';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  ConfirmationDialogEntity,
  ConfirmationDialogAction,
} from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { LcmmDataSource } from 'src/app/datasource/lcmm-data-source';
import { Sort } from 'src/app/datasource/page';
import { UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/service/user.service';
import { getLeveledGroupName } from 'src/app/utils/utils';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { EnvConfigurationService } from 'src/app/service/env-config.service';
import { ResponsiveDesignService } from 'src/app/service/responsiveDesign.service';
import { VehicleTypeDialogComponent } from './vehicle-type-dialog/vehicle-type-dialog.component';
import { VehicleTypeCreateDialogComponent } from './vehicle-type-create-dialog/vehicle-type-create-dialog.component';
import {
  VehicleService,
  VehicleTypeQuery,
} from '../../service/vehicle.service';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html',
  styleUrls: ['./vehicle-type.component.scss'],
})
export class VehicleTypeComponent implements OnInit, OnDestroy, AfterViewInit {
  public displayedColumns: string[] = [
    // 'id',
    'brand',
    'series',
    'model',
    'details',
  ];

  public vehicleTypes: VehicleType[];

  public pageSizeOptions: number[] = [5, 10, 25, 100];

  public pageSize = 10;

  private previousPageSize = -1;

  // Link dataSource to MatTable
  public initialSort: Sort = { property: 'brand', order: 'asc' };

  public vehicleTypesData = new LcmmDataSource<VehicleType, VehicleTypeQuery>(
    (request, query) => this.vehicleService.vehicleTypesPage(request, query),
    this.initialSort,
    { brand: undefined, series: undefined, model: undefined, group: undefined }
  );

  public brandFilter = '';

  public seriesFilter = '';

  public modelFilter = '';

  public groups: Group[] = [];

  public filteredGroups: Observable<Group[]>;

  public groupFilter = new UntypedFormControl();

  public selectedGroup?: Group;

  @ViewChild(MatSort) public sort: MatSort;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  constructor(
    public vehicleService: VehicleService,
    public dialog: MatDialog,
    private userService: UserService,
    public envService: EnvConfigurationService,
    private responsiveDesignService: ResponsiveDesignService
  ) {}

  private resize(pageSize: number): void {
    if (this.vehicleTypesData.pageSize > pageSize) {
      this.resetPage(pageSize);
      this.queryByFilter();
    }
  }

  ngAfterViewInit(): void {
    this.resize(
      this.responsiveDesignService.register(
        'VehicleTypeComponent',
        this.resize.bind(this)
      )
    );
  }

  ngOnDestroy(): void {
    this.responsiveDesignService.deregister('VehicleTypeComponent');
  }

  ngOnInit(): void {
    this.userService.flattenedGroups.subscribe((groups) => {
      if (groups) {
        this.groups = groups;
        this.filteredGroups = this.groupFilter.valueChanges.pipe(
          startWith(groups),
          map((value) => this.filterGroups(value))
        );
      }
    });
  }

  public openEdit(vehicle: VehicleType): void {
    this.dialog.open(VehicleTypeDialogComponent, {
      width: '80%',
      data: { vehicle, groups: this.groups },
    });
  }

  public create(): void {
    this.dialog.open(VehicleTypeCreateDialogComponent, {
      width: '80%',
      data: { groups: this.groups },
    });
  }

  public delete(vehicleType: VehicleType): void {
    const dialogData: ConfirmationDialogData = {
      entity: ConfirmationDialogEntity.model,
      action: ConfirmationDialogAction.delete,
      data: `${vehicleType.brand}${' '}${vehicleType.series}${' '}${
        vehicleType.model
      }`,
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '80%',
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((shouldDelete) => {
      if (shouldDelete) {
        this.vehicleService.deleteVehicleType(vehicleType).subscribe();
      }
    });
  }

  public resetPage(pageSize: number): void {
    this.vehicleTypesData.pageSize = pageSize;
    if (this.previousPageSize > 0 && this.previousPageSize !== pageSize) {
      this.paginator.firstPage();
    }
    this.previousPageSize = pageSize;
  }

  public getCellClass(vehicleType: VehicleType): string {
    if (this.vehicleService.isElectric(vehicleType)) {
      return 'electricCell';
    }
    return null;
  }

  private filterGroups(value: string): Group[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.groups.filter((option) => {
        if (option.groupName) {
          return option.groupName.toLowerCase().includes(filterValue);
        }
        return true;
      });
    }
    return this.groups;
  }

  public displayGroup(group: Group): string {
    return group && group.groupName ? group.groupName : '';
  }

  public queryByFilter(): void {
    this.vehicleTypesData.queryBy({
      brand: this.brandFilter,
      series: this.seriesFilter,
      model: this.modelFilter,
      group: this.groupFilter.value,
    });
  }

  public selectGroup(group: Group): void {
    this.selectedGroup = group;
    this.queryByFilter();
  }

  public removeGroup(): void {
    this.selectedGroup = null;
    this.groupFilter.reset();
    this.queryByFilter();
  }

  public leveledGroupName(group: Group): string {
    return getLeveledGroupName(group);
  }
}
