<h1 mat-dialog-title>
  <span translate="VEHICLE.VEHICLE"> </span>
</h1>
<form [formGroup]="form">
  <app-vehicle-form [groups]="groups" [users]="users" [vehicle]="vehicle" [allowEditing]="true"></app-vehicle-form>
  <mat-divider></mat-divider>
  <div class="form">
    <mat-form-field>
      <mat-label mat-label translate="VEHICLE.VEHICLETYPE"></mat-label>
      <mat-select [(value)]="selectedVehicleType">
        <mat-option *ngFor="let selectedVehicleType of vehicleTypeList" [value]="selectedVehicleType">
          {{ selectedVehicleType.brand }} {{ selectedVehicleType.series }} {{ selectedVehicleType.model }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <button class="load_button" mat-raised-button (click)="loadVehicleType()">
      {{ 'LOAD' | translate }}
    </button>
    <div></div>
  </div>
</form>
<div mat-dialog-actions>
  <button mat-raised-button [disabled]="!form.valid" (click)="onSubmit()">
    {{ 'CREATE' | translate }}
  </button>
  <button mat-raised-button [mat-dialog-close]>
    {{ 'CLOSE' | translate }}
  </button>
</div>
