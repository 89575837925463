<h1 mat-dialog-title>
    <span translate="PRIVACY.TITLE"> </span>
</h1>

<mat-dialog-content>
    <div [innerHTML]="content"></div>
</mat-dialog-content>

<mat-dialog-actions>
    <div class="actions">
        <mat-checkbox [(ngModel)]="gdprAccepted">{{ 'PRIVACY.CONSENT' | translate }}</mat-checkbox>
    </div>
    <div class="actions">
        <button mat-raised-button [mat-dialog-close]="gdprAccepted" [disabled]=!gdprAccepted>
            {{ 'CLOSE' | translate }}
        </button>
    </div>
</mat-dialog-actions>