<mat-card *ngIf="authService.isAdmin()">

  <mat-card-header>
    <mat-card-title>{{ 'TOOLS.NEW_CALC' | translate }}</mat-card-title>
  </mat-card-header>
  
  <mat-card-actions>

    <mat-form-field class="card">
      <input
        matInput
        [ngxMatDatetimePicker]="startPicker"
        placeholder="{{ 'TRIP.FROM_TITLE' | translate }}"
        (dateChange)="intervalChange({ startTimeFrom: $event.value })"
        [max]="maxDate"
        autocomplete="off"
        [formControl]="myControlStartDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!startDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #startPicker [showSeconds]="false"> </ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field class="card">
      <input
        matInput
        [ngxMatDatetimePicker]="endPicker"
        placeholder="{{ 'TRIP.TO_TITLE' | translate }}"
        (dateChange)="intervalChange({ startTimeTo: $event.value })"
        [max]="maxDate"
        autocomplete="off"
        [formControl]="myControlEndDate"
        [errorStateMatcher]="errorStateMatcher"
      />
      <mat-error *ngIf="!endDateValid">{{ 'TRIP.INVALID_DATE' | translate }}</mat-error>
      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker #endPicker [showSeconds]="false"></ngx-mat-datetime-picker>
    </mat-form-field>

    <mat-form-field class="card">
      <mat-chip-list #chipListRecalculateGroup>
        <mat-chip 
          class="trips-tools-mat-chip"
          *ngIf="selectedGroup"
          (removed)="selectGroup(null)">
          {{selectedGroup.groupName}}
          <mat-icon matChipRemove >cancel</mat-icon>
        </mat-chip>
        <input *ngIf="!selectedGroup"
          type="text"
          placeholder="{{ 'TRIP.GROUPNAME' | translate }}"
          matInput
          [formControl]="formControlGroup"
          [matAutocomplete]="autoGroupMap"
          [matChipInputFor]="chipListRecalculateGroup"
        />
      </mat-chip-list>
      <mat-autocomplete
        #autoGroupMap="matAutocomplete"
        (optionSelected)="selectGroup($event.option.value)"
        [displayWith]="displayGroup"
      >
        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
          <span>
            <span
              *ngFor="
                let dummy of ' '.repeat(group.groupIdentifier.split(GROUP_IDENTIFIER_SEPARATOR).length - 2).split('');
                let x = index
              "
            >
              - </span
            >{{ group.groupName }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </mat-card-actions>
    
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="recalcSubmit()" [disabled]="isRecalcSubmitDisabled()">
      {{ 'TOOLS.NEW_CALC' | translate }}
    </button>
  </mat-card-actions>

</mat-card>

<br>

<mat-card *ngIf="authService.isAdminOrDispatcher()">
  <mat-card-header>
    <mat-card-title>{{ 'TOOLS.CLEAR_EMPTY_TRIPS' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-checkbox [(ngModel)]="withPositions">{{ 'TOOLS.CLEAR_EMPTY_TRIPS_WITHPOSITIONS' | translate }}</mat-checkbox>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="clearEmptySubmit()">
      {{ 'TOOLS.CLEAR_EMPTY_TRIPS' | translate }}
    </button>
  </mat-card-actions>
</mat-card>

<br>

<mat-card *ngIf="authService.isAdmin()">

  <mat-card-header>
    <mat-card-title>{{ 'TOOLS.SHOW_TRIP' | translate }}</mat-card-title>
  </mat-card-header>

  <mat-form-field class="card">
    <mat-chip-list #chipListShowTrip>
      <mat-chip 
        class="trips-tools-mat-chip"
        *ngIf="selectedGroupShowTrip"
        (removed)="selectGroupShowTrip(null)">
        {{selectedGroupShowTrip.groupName}}
        <mat-icon matChipRemove >cancel</mat-icon>
      </mat-chip>
      <input *ngIf="!selectedGroupShowTrip"
        type="text"
        placeholder="{{ 'TRIP.GROUPNAME' | translate }}"
        matInput
        [formControl]="formControlGroup"
        [matAutocomplete]="autoGroupMap"
        [matChipInputFor]="chipListShowTrip"
      />
    </mat-chip-list>
    <mat-autocomplete
      #autoGroupMap="matAutocomplete"
      (optionSelected)="selectGroupShowTrip($event.option.value)"
      [displayWith]="displayGroup"
    >
      <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
        <span>
          <span
            *ngFor="
              let dummy of ' '.repeat(group.groupIdentifier.split(GROUP_IDENTIFIER_SEPARATOR).length - 2).split('');
              let x = index
            "
          >
            - </span
          >{{ group.groupName }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="text"
      placeholder="{{ 'TOOLS.TRIPID' | translate}}"
      [(ngModel)]="tripIdShowTrip"
      (input)="clearTripIdShowTripErrorMsg()"
      autocomplete="off"
    />
    <button mat-button *ngIf="tripIdShowTrip" matSuffix mat-icon-button aria-label="Clear" (click)="clearTripIdShowTrip()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-card-actions>
    <button 
      mat-raised-button
      [disabled]="disableShowTripButton()"
      color="primary"
      (click)="showTripDetailsByTripId()"
    >
      {{ 'TOOLS.SHOW_TRIP' | translate }}
    </button>
    <label *ngIf="tripIdShowTripErrorMsg !== null" translate>
      {{ tripIdShowTripErrorMsg }}
    </label>
  </mat-card-actions>

</mat-card>
