import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { MatDialog } from '@angular/material/dialog';
import { timeoutWorker } from 'timeout-worker';
import { environment } from 'src/environments/environment';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { TimeoutComponent } from './timeout/timeout.component';
import { AuthService } from './service/auth.service';
import { ChartEventService } from './service/chart-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = environment.appTitle;

  private readonly viewportChange = this.viewportRuler
    .change(200)
    .subscribe(() => this.ngZone.run(() => this.setSize()));

  constructor(
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog,
    public authService: AuthService,
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
    private ces: ChartEventService
  ) {
    this.setSize();
  }

  ngOnInit(): void {
    // dialog timeout timer
    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.timeoutDialog();
        this.bnIdle.stopTimer();
      }
    });
  }

  ngOnDestroy(): void {
    this.viewportChange.unsubscribe();
  }

  private setSize() {
    const { height, width } = this.viewportRuler.getViewportSize();
    this.ces.setViewPortPx(height, width);
  }

  public timeoutDialog(): void {
    const dialogRef = this.dialog.open(TimeoutComponent);
    dialogRef.afterClosed().subscribe(() => {
      timeoutWorker.stop();
      this.bnIdle.startWatching(3600);
    });
  }
}
